import {useNavigate} from 'react-router-dom'
import CustomButton2, {
    BUTTON_SIZE_STYLE,
    BUTTON_TYPE,
} from '../../../../../../../customComponents/customButtton/CustomButton2'
import CustomModal from '../../../../../../../customComponents/customModal/CustomModal'
import {useDispatch} from 'react-redux'
import {useCallback, useEffect, useState} from 'react'
import {sendDataToReducer} from '../../../../../../../helpers/CommonHelpers'
import {SET_TIMESHEET_APPROVAL_STATUS} from '../../../../../../../redux/reducers/SettingReducer'
import {useSelector} from 'react-redux'
import {
    getCompanyTimesheetApprovalSettingSelector,
    getTimesheetApprovalPendingSelector,
} from '../../../../../../../redux/selectors/SettingsSelectors'

const TimeSheetPendingModal = ({}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const timesheetApprovalStatus = useSelector(getTimesheetApprovalPendingSelector)
    const companyTimesheetSetting = useSelector(getCompanyTimesheetApprovalSettingSelector)

    const [showTimesheetPendingAlert, setShowTimeSheetPendingAlert] = useState(false)

    const onClickView = () => {
        const requiredWeek = `${timesheetApprovalStatus?.startOfWeek} to ${timesheetApprovalStatus?.endOfWeek}`
        navigate('/management/scheduling/timeSheetApproval', {
            state: {preSelectedWeek: requiredWeek},
        })
        onCloseAlert()
    }

    const onCloseAlert = useCallback(
        (e) => {
            sendDataToReducer(dispatch, SET_TIMESHEET_APPROVAL_STATUS, {
                ...timesheetApprovalStatus,
                closed: true,
            })
        },
        [dispatch, timesheetApprovalStatus]
    )

    useEffect(() => {
        if (
            companyTimesheetSetting?.scheduling_setting == 'manual' &&
            !timesheetApprovalStatus?.closed
        )
            setShowTimeSheetPendingAlert(true)
        else setShowTimeSheetPendingAlert(false)
    }, [timesheetApprovalStatus?.closed])

    return (
        <CustomModal
            show={showTimesheetPendingAlert}
            maxWidth='500'
            showHeader={true}
            onHide={onCloseAlert}
        >
            <div className=' w-100 mx-auto text-sm-semibold '>
                <div className='mb-12 '>
                    <div className=' px-10 text-lg-semibold text-sqGrey800 text-center mb-10'>
                        You have Pending Timesheets for Approval
                    </div>
                    <div className='px-5 text-md-semibold text-center text-sqGray600'>
                        Please review immediately so the payroll can be processed!
                    </div>
                </div>
                <div className='d-flex flex-center gap-5'>
                    <CustomButton2
                        buttonLabel='Not now '
                        buttonSize={BUTTON_SIZE_STYLE.sm}
                        buttonType={BUTTON_TYPE.secondaryColor}
                        onClick={onCloseAlert}
                    />
                    <CustomButton2
                        buttonLabel='View'
                        buttonSize={BUTTON_SIZE_STYLE.sm}
                        buttonType={BUTTON_TYPE.primary}
                        onClick={onClickView}
                    />
                </div>
            </div>
        </CustomModal>
    )
}

export default TimeSheetPendingModal
