import './styles.css'
import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useLocation, useNavigate} from 'react-router-dom'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import Show from '../../../../customComponents/show/Show'
import BillingDetails from './BillingDetails'
import PayrollSetup from './PayrollSetup'
import AdminInformation from './AdminInformation'
import {BasicInformation} from './BasicInformation'
import {getTenantDetailsByTenantIdService} from '../../../../services/Services'
import {getErrorMessageFromResponse} from '../../../../helpers/CommonHelpers'
import CustomToast from '../../../../customComponents/customToast/CustomToast'

const AddNewClient = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [workTypeFirst, setWorkTypeFirst] = useState(false)
    const [tenantId, setTenantId] = useState()
    const [payrollToggle, setPayrollToggle] = useState('Yes')
    const [workTypeContractors, setWorkTypeContractors] = useState(false)
    const [loadingGetData, setLoadingGetData] = useState(false)

    const [tabActiveIndex, setActiveIndex] = useState(0)
    // Basic info
    const [salesData, setSaleData] = useState()
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedButton, setSelectedButton] = useState('Solar')

    // Admin info
    const [adminInformationData, setAdminInformationData] = useState({
        companyOwnerName: '',
        companyOwnerEmail: '',
        companyOwnerPhno: '',
        superAdmin1Name: '',
        superAdmin2Name: '',
        superAdmin3Name: '',
        superAdmin1Email: '',
        superAdmin2Email: '',
        superAdmin3Email: '',
        superAdmin1Phno: '',
        superAdmin2Phno: '',
        superAdmin3Phno: '',
    })

    // Payroll
    const [payrollData, setPayrollData] = useState({
        tenantId: '',
        apiKey: '',
        historyTenantId: '',
        historyApiKey: '',
    })
    const [switchIsChecked, setSwitchIsChecked] = useState(true)
    const [isValidateData, setIsValidateData] = useState(false)

    // Billing
    const [billingDetailsData, setBillingDetailsData] = useState({
        frequencyTypeId: '',
        minimumMonthlyFee: '',
        baseFreeForProcessingPID: '',
        basefreePerPIDandM2: '',
        workTypeFreeForProcessingPID: '',
        workTypeViaSequif: '',
        workTypeFreeForEmployeePerMonth: '',
        workTypeOverTimeRatePerHour: '',
    })
    const [pidOrWorkerView, setPidOrWorkerView] = useState('per_pid_and_m2')

    const [stepCompletion, setStepCompletion] = useState([true, false, false, false])

    useEffect(() => {
        const savedStep = localStorage.getItem('currentStep')
        if (savedStep) {
            const formData = JSON.parse(savedStep)

            setActiveIndex(formData?.currentStep)
            setTenantId(formData?.tenantId)
            if (formData?.stepCompletion) {
                setStepCompletion(formData?.stepCompletion)
            }
        }
    }, [])

    const handleItemClick = (index) => {
        if (index > tabActiveIndex) {
            if (index > 0 && !stepCompletion[index]) {
                return
            }
        } else {
            return
        }
        setActiveIndex(index)
    }

    const completeStep = (index) => {
        const newStepCompletion = [...stepCompletion]
        newStepCompletion[index] = true
        setStepCompletion(newStepCompletion)
        setActiveIndex(index + 1)
    }

    useEffect(() => {
        if (location?.state?.tid) {
            const body = {
                tenant_id: location?.state?.tid,
            }
            setLoadingGetData(true)
            getTenantDetailsByTenantIdService(body)
                .then((res) => {
                    setSaleData({
                        companyName: res?.data?.name,
                        sub_domain: res?.data?.id,
                    })
                    setSelectedButton(res?.data?.companyType)
                    setSelectedFile(res?.data?.image)

                    //adminUsers
                    setAdminInformationData({
                        companyOwnerName: res?.data?.adminUsers[0]?.first_name,
                        companyOwnerEmail: res?.data?.adminUsers[0]?.email,
                        companyOwnerPhno: res?.data?.adminUsers[0]?.mobile_no,
                        superAdmin1Name: res?.data?.adminUsers[1]?.first_name,
                        superAdmin2Name: res?.data?.adminUsers[2]?.first_name,
                        superAdmin3Name: res?.data?.adminUsers[3]?.first_name,
                        superAdmin1Email: res?.data?.adminUsers[1]?.email,
                        superAdmin2Email: res?.data?.adminUsers[2]?.email,
                        superAdmin3Email: res?.data?.adminUsers[3]?.email,
                        superAdmin1Phno: res?.data?.adminUsers[1]?.mobile_no,
                        superAdmin2Phno: res?.data?.adminUsers[2]?.mobile_no,
                        superAdmin3Phno: res?.data?.adminUsers[3]?.mobile_no,
                        userDataId: res?.data?.adminUsers[0]?.id,
                        userDataId1: res?.data?.adminUsers[1]?.id,
                        userDataId2: res?.data?.adminUsers[2]?.id,
                        userDataId3: res?.data?.adminUsers[3]?.id,
                    })
                    //payroll
                    setPayrollData({
                        tenantId: res?.data?.payrroll_processing?.company_tenant_id,
                        historyTenantId: res?.data?.payrroll_processing?.company_tenant_id,
                        apiKey: res?.data?.payrroll_processing?.company_api_key,
                        historyApiKey: res?.data?.payrroll_processing?.company_api_key,
                    })

                    if (
                        res?.data?.payrroll_processing?.company_tenant_id &&
                        res?.data?.payrroll_processing?.company_api_key
                    ) {
                        setIsValidateData(true)
                    }

                    setPayrollToggle(
                        res?.data?.payrroll_processing?.company_tenant_id ? 'Yes' : 'No'
                    )
                    setWorkTypeContractors(
                        res?.data?.payrroll_processing?.is_workerType_1099 == 1 ? true : false
                    )
                    setWorkTypeContractors(true)
                    setSwitchIsChecked(
                        res?.data?.payrroll_processing?.sequifi_pay_status == 1 ? true : false
                    )
                    setWorkTypeFirst(
                        res?.data?.payrroll_processing?.is_workerType_w2 == 1 ? true : false
                    )
                    //Billing Details
                    setBillingDetailsData({
                        frequencyTypeId: res?.data?.billing_details?.frequency_type_id,
                        minimumMonthlyFee: res?.data?.billing_details?.min_monthly_pay,

                        baseFreeForProcessingPID:
                            res?.data?.billing_details?.base_fee == 'per_pid_and_m2'
                                ? res?.data?.billing_details?.fee_for_processing_pid
                                : res?.data?.billing_details?.fee_per_user_per_month,
                        // baseFreeForProcessingPID: res?.data?.billing_details?.fee_for_processing_pid,
                        basefreePerPIDandM2: res?.data?.billing_details?.fee_per_pid_and_m2,

                        workTypeFreeForProcessingPID:
                            res?.data?.billing_details?.fee_per_check_for_1099_workers,
                        workTypeViaSequif:
                            res?.data?.billing_details?.onetime_payment_process_fee_via_sequifi,
                        workTypeFreeForEmployeePerMonth:
                            res?.data?.billing_details?.fee_per_employee_per_month,
                        workTypeOverTimeRatePerHour:
                            res?.data?.billing_details?.overtime_rate_per_hour,
                    })
                    setPidOrWorkerView(res?.data?.billing_details?.base_fee)
                })
                .catch((err) => {
                    getErrorMessageFromResponse(CustomToast.error(err?.data?.message))
                })
                .finally(() => setLoadingGetData(false))
        }
    }, [location?.state?.tid])

    return (
        <div className='container' style={{fontFamily: fontsFamily.manrope}}>
            <div className='d-flex align-items-center gap-3 mb-2'>
                <div>
                    <KTSVG
                        path='/media/icons/duotune/art/back-square.svg'
                        svgClassName='h-25px w-25px cursor-pointer'
                        onClick={() => {
                            const formData = {
                                currentStep: 0,
                                tenantId: '',
                                stepCompletion: [true],
                            }
                            localStorage.setItem('currentStep', JSON.stringify(formData))
                            navigate(-1)
                        }}
                    />
                </div>
                <div className='text-cmGrey900' style={{fontSize: 20.58, fontWeight: 500}}>
                    {location?.state?.tid ? 'Edit' : 'Add'} a new client
                </div>
            </div>
            <div className='text-cmGrey700 mb-10' style={{fontWeight: 600, fontSize: 14}}>
                Finish these sections to onboard a new client, ensuring you provide accurate company
                details, administrative contacts, and technical preferences.
            </div>
            <div className='shadow-sm mb-10'>
                <div className='bg-cmwhite pt-10 pb-15 global-padding rounded-2'>
                    <div className='d-flex justify-content-between text-md-medium text-sqGray500 mb-8 scroll-bar'>
                        {tabConstants?.map((item, index) => (
                            <p
                                key={index}
                                className={`${
                                    tabActiveIndex == index
                                        ? 'text-md-bold text-sqPrimary600 text-cmBlue-Crayola'
                                        : ''
                                } ${stepCompletion[index] ? 'text-cmSuccess' : ''}`}
                                onClick={() => handleItemClick(index)}
                            >
                                {index + 1}. {item}
                            </p>
                        ))}
                    </div>

                    <Show>
                        <Show.When isVisible={tabActiveIndex == 0}>
                            <BasicInformation
                                tabActive={tabActiveIndex}
                                setActiveIndex={setActiveIndex}
                                setTenantId={setTenantId}
                                salesData={salesData}
                                setSaleData={setSaleData}
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                                selectedImage={selectedImage}
                                setSelectedImage={setSelectedImage}
                                selectedButton={selectedButton}
                                setSelectedButton={setSelectedButton}
                                loadingGetData={loadingGetData}
                                location={location}
                                completeStep={() => completeStep(0)}
                            />
                        </Show.When>
                    </Show>
                    <Show>
                        <Show.When isVisible={tabActiveIndex == 1}>
                            <AdminInformation
                                tabActive={tabActiveIndex}
                                onTabChange={setActiveIndex}
                                tenantId={tenantId}
                                adminInformationData={adminInformationData}
                                setAdminInformationData={setAdminInformationData}
                                location={location}
                                completeStep={() => completeStep(1)}
                            />
                        </Show.When>
                    </Show>
                    <Show>
                        <Show.When isVisible={tabActiveIndex == 2}>
                            <PayrollSetup
                                tenantId={tenantId}
                                workTypeFirst={workTypeFirst}
                                setWorkTypeFirst={setWorkTypeFirst}
                                workTypeContractors={workTypeContractors}
                                setWorkTypeContractors={setWorkTypeContractors}
                                tabActive={tabActiveIndex}
                                onTabChange={setActiveIndex}
                                payrollData={payrollData}
                                setPayrollData={setPayrollData}
                                payrollToggle={payrollToggle}
                                setPayrollToggle={setPayrollToggle}
                                switchIsChecked={switchIsChecked}
                                setSwitchIsChecked={setSwitchIsChecked}
                                isValidateData={isValidateData}
                                setIsValidateData={setIsValidateData}
                                location={location}
                                completeStep={() => completeStep(2)}
                            />
                        </Show.When>
                    </Show>
                    <Show>
                        <Show.When isVisible={tabActiveIndex == 3}>
                            <BillingDetails
                                workTypeFirst={workTypeFirst}
                                workTypeContractors={workTypeContractors}
                                tabActive={tabActiveIndex}
                                onTabChange={setActiveIndex}
                                tenantId={tenantId}
                                billingDetailsData={billingDetailsData}
                                setBillingDetailsData={setBillingDetailsData}
                                pidOrWorkerView={pidOrWorkerView}
                                setPidOrWorkerView={setPidOrWorkerView}
                                location={location}
                                completeStep={() => completeStep(3)}
                            />
                        </Show.When>
                    </Show>
                </div>
            </div>
        </div>
    )
}

export default AddNewClient

const tabConstants = [
    'Basic Information',
    'Administrative Setup',
    'Payroll Processing',
    'Billing Details',
]
