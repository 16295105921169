import './styles.css'
import {useCallback, useEffect, useState} from 'react'
import CustomInput, {
    CommonLabel,
} from '../../../../customComponents/customInputs/customInput/CustomInput'
import {getErrorMessageFromResponse} from '../../../../helpers/CommonHelpers'
import CustomButton2, {
    BUTTON_TYPE,
    BUTTON_SIZE,
} from '../../../../customComponents/customButtton/CustomButton2'
import {
    addTenantPayrollValidation,
    addTenantPayrollValidationValidate,
} from '../../../../validations/validations'
import useValidation from '../../../../hooks/useValidation'
import {
    postValidateTenantOnEveryService,
    setupTenantPayrollService,
} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'
import {useNavigate} from 'react-router-dom'
import CustomRadioButton1 from '../../../../customComponents/customRadioButton/CustomRadioButton1'
import CustomToggleBtn from '../../../../customComponents/customToogleBtn/CustomToggleBtn'
import CustomCheckbox from '../../../../customComponents/customCheckbox/CustomCheckbox'
import {KTSVG} from '../../../../_metronic/helpers'

const PayrollSetup = ({
    tenantId,
    workTypeFirst,
    setWorkTypeFirst,
    workTypeContractors,
    setWorkTypeContractors,
    setActiveIndex,
    tabActive,
    onTabChange,
    payrollData,
    setPayrollData,
    payrollToggle,
    setPayrollToggle,
    switchIsChecked,
    setSwitchIsChecked,
    completeStep,
    location,
    isValidateData,
    setIsValidateData,
}) => {
    const naviagte = useNavigate()

    // useEffect(() => {
    //     if (payrollToggle === 'No') {
    //         setWorkTypeContractors(true)
    //     } else {
    //         setWorkTypeContractors(false)
    //     }
    // }, [payrollToggle])

    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [errorMsg1099, setErrorMsg1099] = useState('')
    const [validatePayrollInformation, payrollErrorData] = useValidation()

    const onChangeSwitch = useCallback((e) => {
        setSwitchIsChecked((prevState) => !prevState)
        // setWorkTypeFirst(false)
    }, [])

    useEffect(() => {
        if (payrollErrorData?.beginValidating) {
            validatePayrollInformation(
                addTenantPayrollValidation(
                    payrollData,
                    payrollToggle,
                    workTypeFirst,
                    workTypeContractors,
                    switchIsChecked
                )
            )
        }
    }, [payrollData, workTypeFirst, switchIsChecked])

    const updatePayrollData = (e) => {
        setPayrollData((val) => ({
            ...payrollData,
            [e?.target?.name]: e?.target?.value,
        }))
    }

    useEffect(() => {
        if (location?.state?.tid) {
            if (payrollData.tenantId && payrollData.apiKey) {
                if (
                    payrollData.historyTenantId === payrollData.tenantId &&
                    payrollData.historyApiKey === payrollData.apiKey
                ) {
                    setIsValidateData(true)
                } else {
                    setIsValidateData(false)
                }
            } else {
                setIsValidateData(false)
            }
        }
    }, [payrollData])

    const handleValidatedBtn = () => {
        setErrorMsg('')
        validatePayrollInformation(
            addTenantPayrollValidationValidate(payrollData, payrollToggle)
        ).then((res) => {
            if (res?.isValidate) {
                const data = {
                    tenant_id: location?.state?.tid || tenantId,
                    company_tenant_id: payrollData?.tenantId,
                    company_api_key: payrollData?.apiKey,
                }
                setLoading(true)
                postValidateTenantOnEveryService(data)
                    .then((res) => {
                        setIsValidateData(res?.is_validate)
                        if (res?.is_validate == false) {
                            setErrorMsg('Given data not proper')
                        }
                    })
                    .catch((err) => {
                        getErrorMessageFromResponse(CustomToast.error(err?.data?.message))
                    })
                    .finally(() => setLoading(false))
            }
        })
    }
    const onPayrollSave = () => {
        if (payrollToggle == 'No') {
            if (!workTypeContractors) {
                return setErrorMsg1099('Select Worker Type')
            }
        }
        validatePayrollInformation(
            addTenantPayrollValidation(
                payrollData,
                payrollToggle,
                workTypeFirst,
                workTypeContractors,
                switchIsChecked
            )
        ).then((res) => {
            if (res?.isValidate) {
                const data = {
                    tenant_id: location?.state?.tid || tenantId,
                    is_payroll_prcessing: payrollToggle === 'Yes' ? 1 : 0,
                    is_workerType_1099: workTypeContractors ? 1 : 0,
                    sequifi_pay_status: 0,
                    ...(payrollToggle === 'Yes' && {
                        company_tenant_id: payrollData?.tenantId,
                        company_api_key: payrollData?.apiKey,
                        sequifi_pay_status: switchIsChecked ? 1 : 0,
                        is_workerType_w2: switchIsChecked == 1 ? (workTypeFirst ? 1 : 0) : 0,
                    }),
                }
                setLoading(true)
                setupTenantPayrollService(data)
                    .then((res) => {
                        onTabChange(tabActive + 1)
                        CustomToast.success(res?.message)
                        completeStep()
                        const formData = {
                            currentStep: tabActive + 1,
                            tenantId: location?.state?.tid || tenantId,
                            stepCompletion: [true, true, true],
                        }
                        localStorage.setItem('currentStep', JSON.stringify(formData))
                    })
                    .catch((err) => getErrorMessageFromResponse(CustomToast.error(err?.message)))
                    .finally(() => setLoading(false))
            }
        })
    }

    return (
        <div style={{position: 'relative'}}>
            <CustomLoader visible={loading} full />
            <div className='text-xl-bold text-sqGray900'>Payroll Processing</div>

            <div>
                <div className='my-4 row'>
                    <div className='mb-7'>
                        <CommonLabel label='Do you want to process your payroll through Sequifi ?' />
                    </div>
                    <div className='d-flex gap-5 align-items-center flex-wrap mb-6'>
                        <CustomRadioButton1
                            isChecked={payrollToggle === 'Yes'}
                            handleChange={() => setPayrollToggle('Yes')}
                            // disabled={isDisable}
                            // size={isSmall ? RADIO_SIZE.sm : RADIO_SIZE.md}
                            label='Yes'
                        />
                        <CustomRadioButton1
                            label='No'
                            handleChange={() => setPayrollToggle('No')}
                            isChecked={payrollToggle === 'No'}
                            // size={isSmall ? RADIO_SIZE.sm : RADIO_SIZE.md}
                            // disabled={skeletonLoading || apiLoader}
                        />
                    </div>
                    {payrollToggle !== 'No' && (
                        <>
                            <div className='mb-5 col-sm-7'>
                                <CustomInput
                                    required
                                    label={'Company Tenant ID'}
                                    placeholder={'Enter Company Tenant Id'}
                                    name='tenantId'
                                    onChange={updatePayrollData}
                                    value={payrollData?.tenantId}
                                    errorMessage={payrollErrorData?.tenantId}
                                />
                            </div>

                            <div className='mb-6 col-sm-7'>
                                <CustomInput
                                    required
                                    placeholder={'Enter Api key'}
                                    label={'API Key'}
                                    name='apiKey'
                                    onChange={updatePayrollData}
                                    value={payrollData?.apiKey}
                                    errorMessage={payrollErrorData?.apiKey}
                                />
                            </div>
                            <div className=''>
                                <CustomButton2
                                    buttonLabel={isValidateData ? 'Validated' : 'Validate'}
                                    buttonSize={BUTTON_SIZE.lg}
                                    buttonType={BUTTON_TYPE.primary}
                                    onClick={() => handleValidatedBtn()}
                                    iconPosition={'right'}
                                    icon={
                                        <KTSVG
                                            path='/media/icons/duotune/art/check-circle.svg'
                                            svgClassName='w-20px h-20px'
                                        />
                                    }
                                />
                            </div>
                            <div className='text-sqError600 mt-3'>{errorMsg}</div>
                            {isValidateData && (
                                <div className='d-flex align-item-center pt-8'>
                                    <CustomToggleBtn
                                        checked={switchIsChecked}
                                        onChange={onChangeSwitch}
                                        // id={'My_client_Table_Toggle'}
                                    />
                                    <div className='ps-3'>
                                        <CommonLabel label='SequiPay' />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {/* -------- Worker Type ------- */}

                    <>
                        {((isValidateData && payrollToggle == 'Yes') || payrollToggle == 'No') && (
                            <>
                                <div className='text-xl-bold text-sqGray900 mt-6'>
                                    Select Worker Type
                                </div>
                                <div className='row align-items-center mt-4'>
                                    {/* Render both checkboxes when isValidateData is true and payrollToggle is not 'No' */}

                                    {payrollToggle == 'Yes' && switchIsChecked && (
                                        <div className='col-12 col-md-auto gap-3 d-flex align-items-center'>
                                            <CustomCheckbox
                                                id='remember_CheckBox1'
                                                checked={workTypeFirst}
                                                onChange={() => setWorkTypeFirst(!workTypeFirst)}
                                            />
                                            <CommonLabel label='W-2' />
                                        </div>
                                    )}
                                    <div className='col-12 col-md-auto gap-3 d-flex align-items-center'>
                                        <CustomCheckbox
                                            id='remember_CheckBox2'
                                            checked={workTypeContractors}
                                            onChange={() =>
                                                setWorkTypeContractors(!workTypeContractors)
                                            }
                                        />
                                        <CommonLabel label='1099 Contractors' />
                                    </div>
                                    <div className='text-sqError600 mt-3'>
                                        {payrollErrorData?.workTypeContractors}
                                    </div>
                                    <div className='text-sqError600 mt-3'>{errorMsg1099}</div>

                                    {/* Render only W-2 checkbox if payrollToggle is 'No' */}
                                </div>
                            </>
                        )}
                    </>
                </div>
            </div>

            <div className='row mt-13'>
                <div className='col-sm-6 row gap-2'>
                    {/* <div className='col-sm-5'>
                        <CustomButton2
                            buttonLabel='skip'
                            buttonSize={BUTTON_SIZE.lg}
                            buttonType={BUTTON_TYPE.secondaryGray}
                            onClick={() => naviagte(-1)}
                            width={100}
                        />
                    </div> */}
                    <div className='col-sm-6'>
                        <CustomButton2
                            buttonLabel='Save & Continue'
                            buttonSize={BUTTON_SIZE.lg}
                            buttonType={BUTTON_TYPE.primary}
                            onClick={onPayrollSave}
                            // width={'225px'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayrollSetup
