import React, {useState} from 'react'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomDatePicker from '../../../../../../customComponents/customInputs/customDatePicker/CustomDatePicker'
import images from '../../../../../../assets/images'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomDelete from '../../../../../../customComponents/customIcons/CustomDelete'
import CustomBadge from '../../../../../../customComponents/customBadge/CustomBadge'
const CompetitionSection = ({image, images}) => (
    <div
        className='d-flex align-items-center justify-content-between border px-12px mb-24px'
        style={{borderRadius: '8px', height: '66px'}}
    >
        <div className='d-flex align-items-center flex-row pb-3 mb-5 mt-8'>
            <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                <img
                    src={image || images.defaultUserImage}
                    alt='Competition'
                    className='rounded mr-3 border border-light'
                    style={{width: '40px', height: '40px'}}
                />
            </label>
            <div className='d-flex flex-column ps-3'>
                <span className='mb-1 text-sm-semibold text-sqGray900'>Olivia Rhye</span>
                <div className='d-flex gap-3'>
                    <div>
                        <CustomBadge
                            label={'Setter'}
                            textColor='sqGray700'
                            fontWeight={700}
                            fontSize={12}
                            px={3}
                            py={2}
                            backgroundColor={'sqGray100'}
                            opacity={false}
                            rounded={true}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            className='px-4 text-center'
            style={{
                fontFamily: 'Digitalt',
                fontSize: '32px',
                fontWeight: '500',
                color: '#393939',
            }}
        >
            VS
        </div>

        <div className='d-flex align-items-center flex-row pb-3 mb-5 mt-8 justify-content-end'>
            <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                <img
                    src={image || images.defaultUserImage}
                    alt='Competition'
                    className='rounded mr-3 border border-light'
                    style={{width: '40px', height: '40px'}}
                />
            </label>
            <div className='d-flex flex-column ps-3'>
                <h6 className='mb-1 text-sm-semibold text-sqGray900'>Olivia Rhye</h6>
                <div className='d-flex gap-3'>
                    <CustomBadge
                        label={'Setter'}
                        textColor='sqGray700'
                        fontWeight={700}
                        fontSize={12}
                        px={3}
                        py={2}
                        backgroundColor={'sqGray100'}
                        opacity={false}
                        rounded={true}
                    />
                </div>
            </div>
        </div>
    </div>
)
const AssignMatchesBattle = () => {
    const [image, setImage] = useState(null)
    const [sections, setSections] = useState([{}])
    const addNewSection = () => {
        setSections([...sections, {}])
    }

    return (
        <>
            <div className='py-32px'>
                <div className='mb-32px h-290px overflow-auto px-32px'>
                    <div className='d-flex align-items-center flex-row mb-32px'>
                        <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                            <KTSVG
                                path='/media/icons/duotune/arena/battle.svg'
                                // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                                svgClassName='w-50px h-50px cursor-pointer'
                            />
                        </label>

                        <div className='d-flex flex-column ps-3'>
                            <h6 className='mb-1 text-md-bold text-sqGray900'>Be the Champion</h6>
                            <div className='d-flex gap-8px'>
                                <div>
                                    <CustomBadge
                                        label={'Ladder'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                                <div>
                                    <CustomBadge
                                        label={'Individuals'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='text-md-semibold'>
                            Choose How you want to create matches.
                        </div>

                        <div className='text-sm-medium text-sqGray600 mb-6px'>
                            Either assign players to matches manually or let us do the work for you
                            by choosing random.
                        </div>
                        <div className='row mb-24px'>
                            <div className='col-sm'>
                                <CustomDropdown
                                    id='Manage_Leads_Home_Location_Input'
                                    label={''}
                                    valueKey='id'
                                    // options={allState}
                                    // value={homestate}
                                    // onChange={(e) => setHomeState(e.target.value)}
                                    errorMessage={''}
                                />
                            </div>
                        </div>

                        <>
                            {sections.map((_, index) => (
                                <>
                                    <CompetitionSection key={index} images={images} />
                                    {/* <CompetitionSection key={index} images={images} /> */}
                                </>
                            ))}
                            <div className='d-flex gap-2'>
                                <div>
                                    <KTSVG
                                        path='/media/icons/duotune/arena/plus-square.svg'
                                        className='cursor-pointer mb-2'
                                        svgClassName='w-20px h-20px'
                                        onClick={addNewSection}
                                    />
                                </div>
                                <div>
                                    <span className='text-sm-semibold text-sqPrimary600'>
                                        Add New
                                    </span>
                                </div>
                            </div>
                        </>

                        {/* <div
                                    className='d-flex align-items-center justify-content-center border p-4 mb-10'
                                    style={{borderRadius: '8px', height: '66px'}}
                                >
                                    <div className='d-flex align-items-center justify-content-center flex-row pb-3 mb-5 mt-8'>
                                        <label htmlFor='upload-image' style={{cursor: 'pointer'}}>
                                            <img
                                                src={image || images.defaultUserImage}
                                                alt='Competition'
                                                className='rounded mr-3 border border-light'
                                                style={{width: '40px', height: '40px'}}
                                            />
                                        </label>
                                        <div className='d-flex flex-column ps-3'>
                                            <span className='mb-1 text-sm-semibold text-sqGray900'>
                                                Olivia Rhye
                                            </span>
                                            <div>
                                                <CustomBadge
                                                    label={'Setter'}
                                                    textColor='sqGray700'
                                                    fontWeight={700}
                                                    fontSize={12}
                                                    px={6}
                                                    py={2}
                                                    backgroundColor={'sqGray100'}
                                                    opacity={false}
                                                    rounded={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                    </div>
                </div>
                <div className='px-32px'>
                    {/* {activeGroup === 'GroupA' && ( */}
                    <div className='mb-5'>
                        <CustomButton2
                            buttonLabel='View Participants (64)'
                            buttonSize={BUTTON_SIZE.xl}
                            buttonType={BUTTON_TYPE.secondaryColor}
                            width={'100'}
                        />
                    </div>
                    {/* )} */}
                    <div className=''>
                        <CustomButton2
                            buttonLabel='Next (Set Game Rules)'
                            buttonSize={BUTTON_SIZE.xl}
                            buttonType={BUTTON_TYPE.primary}
                            width={'100'}

                            // onClick={() => setOpen(true)}
                            // width={'225px'}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssignMatchesBattle
