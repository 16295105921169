import React, {useState} from 'react'
import CustomInput, {
    CommonLabel,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomBadge from '../../../../../../customComponents/customBadge/CustomBadge'

const SetRewardsStandardRoulette = () => {
    const [addRow, setAddRow] = useState([{id: 1}])

    const addMilestone = () => {
        setAddRow([...addRow, {id: addRow.length + 1}])
    }

    return (
        <>
            <div className='py-32px'>
                <div className='mb-32px h-310px overflow-auto px-32px'>
                    <div className='d-flex align-items-center flex-row mb-32px'>
                        <label htmlFor='upload-image cursor-pointer'>
                            <KTSVG
                                path='/media/icons/duotune/arena/classic_roulette.svg'
                                // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                                svgClassName='w-50px h-50px cursor-pointer'
                            />
                        </label>

                        <div className='d-flex flex-column ps-3'>
                            <span className='mb-1 text-md-bold text-sqGray900'>
                                Standard Roulette
                            </span>
                            <div className='d-flex gap-8px'>
                                <div>
                                    <CustomBadge
                                        label={'Roulette'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                                <div>
                                    <CustomBadge
                                        label={'Individuals'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                                <div>
                                    <CustomBadge
                                        label={'105 Participants'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-md-semibold text-sqGray900 mb-32px'>Set Reward List</div>

                    <div className=''>
                        <div className='mb-18px'>
                            <div className='text-md-medium text-sqGray900 mb-18px'>
                                Round 1- 10days
                            </div>
                            <div className='row gap-24px'>
                                <div className='col-sm'>
                                    <CustomInput
                                        prefixText='$'
                                        label={'Reward'}
                                        id={`reward`}
                                        placeholder='0'
                                    />
                                </div>
                                <div className='col-sm'>
                                    <CustomDropdown
                                        label={'Select Reward Type'}
                                        id='Missing_Info_Customer_State_Input'
                                        // value={missingData?.customer_state}
                                        // onChange={onChangeInputData}
                                        // options={allLocationsList}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mb-18px'>
                            <div className='text-md-medium text-sqGray900 mb-18px'>
                                Round 2 - 5days
                            </div>
                            <div className='row gap-24px'>
                                <div className='col-sm'>
                                    <CustomInput
                                        prefixText='$'
                                        label={'Reward'}
                                        id={`reward`}
                                        placeholder='0'
                                    />
                                </div>
                                <div className='col-sm'>
                                    <CustomDropdown
                                        label={'Select Reward Type'}
                                        id='Missing_Info_Customer_State_Input'
                                        // value={missingData?.customer_state}
                                        // onChange={onChangeInputData}
                                        // options={allLocationsList}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mb-18px'>
                            <div className='text-md-medium text-sqGray900 mb-18px'>
                                Round 3- 10days
                            </div>
                            <div className='row gap-24px'>
                                <div className='col-sm'>
                                    <CustomInput
                                        prefixText='$'
                                        label={'Reward'}
                                        id={`reward`}
                                        placeholder='0'
                                    />
                                </div>
                                <div className='col-sm'>
                                    <CustomDropdown
                                        label={'Select Reward Type'}
                                        id='Missing_Info_Customer_State_Input'
                                        // value={missingData?.customer_state}
                                        // onChange={onChangeInputData}
                                        // options={allLocationsList}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className='text-md-medium text-sqGray900 mb-18px'>
                                Round 4 - 10days
                            </div>
                            <div className='row gap-24px'>
                                <div className='col-sm'>
                                    <CustomInput
                                        prefixText='$'
                                        label={'Reward'}
                                        id={`reward`}
                                        placeholder='0'
                                    />
                                </div>
                                <div className='col-sm'>
                                    <CustomDropdown
                                        label={'Select Reward Type'}
                                        id='Missing_Info_Customer_State_Input'
                                        // value={missingData?.customer_state}
                                        // onChange={onChangeInputData}
                                        // options={allLocationsList}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-32px'>
                    <div className='mb-18px'>
                        <CustomButton2
                            buttonLabel='Preview Game Map'
                            buttonSize={BUTTON_SIZE.xl}
                            buttonType={BUTTON_TYPE.secondaryColor}
                            width={'100'}
                            // onClick={() => setViewParticipants(true)}
                        />
                    </div>
                    <CustomButton2
                        buttonLabel='Save and Publish'
                        buttonSize={BUTTON_SIZE.xl}
                        buttonType={BUTTON_TYPE.primary}
                        width={'100'}
                    />
                </div>
            </div>
        </>
    )
}

export default SetRewardsStandardRoulette
