import {useCallback, useEffect, useRef, useState} from 'react'
import CustomInput, {
    CommonLabel,
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomButton, {
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {forgotPasswordService, loginService} from '../../../../../../services/Services'
import CustomToast from '../../../../../../customComponents/customToast/CustomToast'
import {loginAction} from '../../../../../../redux/actions/AuthActions'
import {useDispatch} from 'react-redux'
import Show from '../../../../../../customComponents/show/Show'
import CustomImage from '../../../../../../customComponents/customImage/CustomImage'
import {getErrorMessageFromResponse, IMAGE_TYPE} from '../../../../../../helpers/CommonHelpers'
import {getCompanyProfileSelector} from '../../../../../../redux/selectors/SettingsSelectors'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import CustomOTPInput from '../../../../../../customComponents/customOTPInput/CustomOTPInput'
import ubid from 'ubid'
import useBrowserId from '../../../../../../hooks/useBrowserId'
import CustomTimer from '../../../../../../customComponents/customTimer/CustomTimer'
import {resetStoreOnceAction} from '../../../../../../redux/actions/SettingActions'
import CustomLink from '../../../../../../customComponents/customButtton/CustomLink'

const NewLogin = () => {
    const [loginForgot, setLoginForgot] = useState('Signin')
    const [firstTimeLoad, setFirstTimeLoad] = useState(true)
    const resendOtpTimerRef = useRef(null)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [clockInLoading, setClockInLoading] = useState(false)
    const companyData = useSelector(getCompanyProfileSelector)
    const [required2FA, setRequired2FA] = useState(false)
    const [otp, setOTP] = useState('')
    const {browserId} = useBrowserId()

    useEffect(() => {
        dispatch(resetStoreOnceAction())
        setTimeout(() => {
            setFirstTimeLoad(false)
        }, 1000)
    }, [])

    const forgotPassword = () => {
        setEmailError('')
        setEmail('')
        setPassword('')
        setLoginForgot('ForgotPassword')
    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email)
    }

    const changePassword = (event) => {
        event.preventDefault()

        if (email == '') return setEmailError('Email is required')
        if (isValidEmail(email)) {
            setLoading(true)
            const body = {
                email: email,
            }
            forgotPasswordService(body)
                .then((res) => {
                    if (res?.status == false) return CustomToast.error(res?.message)
                    CustomToast.success('Email sent successfully')
                    setLoginForgot('Signin')
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setEmailError('Wrong email format')
        }
    }

    const handleLoginSubmit = useCallback(
        (e, resend = false, isClockIn = false) => {
            e.preventDefault()
            if (email == '' || password == '') {
                if (email == '') setEmailError('Enter Email')
                if (password == '') setPasswordError('Enter Password')
                return
            } else if (isValidEmail(email)) {
                if (!isClockIn) setLoading(true)
                else setClockInLoading(true)
                let body = {
                    email,
                    password,
                    device_token: browserId,
                    two_fa_code: required2FA ? otp : '',
                }
                if (isClockIn) body.worker_type = 'w2'
                if (isClockIn) body.worker_type = 'w2'
                loginService(body)
                    .then((res) => {
                        dispatch(loginAction(res, isClockIn)).catch(() => {
                            if (!isClockIn) setLoading(false)
                            else setClockInLoading(false)
                        })
                    })
                    .catch((error) => {
                        if (resend) CustomToast.success('Otp has been resent ')
                        setLoading(false)
                        if (error?.data?.['2FA']) {
                            setRequired2FA(true)
                        } else {
                            CustomToast.error(getErrorMessageFromResponse(error))
                        }
                    })
                    .finally(() => {
                        if (!isClockIn) setLoading(false)
                        else setClockInLoading(false)
                    })
            } else {
                setEmailError('Enter valid email address')
            }
        },
        [browserId, dispatch, email, otp, password, required2FA]
    )
    const handleClockIn = (e) => {
        handleLoginSubmit(e, false, true)
    }

    return (
        <Show>
            <Show.When isVisible={firstTimeLoad}>
                <div
                    style={{height: '100%', width: '100%'}}
                    className='d-flex align-items-center justify-content-center flex-column splash-screen'
                >
                    <img src='/media/logos/sequifiLogoSVG.svg' alt='Sequifi logo' />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<div>
                                <dotlottie-player src="https://lottie.host/1e67f7a3-aeeb-4caa-a947-64f06ab1fb4c/2EuKxO0oeX.json"
                                    background="transparent" speed="1" style="width: 300px; height: 100px;" loop autoplay></dotlottie-player>
                        </div>`,
                        }}
                    />
                </div>
            </Show.When>
            <Show.Else>
                <div
                    className=''
                    style={{
                        backgroundImage: "url('LoginBgPng.png')",
                        height: '100vh',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                >
                    <div className='d-flex flex-lg-row flex-column gap-sm-10 gap-5 h-100 justify-content-center p-10'>
                        {/*------------------------- RIGHT SIDE UI ------------------------*/}
                        <div className='flex-grow-1 d-sm-block d-none'>
                            <div className='d-flex flex-column flex-center h-100'>
                                <div className='mb-8 text-center'>
                                    <KTSVG
                                        path='/media/icons/duotune/art/Login_User_Icon.svg'
                                        svgClassName='w-md-400px w-75 h-md-100 h-75'
                                    />
                                </div>
                                <div
                                    className='mb-5 text-cmGrey800 text-center'
                                    style={{fontSize: 34, fontWeight: 600, lineHeight: '42px'}}
                                >
                                    Shaping the Future of Work!
                                </div>

                                <div
                                    className='text-center text-cmGrey600'
                                    style={{fontSize: 16, fontWeight: 500, lineHeight: '27px'}}
                                >
                                    Welcome to{' '}
                                    <span
                                        className='text-cmBlue-Crayola'
                                        style={{fontWeight: 700, lineHeight: '27px'}}
                                    >
                                        Sequifi
                                    </span>
                                    ! The ultimate platform for contractors and employers <br />
                                    alike. Shaping the future with trust and transparency.
                                </div>
                            </div>
                        </div>
                        {/*------------------------- Login form ------------------------*/}
                        <div
                            className='w-lg-40 w-100  bg-cmwhite position-relative p-10'
                            style={{borderRadius: 10}}
                        >
                            <div className='d-flex flex-center h-100 w-100 mb-10'>
                                <div className='w-sm-400px w-100 mb-10'>
                                    <div className='text-center mb-14'>
                                        <CustomImage
                                            type={IMAGE_TYPE.companyLogo}
                                            src={companyData?.company_logo_s3}
                                            style={{width: '150px'}}
                                        />
                                    </div>
                                    <div className='mb-8'>
                                        <div
                                            className='mb-2 text-cmGrey900'
                                            style={{
                                                fontSize: 24,
                                                fontWeight: 700,
                                                lineHeight: '32px',
                                            }}
                                        >
                                            <Show>
                                                <Show.When isVisible={required2FA}>2FA</Show.When>
                                                <Show.When isVisible={loginForgot == 'Signin'}>
                                                    Welcome Back!
                                                </Show.When>
                                                <Show.When
                                                    isVisible={loginForgot == 'ForgotPassword'}
                                                >
                                                    Forgot Password
                                                </Show.When>
                                            </Show>
                                        </div>
                                        <div
                                            className='mb-2 text-cmGrey500'
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                lineHeight: '23px',
                                            }}
                                        >
                                            <Show>
                                                <Show.When isVisible={required2FA}>
                                                    Otp has been sent to your registered Email ID!
                                                </Show.When>
                                                <Show.When isVisible={loginForgot == 'Signin'}>
                                                    Log into your Account
                                                </Show.When>
                                            </Show>
                                        </div>
                                    </div>
                                    <Show>
                                        <Show.When isVisible={required2FA}>
                                            <form
                                                action=''
                                                className='w-100'
                                                onSubmit={handleLoginSubmit}
                                            >
                                                <CustomOTPInput otp={otp} setOtp={setOTP} />

                                                <div className='text-end my-5'>
                                                    <CustomTimer
                                                        ref={resendOtpTimerRef}
                                                        seconds={30}
                                                        preLabel='You can resend otp in'
                                                    >
                                                        <CustomLink
                                                            labelClassName={'text-sm-semibold'}
                                                            label={'Resend OTP'}
                                                            underline={false}
                                                            onClick={(e) => {
                                                                resendOtpTimerRef?.current?.restartTimer()
                                                                handleLoginSubmit(e, true)
                                                            }}
                                                        />
                                                    </CustomTimer>
                                                </div>
                                                <div className='mb-6'>
                                                    <CustomButton
                                                        width={100}
                                                        style={{width: '100%'}}
                                                        type='submit'
                                                        onClick={handleLoginSubmit}
                                                        loading={loading}
                                                        buttonLabel={`${
                                                            loading ? 'Please wait...' : 'Submit'
                                                        } `}
                                                    />
                                                </div>
                                                <div className='mb-6'>
                                                    <CustomButton
                                                        buttonType={BUTTON_TYPE.disabled}
                                                        width={100}
                                                        style={{width: '100%'}}
                                                        onClick={() => {
                                                            setRequired2FA(false)
                                                            setOTP('')
                                                            setLoading(false)
                                                        }}
                                                        disabled={loading}
                                                        buttonLabel='Cancel'
                                                    />
                                                </div>
                                            </form>
                                        </Show.When>
                                        <Show.When isVisible={loginForgot == 'Signin'}>
                                            <form
                                                action=''
                                                className='w-100'
                                                onSubmit={handleLoginSubmit}
                                            >
                                                <div className='mb-7'>
                                                    <CustomInput
                                                        labelStyle={{fontWeight: 500}}
                                                        label={'Username'}
                                                        labelColor={'cmGrey600'}
                                                        placeholder='Enter Username'
                                                        type={INPUT_TYPE.email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value)
                                                            setEmailError('')
                                                        }}
                                                        errorMessage={emailError}
                                                        value={email}
                                                    />
                                                </div>
                                                <div className='mb-6'>
                                                    <div className='mb-2'>
                                                        <CustomInput
                                                            labelStyle={{fontWeight: 500}}
                                                            label={'Password'}
                                                            labelColor={'cmGrey600'}
                                                            placeholder='Enter Password'
                                                            type={INPUT_TYPE.password}
                                                            value={password}
                                                            onChange={(e) => {
                                                                setPassword(e.target.value)
                                                                setPasswordError('')
                                                            }}
                                                            errorMessage={passwordError}
                                                        />
                                                    </div>

                                                    <div
                                                        className='text-cmGrey600 text-end text-decoration-underline text-hover-dark cursor-pointer'
                                                        onClick={() => forgotPassword()}
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: 600,
                                                            lineHeight: '16.4px',
                                                        }}
                                                    >
                                                        Forgot Password?
                                                    </div>
                                                </div>
                                                <div className='mb-6'>
                                                    <CustomButton
                                                        width={100}
                                                        style={{width: '100%'}}
                                                        type='submit'
                                                        onClick={handleLoginSubmit}
                                                        loading={loading}
                                                        buttonLabel={`${
                                                            loading ? 'Please wait...' : 'Sign in'
                                                        } `}
                                                        disabled={clockInLoading}
                                                    />
                                                    <CustomButton
                                                        className='text-sm-medium'
                                                        buttonType={BUTTON_TYPE.secondary}
                                                        width={100}
                                                        style={{
                                                            width: '100%',
                                                            fontWeight: 500,
                                                            marginTop: 20,
                                                        }}
                                                        onClick={handleClockIn}
                                                        loading={clockInLoading}
                                                        buttonLabel={`${
                                                            clockInLoading
                                                                ? 'Please wait...'
                                                                : 'Clock In'
                                                        } `}
                                                        disabled={loading}
                                                    />
                                                </div>
                                                <div
                                                    className='text-cmGrey500'
                                                    style={{
                                                        fontSize: 13,
                                                        fontWeight: 500,
                                                        lineHeight: '22px',
                                                    }}
                                                >
                                                    Don’t have a Sequifi Account?{' '}
                                                    <a
                                                        href={'https://sequifi.com/'}
                                                        rel='noreferrer'
                                                        className='text-cmBlue-Crayola'
                                                        target='_blank'
                                                    >
                                                        Contact Us
                                                    </a>
                                                </div>
                                            </form>
                                        </Show.When>
                                        <Show.Else>
                                            <form
                                                action=''
                                                className='w-100'
                                                onSubmit={changePassword}
                                            >
                                                <div className='mb-7'>
                                                    <div className='mb-2'>
                                                        <CustomInput
                                                            labelStyle={{fontWeight: 500}}
                                                            label={'Email'}
                                                            labelColor={'cmGrey600'}
                                                            placeholder='Enter Email Address'
                                                            type={INPUT_TYPE.email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value)
                                                                setEmailError('')
                                                            }}
                                                            errorMessage={emailError}
                                                            value={email}
                                                        />
                                                    </div>
                                                    <div
                                                        className='text-cmGrey600 text-end text-decoration-underline text-hover-dark cursor-pointer'
                                                        onClick={() => {
                                                            setEmailError('')
                                                            setEmail('')
                                                            setLoginForgot('Signin')
                                                        }}
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: 600,
                                                            lineHeight: '16.4px',
                                                        }}
                                                    >
                                                        Sign In?
                                                    </div>
                                                </div>
                                                <div className='mb-6'>
                                                    <CustomButton
                                                        width={100}
                                                        style={{width: '100%'}}
                                                        type='submit'
                                                        onClick={changePassword}
                                                        loading={loading}
                                                        buttonLabel={`${
                                                            loading ? 'Please wait...' : 'Submit'
                                                        } `}
                                                    />
                                                </div>
                                            </form>
                                        </Show.Else>
                                    </Show>
                                </div>
                            </div>
                            <div className='position-absolute bottom-0 end-0 py-6 px-8'>
                                <div className='d-flex align-items-center'>
                                    <span className='text-cmGrey500' style={{fontSize: '12px'}}>
                                        Powered by
                                    </span>
                                    <KTSVG
                                        path='/media/icons/duotune/art/SequifiLogoWithText.svg'
                                        svgClassName='w-100px h-25px'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Show.Else>
        </Show>
    )
}

export default NewLogin
