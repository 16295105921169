import {useLocation, useNavigate} from 'react-router-dom'
import useSequifiConfig from '../../../../sequiConfig/useSequifiConfig'
import {KTSVG} from '../../../../_metronic/helpers'
import {sendDataToReducer, TABLE_BORDER} from '../../../../helpers/CommonHelpers'
import {CustomSortSvg} from '../../../../_metronic/helpers/components/CustomSortSVG'
import {MULTITENANT_CLIENT_DIRECTORY_DROPDOWN_LIST} from '../../../../constants/constants'
import Pagination from '../../admin/sequidocs/component/Pagination'
import CustomTableSkeleton from '../../../../customComponents/customSkeleton/CustomTableSkeleton'
import {useCallback, useEffect, useState} from 'react'
import CustomDropdown from '../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import Show from '../../../../customComponents/show/Show'
import CustomCardSkeleton from '../../../../customComponents/customSkeleton/CustomCardSkeleton'
import {useDispatch} from 'react-redux'
import {getgetBackgroundChecksService} from '../../../../services/Services'
import useAPI from '../../../../hooks/useAPI'
import {useSelector} from 'react-redux'
import {getMultitenantFilterDataSelector} from '../../../../redux/selectors/FilterSelectors'
import {SET_MULTITENANT_FILTER_DATA} from '../../../../redux/reducers/FilterReducer'

const tempData = {
    ApiName: 'getBackgroundByTenantId',
    status: true,
    message: 'Screening Request List',
    data: {
        current_page: 1,
        data: [
            {
                id: '',
                email: '',
                first_name: '',
                last_name: '',
                screening_request_id: '',
                bundle_id: '',
                plan_id: '',
                plan_name: '',
                price: '',
            },
        ],
        first_page_url: 'http://127.0.0.1:8001/api/get_background_by_tenant_id?page=1',
        from: null,
        last_page: 1,
        last_page_url: 'http://127.0.0.1:8001/api/get_background_by_tenant_id?page=1',
        links: [
            {
                url: null,
                label: '&laquo; Previous',
                active: false,
            },
            {
                url: 'http://127.0.0.1:8001/api/get_background_by_tenant_id?page=1',
                label: '1',
                active: true,
            },
            {
                url: null,
                label: 'Next &raquo;',
                active: false,
            },
        ],
        next_page_url: null,
        path: 'http://127.0.0.1:8001/api/get_background_by_tenant_id',
        per_page: 10,
        prev_page_url: null,
        to: null,
        total: 0,
    },
    report_count: 0,
    total_price: null,
}
const BackgroundCheckPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const filterData = useSelector(getMultitenantFilterDataSelector)
    const {callAPI, initAPI, loading: corporateLoading} = useAPI()

    const [backgroundTableData, setBackgroundTableData] = useState()
    const {basedOnCompanyType} = useSequifiConfig()
    const [filter, setFilter] = useState(filterData?.filter)
    const [page, setPage] = useState(filterData?.page)
    const [limit, setLimit] = useState(filterData?.limit)

    const onChangeFilter = (data) => {
        setFilter(data)
    }
    useEffect(() => {
        getPayrollCheckData()
        sendDataToReducer(dispatch, SET_MULTITENANT_FILTER_DATA, {
            ...filterData,
            filter,
            page: page,
            limit: limit,
        })
    }, [page, limit, filter])

    const getPayrollCheckData = useCallback(() => {
        const body = {
            tenant_id: location?.state?.tid,
            filter: filter,
            page: page,
            perpage: Number(limit),
        }
        initAPI().then((signal) => {
            callAPI(getgetBackgroundChecksService(body, signal))
                .then((res) => {
                    setBackgroundTableData(res)
                })
                .catch(() => {})
        })
    }, [callAPI, initAPI, filter, page, limit])

    return (
        <div className='ow container gap-5 mx-auto'>
            {/* <CustomLoader full visible={loading} /> */}
            <div className='d-flex justify-content-between mb-2'>
                <div className='d-flex align-items-center mb-5 gap-3'>
                    <div onClick={() => navigate(-1)}>
                        <KTSVG
                            path='/media/icons/duotune/art/back-square.svg'
                            className='cursor-pointer'
                            svgClassName='w-25px h-25px'
                        />
                    </div>
                    <div className='text-sqGray900 text-xl-bold flex-grow-1'>
                        Flex Power | Background Checks{' '}
                    </div>
                </div>
                <div className='col-sm-3 col-lg-2 col-xl-2'>
                    <CustomDropdown
                        id={'One_time_Payment_Time_Frame_Dropdown'}
                        value={filter}
                        showClear={false}
                        searching={false}
                        options={MULTITENANT_CLIENT_DIRECTORY_DROPDOWN_LIST}
                        valueKey='value'
                        displayKey='name'
                        onChange={(e) => onChangeFilter(e.target.value)}
                    />
                </div>
            </div>
            <Show>
                <Show.When isVisible={corporateLoading}>
                    <CustomCardSkeleton count={2} col='col-sm-2' />
                </Show.When>
                <Show.When isVisible={!corporateLoading}>
                    <div className='container mb-6'>
                        <div className='row gap-6'>
                            <>
                                {/* Card 1 */}
                                <div className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200'>
                                    <div className='card-body'>
                                        <div className='card-title text-sqGray900 text-xl-bold'>
                                            {0}
                                        </div>
                                        <div className='card-text text-sqGray800 text-sm-semibold'>
                                            No. of Background Checks
                                        </div>
                                    </div>
                                </div>
                                {/* Card 2 */}
                                <div className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200'>
                                    <div className='card-body'>
                                        <div className='card-title text-sqGray900  text-xl-bold'>
                                            {0}
                                        </div>
                                        <div className='card-text text-sqGray800  text-sm-semibold'>
                                            Total Cost
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </Show.When>
            </Show>

            <div className='bg-cmwhite mt-6' style={{borderRadius: 10}}>
                <div className='table-responsive' style={{borderRadius: 10}}>
                    <table className='table'>
                        <thead className={TABLE_BORDER}>
                            <tr className='text-sqGray800 bg-sqGray300 text-sm-semibold text-nowrap'>
                                <th className='p-5 text-nowrap'>Employee Name</th>
                                <th className='p-5 text-nowrap'>Email</th>
                                <th className='p-5 text-nowrap'>Background Check Type</th>

                                <th className='p-5 text-nowrap'>
                                    <div className='d-flex align-items-center'>
                                        Price Billed
                                        <CustomSortSvg
                                            id={'comapny_billing_Sort_Icon'}
                                            // sortingOrder={
                                            //     sortValue === 'requested_on' ? sortingOrder : null
                                            // }
                                            // onClick={() => onPress('requested_on')}
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className={TABLE_BORDER}>
                            {corporateLoading ? (
                                <CustomTableSkeleton coloums={4} />
                            ) : backgroundTableData &&
                              backgroundTableData?.data?.data?.length > 0 ? (
                                backgroundTableData?.data?.data?.map((item, index) => (
                                    <tr
                                        key={index}
                                        className={`text-sqGray800 stripRow ${TABLE_BORDER}`}
                                    >
                                        <td className='p-5 text-nowrap text-xs-medium'>
                                            <div className='d-flex gap-4'>{item.name ?? '-'}</div>
                                        </td>
                                        <td className='p-5 text-nowrap text-xs-medium'>
                                            {item.email ?? '-'}
                                        </td>
                                        <td className='p-5 text-nowrap text-xs-medium'>
                                            {item.checkType ?? '-'}
                                        </td>
                                        <td className='p-5 text-nowrap text-xs-bold'>
                                            {item.price ?? '-'}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={4}
                                        className='text-center py-3'
                                        style={{
                                            fontFamily: 'Manrope',
                                            fontWeight: '500',
                                            fontSize: 14,
                                        }}
                                    >
                                        No data found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='pb-5 pe-5'>
                    {backgroundTableData?.data?.data?.length > 0 && (
                        <Pagination
                            tableName={'Background_Check'}
                            page={page}
                            totalPages={backgroundTableData?.data?.last_page}
                            limit={limit}
                            setLimit={setLimit}
                            setPage={(changedPage) => setPage(changedPage)}
                        />
                    )}
                </div>
            </div>
            {/* <div className='d-flex justify-content-center align-items-center mt-6'>
                <CustomButton2
                    buttonLabel='Load More'
                    buttonSize={BUTTON_SIZE_STYLE.lg}
                    buttonType={BUTTON_TYPE.primary}
                />
            </div> */}
        </div>
    )
}

export default BackgroundCheckPage
