import {useCallback, useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {KTSVG, reInitMenu} from '../helpers'
import useIsAccountBillingDue from '../../hooks/useIsAccountBillingDue'
import {useDispatch} from 'react-redux'
import {logoutAction} from '../../redux/actions/AuthActions'
import clsx from 'clsx'
import CustomUserAvatar from '../../customComponents/customUserAvatar/CustomUserAvatar'
import {HeaderMultitenantMenu} from '../partials'
import {useSelector} from 'react-redux'
import {getUserDataSelector} from '../../redux/selectors/AuthSelectors'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'

const MultitenantLayout = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const userData = useSelector(getUserDataSelector)

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const {isBillingOverdue} = useIsAccountBillingDue()
    const [flag, setFlag] = useState(null)

    useEffect(() => {
        reInitMenu()
    }, [location.key])

    useEffect(() => {
        setFlag(!flag)
    }, [isBillingOverdue])

    return (
        <>
            <div className='navbar sticky-top px-3 px-sm-10 mb-10 bg-cmBlue-Crayola h-70px'>
                <div className='d-flex justify-content-between align-items-center w-100 flex-wrap'>
                    <div className='d-flex align-items-center'>
                        <KTSVG
                            path='/media/icons/duotune/art/sequifiWhiteLogo.svg'
                            className='cursor-pointer'
                            svgClassName='w-125px h-45px'
                        />
                        <div
                            className='text-cmwhite text-xl-bold bg-cmGrey900 text-center h-35px min-w-90px d-flex flex-center cursor-pointer ms-2'
                            style={{
                                borderRadius: 10,
                                // fontSize: '18px',
                                // fontWeight: 500,
                                lineHeight: '28px',
                            }}
                        >
                            ADMIN
                        </div>
                    </div>

                    <div className='d-flex align-items-center gap-2 gap-sm-3 mt-3 mt-sm-0'>
                        {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
                            <KTSVG
                                path='/media/icons/duotune/art/notificationMultitenant.svg'
                                svgClassName='w-25px h-25px cursor-pointer'
                            />
                        </div> */}

                        <div className={clsx('app-navbar-item', itemClass)}>
                            <div
                                className={clsx('cursor-pointer symbol', userAvatarClass)}
                                data-kt-menu-trigger="{default: 'click'}"
                                data-kt-menu-attach='parent'
                                data-kt-menu-placement='bottom-end'
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                style={{width: '28px', height: '28px'}}
                            >
                                <CustomUserAvatar
                                    image={userData?.employee_image_s3}
                                    margin='0'
                                    style={{width: '28px', height: '28px'}}
                                    username={`${userData?.first_name} ${userData?.last_name}`}
                                    positionData={userData}
                                />
                            </div>
                            <HeaderMultitenantMenu
                                open={open}
                                handleClose={() => setAnchorEl(null)}
                                anchorEl={anchorEl}
                            />
                        </div>

                        <div className={clsx('app-navbar-item d-none d-sm-block', itemClass)}>
                            <div className='text-xm-medium text-cmwhite'>
                                <span className='text-xs-medium text-cmwhite me-1'>Hi,</span>
                                {userData?.first_name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mb-20 px-sm-0 px-2'>
                <Outlet />
            </div>
            <div
                className='fixed-bottom my-5 text-center text-cmGrey500 px-sm-0 px-5'
                style={{
                    fontFamily: 'roboto',
                    fontSize: 14,
                    fontWeight: 500,
                    wordWrap: 'break-word',
                }}
            >
                © Copyright | <span className='text-cmBlue-Crayola'>www.sequifi.com</span> | All
                rights reserved
            </div>
        </>
    )
}

export {MultitenantLayout}
