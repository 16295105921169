import React, {useState} from 'react'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {
    ARENA_EVENT_TYPES,
    ARENA_NEW_EVENT_MODE,
    ARENA_NEW_EVENT_TYPE_META_DATA,
} from '../../../../../../constants/constants'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ARENA_EVENT_KEYS} from './AddArenaEvent'
// import images from '../../../../../assets/images'

const NewEvent = ({updateEventData, eventData = null, arenaEventErrorData = null}) => {
    // const [eventType, setEventType] = useState(null)
    const eventTypeOptionTemplate = (option) => {
        return (
            <div className='d-flex gap-2'>
                <div className='flex align-items-center'>
                    <KTSVG
                        path={option.icon}
                        className=''
                        svgClassName='w-24px h-24px'
                        // style={{width: '24px', height: '24px'}}
                    />
                </div>
                <div>
                    <span className='text-md-medium text-sqGray900'>{option?.name}</span>
                </div>
            </div>
        )
    }

    const selectedEventTypeTemplate = (option) => {
        if (option) {
            return (
                <div className='d-flex gap-2'>
                    <div className='flex align-items-center'>
                        <KTSVG
                            path={option.icon}
                            className=''
                            svgClassName='w-24px h-24px'
                            // style={{width: '24px', height: '24px'}}
                        />
                    </div>
                    <div>
                        <span className='text-md-medium text-sqGray900'>{option?.name}</span>
                    </div>
                </div>
            )
        }

        return <span className='text-md-regular text-sqGray800'>Select</span>
    }

    return (
        <div className='px-32px'>
            <div className='text-md-semibold mb-32px'>New Event</div>
            <div className='row'>
                <div className='mb-24px'>
                    <CustomDropdown
                        required
                        id='Manage_Leads_Home_Location_Input'
                        label={'Event Type'}
                        placeholder={'Select Event Type'}
                        searching={false}
                        name={ARENA_EVENT_KEYS.event_type}
                        value={eventData?.[ARENA_EVENT_KEYS.event_type]}
                        onChange={updateEventData}
                        errorMessage={arenaEventErrorData?.[ARENA_EVENT_KEYS.event_type]}
                        valueKey='value'
                        displayKey='name'
                        options={ARENA_NEW_EVENT_TYPE_META_DATA}
                        customTemplate={eventTypeOptionTemplate}
                        displayValueTemplate={selectedEventTypeTemplate}
                    />
                </div>
                <div className='mb-24px'>
                    <CustomDropdown
                        required
                        id='Manage_Leads_Home_Location_Input'
                        label={'Event Mode'}
                        options={ARENA_NEW_EVENT_MODE}
                        name={ARENA_EVENT_KEYS.event_mode}
                        errorMessage={arenaEventErrorData?.[ARENA_EVENT_KEYS.event_mode]}
                        value={eventData?.[ARENA_EVENT_KEYS.event_mode]}
                        onChange={updateEventData}
                        valueKey='value'
                        displayKey='name'
                    />
                </div>
            </div>
            <div className='mb-24px'>
                <CustomInput
                    required
                    label={'Event Name'}
                    id='Manage_Leads_First_Name_Input'
                    placeholder='Select Event Name'
                    name={ARENA_EVENT_KEYS.event_name}
                    value={eventData?.[ARENA_EVENT_KEYS.event_name]}
                    onChange={updateEventData}
                    errorMessage={arenaEventErrorData?.[ARENA_EVENT_KEYS.event_name]}
                />
            </div>
            <div className=''>
                <CustomInput
                    required
                    type={INPUT_TYPE.textarea}
                    label={'Event Description'}
                    id='Manage_Leads_First_Name_Input'
                    placeholder='Event Description Here'
                    name={ARENA_EVENT_KEYS.event_description}
                    value={eventData?.[ARENA_EVENT_KEYS.event_description]}
                    onChange={updateEventData}
                    errorMessage={arenaEventErrorData?.[ARENA_EVENT_KEYS.event_description]}
                    style={{minHeight: '130px'}}
                    maxLength={350}
                />
            </div>
            <span className='text-sm-regular text-sqGray500'>
                {eventData?.[ARENA_EVENT_KEYS.event_description]?.length}/350 characters
            </span>
        </div>
    )
}

export default NewEvent
