import defaultCompanyImage from './images/defaultCompanyImage.png'
import defaultUserImage from './images/defaultUserImage.png'
import lock from './images/lock.png'
import unlock from './images/unlock.png'
import sequifiLogo from './images/sequifiLogo.png'
import sequiPayLogo from './images/sequiPayLogo.png'
import maintenance from './images/maintenance.png'
import check from './images/check.png'

const images = {
    sequifiLogo,
    sequiPayLogo,
    defaultCompanyImage,
    defaultUserImage,
    lock,
    unlock,
    check,
    maintenance,
}

export default images
