import React from 'react'
import {fontsFamily} from '../../assets/fonts/fonts'

const CustomBadge = ({
    backgroundColor = null,
    textColor = 'cminfo',
    px = '5',
    py = null,
    cusrsor = false,
    id = null,
    onClick = null,
    label = null,
    fontSize = 12,
    fontWeight = 500,
    opacity = true,
    style = null,
    className = null,
    rounded = false,
}) => {
    return (
        <div
            id={id}
            onClick={onClick}
            className={`badge ${rounded ? 'rounded-pill' : null} text-${textColor} bg-${
                backgroundColor ?? textColor
            } ${opacity ? 'bg-opacity-10' : ''} px-${px} py-${py} ${
                cusrsor ? 'cursor-pointer' : null
            } ${className}`}
            style={{
                fontSize: fontSize,
                fontWeight: fontWeight,
                fontFamily: fontsFamily.manrope,
                ...style,
            }}
        >
            {label}
        </div>
    )
}

export default CustomBadge
