import React, {useEffect, useState} from 'react'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import {KTSVG} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import ActiveBillingCrad from './clientDirectoryTopCards/ActiveBillingCrad'
import TotalSalesCard from './clientDirectoryTopCards/TotalSalesCard'
import ClientandUsersCard from './clientDirectoryTopCards/ClientandUsersCard'
import ClientDirectoryTable from './clientDirectoryTable/ClientDirectoryTable'
import CustomDropdown from '../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {MULTITENANT_CLIENT_DIRECTORY_DROPDOWN_LIST} from '../../../../constants/constants'
import {
    getClientsAndActiveUsersDashboardService,
    getPayrollCheckOneTimePaymentsService,
    getTenantBillingGraphDataService,
    getTenantDetailsDashboardListService,
    getTotalEarningService,
} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import {getErrorMessageFromResponse, sendDataToReducer} from '../../../../helpers/CommonHelpers'
import {useSelector} from 'react-redux'
import {getMultitenantFilterDataSelector} from '../../../../redux/selectors/FilterSelectors'
import {SET_MULTITENANT_FILTER_DATA} from '../../../../redux/reducers/FilterReducer'
import {useDispatch} from 'react-redux'

const ClientDirectoryPage = () => {
    const naviagte = useNavigate()
    const dispatch = useDispatch()
    const filterData = useSelector(getMultitenantFilterDataSelector)

    const [filter, setFilter] = useState(filterData?.filter)
    const [page, setPage] = useState(filterData?.page)
    const [limit, setLimit] = useState(filterData?.limit)
    const [searchVal, setSearchVal] = useState(filterData?.search)
    const [tableFilter, setTableFilter] = useState()

    const [dashboardFilterData, setDashboardFilterData] = useState(null)
    const [totalSalesData, setTotalSalesData] = useState(null)
    const [clientUserData, setClientUserData] = useState(null)
    const [clientDashboardData, setClientDashboardData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingDashboardTable, setloadingDashboardTable] = useState(false)

    //Graph data
    const [activeGraphData, setActiveGraphData] = useState()

    const onChangeFilter = (data) => {
        setFilter(data)
    }

    const getFilterActiveUserDashboard = () => {
        const body = {
            filter: filter,
        }
        setLoading(true)
        getClientsAndActiveUsersDashboardService(body)
            .then((res) => {
                setDashboardFilterData(res?.data)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }

    const getActiveBillingGraphData = () => {
        const body = {
            filter: filter,
        }
        getTenantBillingGraphDataService(body)
            .then((res) => {
                setActiveGraphData(res)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }

    const getTotalEarnings = () => {
        const body = {
            filter: filter,
        }
        setLoading(true)
        getTotalEarningService(body)
            .then((res) => {
                setTotalSalesData(res?.data)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }
    const getPayrollCheckOneTime = () => {
        const body = {
            filter: filter,
        }
        setLoading(true)
        getPayrollCheckOneTimePaymentsService(body)
            .then((res) => {
                setClientUserData(res)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }

    const getClientDirectoryDataList = () => {
        const body = {
            search: searchVal,
            company_type: tableFilter,
            page: page,
            perpage: Number(limit),
        }
        setloadingDashboardTable(true)
        getTenantDetailsDashboardListService(body)
            .then((res) => {
                setClientDashboardData(res?.data)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setloadingDashboardTable(false))
    }

    useEffect(() => {
        getFilterActiveUserDashboard()
        getActiveBillingGraphData()
        getTotalEarnings()
        getPayrollCheckOneTime()
        sendDataToReducer(dispatch, SET_MULTITENANT_FILTER_DATA, {
            ...filterData,
            filter,
            page: page,
            limit: limit,
            search: searchVal,
        })
    }, [filter])

    useEffect(() => {
        getClientDirectoryDataList()
        sendDataToReducer(dispatch, SET_MULTITENANT_FILTER_DATA, {
            ...filterData,
            filter,
            page: page,
            limit: limit,
            search: searchVal,
        })
    }, [filter, page, limit, searchVal, tableFilter])
    return (
        <div className='container mt-6' style={{fontFamily: fontsFamily.manrope}}>
            {/* <CustomLoader visible={loading} full /> */}
            <div className='d-flex justify-content-between mb-2'>
                <div className='d-flex align-items-center gap-3 '>
                    <div>
                        <KTSVG
                            path='/media/icons/duotune/art/back-square.svg'
                            svgClassName='h-25px w-25px cursor-pointer'
                            onClick={() => naviagte(-1)}
                        />
                    </div>
                    <div className='text-cmGrey900 text-xl-bold my-5'>Client Directory</div>
                </div>
                <div className='col-sm-3 col-lg-2 col-xl-2'>
                    <CustomDropdown
                        id={'One_time_Payment_Time_Frame_Dropdown'}
                        value={filter}
                        showClear={false}
                        searching={false}
                        options={MULTITENANT_CLIENT_DIRECTORY_DROPDOWN_LIST}
                        valueKey='value'
                        displayKey='name'
                        onChange={(e) => onChangeFilter(e.target.value)}
                    />
                </div>
            </div>
            {/* Cards */}
            <div className='mb-10 row mx-auto gap-10'>
                <ActiveBillingCrad activeGraphData={activeGraphData} loading={loading} />
                <TotalSalesCard totalSalesData={totalSalesData} loading={loading} />
                <ClientandUsersCard
                    clientUserData={clientUserData}
                    loading={loading}
                    dashboardFilterData={dashboardFilterData}
                />
            </div>
            {/* table */}
            <ClientDirectoryTable
                clientDashboardData={clientDashboardData}
                loading={loadingDashboardTable}
                page={page}
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                tableFilter={tableFilter}
                setTableFilter={setTableFilter}
            />
        </div>
    )
}

export default ClientDirectoryPage
