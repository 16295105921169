import React from 'react'
import {InputSwitch} from 'primereact/inputswitch'

const CustomToggleBtn = ({
    onChange = null,
    checked = null,
    name = null,

    disabled = false,
    id,
}) => {
    return (
        <InputSwitch
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            id={id}
            name={name}
        />
    )
}

export default CustomToggleBtn
