import React, {useState} from 'react'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../customComponents/customInputs/customInput/CustomInput'
import {formattedNumberFields, TABLE_BORDER} from '../../../../../helpers/CommonHelpers'
import Pagination from '../../../admin/sequidocs/component/Pagination'
import {useNavigate} from 'react-router-dom'
import CustomImage from '../../../../../customComponents/customImage/CustomImage'
import images from '../../../../../assets/images'
import CustomDropdown from '../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {COMPANY_TYPE} from '../../../../../sequiConfig/useBasedOnCompanyType'
import CustomTableSkeleton from '../../../../../customComponents/customSkeleton/CustomTableSkeleton'

const ClientDirectoryTable = ({
    clientDashboardData,
    loading,
    page,
    limit,
    setPage,
    setLimit,
    searchVal,
    setSearchVal,
    tableFilter,
    setTableFilter,
}) => {
    const naviagte = useNavigate()

    const companyTypeArray = Object.entries(COMPANY_TYPE).map(([value, name]) => ({
        name,
        value,
    }))

    const onChangeFilter = (data) => {
        setTableFilter(data)
    }

    const handleSearchChange = (e) => {
        setSearchVal(e.target.value)
    }
    return (
        <div className='bg-cmwhite shadow-sm' style={{borderRadius: 10}}>
            <div className='row align-items-center justify-content-between px-sm-10 px-5 py-5'>
                <div className='col-sm-3'>
                    {/* <CustomInput type={INPUT_TYPE.search} /> */}
                    <CustomInput
                        type={INPUT_TYPE.search}
                        name='search'
                        id={'Alert_Center_Table_Search'}
                        onChange={handleSearchChange}
                        value={searchVal}
                    />
                </div>
                <div className='col-sm-3 col-lg-2 ms-auto'>
                    {/* <CustomFilterButton /> */}
                    <CustomDropdown
                        id={'One_time_Payment_Time_Frame_Dropdown'}
                        value={tableFilter}
                        searching={false}
                        options={companyTypeArray}
                        valueKey='value'
                        displayKey='name'
                        onChange={(e) => onChangeFilter(e.target.value)}
                    />
                </div>
            </div>
            <div className='table-responsive'>
                <table className='table' style={{verticalAlign: 'baseline'}}>
                    <thead>
                        <tr className='bg-sqGray200 text-sqGray800 text-sm-semibold'>
                            <td className='p-5 ps-sm-10'>Client ID</td>
                            <td className='p-5'>Name</td>
                            <td className='p-5'>Company Type</td>
                            <td className='p-5'>Products</td>
                            <td className='p-5'>Admins</td>
                            <td className='p-5'>Active Users</td>
                            <td className='p-5'>Total Paid YTD</td>
                            <td className='p-5'>Current Bill</td>
                            <td className='p-5'>Status</td>
                        </tr>
                    </thead>
                    <tbody className={TABLE_BORDER}>
                        {loading ? (
                            <CustomTableSkeleton coloums={9} />
                        ) : clientDashboardData?.data?.length > 0 ? (
                            <>
                                {clientDashboardData?.data?.map((item, index) => (
                                    <tr className={TABLE_BORDER}>
                                        {item?.onboarding_status == 'Draft' ? (
                                            <td
                                                className='p-5 ps-sm-10 text-sqPrimary600 cursor-pointer'
                                                onClick={() =>
                                                    naviagte('/new-user', {
                                                        state: {
                                                            tid: item?.tenant_id,
                                                        },
                                                    })
                                                }
                                            >
                                                <u>Draft</u>
                                            </td>
                                        ) : (
                                            <td className='p-5 ps-sm-10'>{item?.tenant_id}</td>
                                        )}
                                        <td className='p-5  '>
                                            <div
                                                className='text-xs-bold text-sqGray900 text-decoration-underline cursor-pointer'
                                                onClick={() =>
                                                    item?.onboarding_status === 'Draft'
                                                        ? naviagte('/new-user', {
                                                              state: {
                                                                  tid: item?.tenant_id,
                                                              },
                                                          })
                                                        : naviagte('/client-overview', {
                                                              state: {
                                                                  tid: item?.tenant_id,
                                                              },
                                                          })
                                                }
                                            >
                                                {item?.tenant_name}
                                            </div>
                                        </td>
                                        <td className='p-5'>
                                            {item?.onboarding_status === 'Draft' ? (
                                                '-'
                                            ) : (
                                                <>
                                                    {item?.company_type === 'Solar' ? (
                                                        <div className='badge rounded-pill bg-sqPrimary600 bg-opacity-10 px-5 text-sqPrimary600 text-xs-bold'>
                                                            {item?.company_type}
                                                        </div>
                                                    ) : item?.company_type === 'Real Estate' ? (
                                                        <div className='badge rounded-pill bg-sqPurple600 bg-opacity-10 px-5 text-sqPurple600 text-xs-bold'>
                                                            {item?.company_type}
                                                        </div>
                                                    ) : item?.company_type === 'Pest Control' ? (
                                                        <div className='badge rounded-pill bg-sqSuccess600 bg-opacity-10 px-5 text-sqSuccess600 text-xs-bold'>
                                                            {item?.company_type}
                                                        </div>
                                                    ) : item?.company_type === 'Turf' ? (
                                                        <div className='badge rounded-pill bg-sqSuccess600 bg-opacity-10 px-5 text-sqSuccess600 text-xs-bold'>
                                                            {item?.company_type}
                                                        </div>
                                                    ) : item?.company_type === 'Roofing' ? (
                                                        <div className='badge rounded-pill bg-sqSuccess600 bg-opacity-10 px-5 text-sqSuccess600 text-xs-bold'>
                                                            {item?.company_type}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            <div className='symbol-group symbol-hover flex-nowrap mt-2'>
                                                {item?.onboarding_status === 'Draft'
                                                    ? '-'
                                                    : item?.products?.map((data, index) => (
                                                          <div
                                                              className='symbol symbol-35px symbol-circle shadow-sm'
                                                              data-bs-toggle='tooltip'
                                                              title={data?.name}
                                                              key={`cw7-item-${index}`}
                                                          >
                                                              <CustomImage
                                                                  alt='Pic'
                                                                  src={
                                                                      data?.logo ||
                                                                      images?.sequifiLogo
                                                                  }
                                                              />
                                                          </div>
                                                      ))}
                                            </div>
                                        </td>
                                        <td className='p-5 text-xs-medium'>
                                            {item?.onboarding_status == 'Draft'
                                                ? '-'
                                                : item?.admins}
                                        </td>
                                        <td className='p-5 text-xs-medium'>
                                            {item?.onboarding_status == 'Draft'
                                                ? '-'
                                                : item?.active_users}
                                        </td>
                                        <td className='p-5 text-xs-medium'>
                                            {item?.onboarding_status === 'Draft'
                                                ? '-'
                                                : formattedNumberFields(item?.total_paid_ytd)}
                                        </td>

                                        <td className='p-5 text-cmGrey800 text-xs-medium'>
                                            {item?.onboarding_status === 'Draft'
                                                ? '-'
                                                : formattedNumberFields(
                                                      item?.current_bill[0]?.grand_total
                                                  )}
                                        </td>
                                        <td className='p-5 text-sqSuccess600 text-xs-bold'>
                                            {item?.onboarding_status === 'Draft'
                                                ? '-'
                                                : item?.current_bill[0]?.paid_status === 0
                                                ? 'Paid'
                                                : item?.current_bill[0]?.paid_status === 1
                                                ? 'Unpaid'
                                                : item?.current_bill[0]?.paid_status === 2
                                                ? 'Overdue'
                                                : null}
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td
                                    colSpan={12}
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: 'Manrope',
                                        fontWeight: '500',
                                        fontSize: 14,
                                        paddingTop: 20,
                                        paddingBottom: 20,
                                    }}
                                >
                                    No data found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <Pagination
                tableName={'Client_Directory_Table'}
                page={page}
                totalPages={clientDashboardData?.last_page}
                limit={limit}
                setLimit={setLimit}
                setPage={(changedPage) => setPage(changedPage)}
            />
        </div>
    )
}

export default ClientDirectoryTable
