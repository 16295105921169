import React, {useState} from 'react'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomDatePicker from '../../../../../../customComponents/customInputs/customDatePicker/CustomDatePicker'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomDelete from '../../../../../../customComponents/customIcons/CustomDelete'
import CustomBadge from '../../../../../../customComponents/customBadge/CustomBadge'

const SetGameRulesBattle = () => {
    const [image, setImage] = useState(null)
    const [addRound, setAddRound] = useState([{id: 1}])

    const HandleAddRound = () => {
        setAddRound([...addRound, {id: addRound.length + 1}])
    }

    const removeRound = (indexToRemove) => {
        setAddRound((prevRounds) => prevRounds?.filter((_, index) => index !== indexToRemove))
    }
    return (
        <div className='py-32px'>
            <div className='mb-14px h-350px overflow-auto px-32px'>
                <div className='d-flex align-items-center flex-column flex-sm-row mb-32px'>
                    <label htmlFor='upload-image'>
                        <KTSVG
                            path='/media/icons/duotune/arena/battle.svg'
                            svgClassName='w-50px h-50px cursor-pointer'
                        />
                    </label>

                    <div className='d-flex flex-column align-items-center align-items-sm-start ps-sm-3 mt-3 mt-sm-0'>
                        <span className='mb-1 text-md-bold text-sqGray900 text-center text-sm-start'>
                            Lead the Pack and Win it All!
                        </span>
                        <div className='d-flex flex-wrap justify-content-center justify-content-sm-start gap-8px'>
                            <CustomBadge
                                label={'Ladder'}
                                textColor='sqGray700'
                                fontWeight={700}
                                fontSize={12}
                                px={8}
                                py={2}
                                backgroundColor={'sqGray100'}
                                opacity={false}
                                rounded={true}
                            />
                            <CustomBadge
                                label={'Individuals'}
                                textColor='sqGray700'
                                fontWeight={700}
                                fontSize={12}
                                px={8}
                                py={2}
                                backgroundColor={'sqGray100'}
                                opacity={false}
                                rounded={true}
                            />
                            <CustomBadge
                                label={'64 Participants'}
                                textColor='sqGray700'
                                fontWeight={700}
                                fontSize={12}
                                px={8}
                                py={2}
                                backgroundColor={'sqGray100'}
                                opacity={false}
                                rounded={true}
                            />
                        </div>
                    </div>
                </div>

                <div className=''>
                    <div className='text-md-semibold text-sqGray900 mb-32px'>Set Game Rules</div>

                    <div className='text-sm-bold text-sqGray900 mb-18px'>Sold As</div>
                    <div className='row mb-18px gap-2px'>
                        <div className='col-sm'>
                            <CustomInput
                                label={'Setter'}
                                id='Manage_Leads_First_Name_Input'
                                placeholder='0'
                                // onChange={(e) => {
                                //     setFirstName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.firstName}
                                // value={firstname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Closer'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Self-Gen'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                    </div>
                    <div className='text-sm-bold text-sqGray900 mb-18px'>Installed As</div>
                    <div className='row mb-18px gap-2'>
                        <div className='col-sm'>
                            <CustomInput
                                label={'Setter'}
                                id='Manage_Leads_First_Name_Input'
                                placeholder='0'
                                // onChange={(e) => {
                                //     setFirstName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.firstName}
                                // value={firstname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Closer'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Self-Gen'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                    </div>
                    <div className='text-sm-bold text-sqGray900 mb-18px'>KW Sold as</div>
                    <div className='row mb-18px gap-2'>
                        <div className='col-sm'>
                            <CustomInput
                                label={'Setter'}
                                id='Manage_Leads_First_Name_Input'
                                placeholder='0'
                                // onChange={(e) => {
                                //     setFirstName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.firstName}
                                // value={firstname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Closer'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Self-Gen'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                    </div>
                    <div className='text-sm-bold text-sqGray900 mb-18px'>KW Installed As</div>
                    <div className='row mb-18px gap-2'>
                        <div className='col-sm'>
                            <CustomInput
                                label={'Setter'}
                                id='Manage_Leads_First_Name_Input'
                                placeholder='0'
                                // onChange={(e) => {
                                //     setFirstName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.firstName}
                                // value={firstname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Closer'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Self-Gen'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                    </div>
                    <div className='row mb-32px gap-2'>
                        <div className='col-sm'>
                            <CustomInput
                                label={'New Hiring Leads'}
                                id='Manage_Leads_First_Name_Input'
                                placeholder='0'
                                // onChange={(e) => {
                                //     setFirstName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.firstName}
                                // value={firstname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'New Recruit Hired'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Hours Worked'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                id='Manage_Leads_First_Last_Input'
                                label={'Days On-Time'}
                                placeholder='0'
                                // onChange={(e) => {
                                //     setLastName(e.target.value)
                                // }}
                                // errorMessage={referalFormError?.lastName}
                                // value={lastname}
                                // rejex={/^[\w\-\s]+$/}
                            />
                        </div>
                    </div>
                    <div className='text-sm-bold text-sqGray900 mb-32px'>Competition Duration</div>
                    <div className='row gap-2 mb-32px'>
                        <div className='col-sm'>
                            <CustomDropdown
                                id='Manage_Leads_Home_Location_Input'
                                label={'Competition Days'}
                                valueKey='id'
                                // options={allState}
                                // value={homestate}
                                // onChange={(e) => setHomeState(e.target.value)}
                                errorMessage={''}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomDropdown
                                id='Manage_Leads_Home_Location_Input'
                                label={'Duration'}
                                valueKey='id'
                                // options={allState}
                                // value={homestate}
                                // onChange={(e) => setHomeState(e.target.value)}
                                errorMessage={''}
                            />
                        </div>
                    </div>
                    <div className='row align-items-center gap-2'>
                        <div className='col-sm d-flex flex-column'>
                            <label className='text-sm-medium text-sqGrey700 mb-6px'>
                                Start Date
                            </label>
                            <CustomDatePicker
                                id={'One_time_Payment_Start_Date_Input_1'}
                                placeholderText={'Start Date'}
                                name='startDate'
                                // value={startDate}
                                // onChange={(event) => {
                                //     setStartDate(event.target.value)
                                // }}
                                // maxDate={customMoment.todayDate()}
                            />
                        </div>

                        <div className='col-sm d-flex flex-column'>
                            <label className='text-sm-medium text-cmGrey700 mb-6px'>End Date</label>
                            <CustomDatePicker
                                id={'One_time_Payment_Start_Date_Input_2'}
                                placeholderText={'Start Date'}
                                name='startDate'
                                // value={startDate}
                                // onChange={(event) => {
                                //     setStartDate(event.target.value)
                                // }}
                                // maxDate={customMoment.todayDate()}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-32px py-14px'>
                <CustomButton2
                    buttonLabel='Next (Set Game Rules)'
                    buttonSize={BUTTON_SIZE.xl}
                    buttonType={BUTTON_TYPE.primary}
                    width={'100'}

                    // onClick={() => setOpen(true)}
                    // width={'225px'}
                />
            </div>
        </div>
    )
}

export default SetGameRulesBattle
