import clsx from 'clsx'
import CustomImage from '../../../../../customComponents/customImage/CustomImage'
import CustomButton, {BUTTON_SIZE} from '../../../../../customComponents/customButtton/CustomButton'
import Show from '../../../../../customComponents/show/Show'
import CustomCardSkeleton from '../../../../../customComponents/customSkeleton/CustomCardSkeleton'

const ClientandUsersCard = ({dashboardFilterData, clientUserData, loading}) => {
    return (
        <Show>
            <Show.When isVisible={loading}>
                <CustomCardSkeleton
                    count={1}
                    containerWidth='col-md-12 col-lg col-sm-12 col-12 ps-0 pe-0 mx-auto'
                    col='px-0'
                    height='220px'
                />
            </Show.When>
            <Show.When isVisible={!loading}>
                <div
                    className={`bg-cmwhite shadow-sm col-md-12 col-lg col-12 px-sm-10 py-5`}
                    style={{borderRadius: 10}}
                >
                    {/* Heading */}
                    <div className='row mb-15'>
                        <div className='col-lg-3 col-md-3 col-12'>
                            <div className='text-sqGray900 text-dsm-bold'>
                                {dashboardFilterData?.tenantCount
                                    ? dashboardFilterData?.tenantCount
                                    : 0}
                            </div>
                            <div className='text-sqGray500 text-md-medium'>Clients</div>
                        </div>
                        <div className='vr p-0 me-1 my-2' style={{width: 0.5}} />
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='text-sqGray900 text-dsm-bold'>
                                {dashboardFilterData?.total_active_users
                                    ? dashboardFilterData?.total_active_users
                                    : 0}
                            </div>
                            <div className='text-sqGray500 text-md-medium'>Active Users</div>
                        </div>
                        <div className='vr p-0 me-1 my-2' style={{width: 0.5}} />
                        <div className='col-lg col-md-4 col-12'>
                            <div className='text-sqGray900 text-dsm-bold'>
                                {dashboardFilterData?.this_month_tenantCount
                                    ? dashboardFilterData?.this_month_tenantCount
                                    : 0}
                            </div>
                            <div className='text-sqGray500 text-md-medium'>Background Checks</div>
                        </div>
                    </div>
                    <div className='row align-items-center justify-content-between '>
                        <div className='col-lg-4 col-md-4 col-12 '>
                            <div className='text-sqGray900 text-dsm-bold'>
                                {clientUserData?.total_payroll_count
                                    ? clientUserData?.total_payroll_count
                                    : 0}
                            </div>
                            <div className='text-sqGray500 text-md-medium'>Payroll Checks</div>
                        </div>
                        <div className='col-lg-1 col-md-1' />
                        <div className='col-lg-1 col-md-1 p-0 vr my-2' style={{width: 0.5}} />
                        <div className='col-lg-1 col-md-1' />
                        <div className='col-lg-5 col-md-4 col-12'>
                            <div>
                                <div className='text-sqGray900 text-dsm-bold'>
                                    {clientUserData?.total_one_time_payment_count
                                        ? clientUserData?.total_one_time_payment_count
                                        : 0}
                                </div>
                                <div className='text-sqGray500 text-md-medium'>One-Time Checks</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='symbol-group symbol-hover flex-nowrap my-10'>
                {items.map((item, index) => (
                    <div
                        className='symbol symbol-35px symbol-circle'
                        data-bs-toggle='tooltip'
                        title={item.name}
                        key={`cw7-item-${index}`}
                    >
                        {item.src && <CustomImage alt='Pic' src={item.src} />}
                        {item.state && item.initials && (
                            <span
                                className={clsx(
                                    'symbol-label fw-bold',
                                    'bg-' + item.state,
                                    'text-inverse-' + item.state
                                )}
                            >
                                {item.initials}
                            </span>
                        )}
                    </div>
                ))}

                <a href='#' className='symbol symbol-35px symbol-circle'>
                    <span
                        className={clsx(
                            'symbol-label fs-8 fw-bold',
                            'bg-' + 'cmError',
                            'text-' + 'cmwhite'
                        )}
                    >
                        +42
                    </span>
                </a>
            </div> */}

                    {/* <CustomButton buttonLabel='Add New' buttonSize={BUTTON_SIZE.small} /> */}
                </div>
            </Show.When>
        </Show>
    )
}

export default ClientandUsersCard
