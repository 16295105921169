/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {ROLES} from '../../../../../accessRights/AccessRightsConstants'
import {
    getActiveRoleSelector,
    getUserDataSelector,
} from '../../../../../redux/selectors/AuthSelectors'
import AccessRights from '../../../../../accessRights/AccessRights'
import {useDispatch} from 'react-redux'
import {getAlertCenterListAction} from '../../../../../redux/actions/DashboardActions'
import {getAlertCenterCount} from '../../../../../redux/selectors/DashboardSelectors'
import useCustomAccessRights, {
    allPermissionsAccess,
} from '../../../../../accessRights/useCustomAccessRights'
import {getCompanySettingSelector} from '../../../../../redux/selectors/SettingsSelectors'
import {KTSVG} from '../../../../helpers'
import {INTEGRATIONS_ID} from '../../../../../constants/constants'
import {getIntegrationListSelector} from '../../../../../redux/selectors/IntegrationSelectors'
import useSequifiConfig from '../../../../../sequiConfig/useSequifiConfig'
import Show from '../../../../../customComponents/show/Show'
import useIsAccountBillingDue from '../../../../../hooks/useIsAccountBillingDue'
import {getBooleanValue} from '../../../../../helpers/CommonHelpers'

const SidebarMenuMain = () => {
    const intl = useIntl()
    const alertCenterListCount = useSelector(getAlertCenterCount)
    const {isBillingOverdue} = useIsAccountBillingDue()
    const dispatch = useDispatch()
    const {pathname} = useLocation()
    const activeRole = useSelector(getActiveRoleSelector)
    const {sideBarAccess} = useCustomAccessRights()
    const companySetting = useSelector(getCompanySettingSelector)
    const userDetails = useSelector(getUserDataSelector)

    const integrationList = useSelector(getIntegrationListSelector)
    const checkSclearnceActive = integrationList.find(
        (val) => val?.id == INTEGRATIONS_ID.sClearance && val?.status == 1
    )?.status
    const saleCRMActive = integrationList.find(
        (val) => val?.id == INTEGRATIONS_ID.salesCRM && val?.status == 1
    )?.status

    const {basedOnHost} = useSequifiConfig()

    useEffect(() => {
        dispatch(getAlertCenterListAction())
    }, [])

    const alertsCount = useMemo(() => {
        return (
            alertCenterListCount?.closedPayroll +
            alertCenterListCount?.locationRedline +
            alertCenterListCount?.missingRep +
            alertCenterListCount?.repRedline +
            alertCenterListCount?.sales +
            alertCenterListCount?.people
        )
    }, [
        alertCenterListCount?.closedPayroll,
        alertCenterListCount?.locationRedline,
        alertCenterListCount?.missingRep,
        alertCenterListCount?.people,
        alertCenterListCount?.repRedline,
        alertCenterListCount?.sales,
    ])

    const redirectRoute = useMemo(() => {
        return getRedirectRoute({companySetting, userDetails})
    }, [companySetting, userDetails])

    return activeRole ? (
        <>
            {activeRole === ROLES.standard.roleValue ? (
                <>
                    <AccessRights ignorePosition customCondition={sideBarAccess.standard.dashboard}>
                        <SidebarMenuItem
                            to={redirectRoute.standard.dashboard}
                            followActiveRouteInnerLogic={false}
                            isActiveRoute={pathname?.includes('standard-dashboard')}
                            icon={
                                pathname.includes('standard-dashboard')
                                    ? '/media/icons/duotune/art/dashboard1.svg'
                                    : '/media/icons/duotune/art/shape.svg'
                            }
                            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                            fontIcon='bi-app-indicator'
                        />
                    </AccessRights>
                    <Show>
                        <Show.When isVisible={!isBillingOverdue}>
                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.standard.mySales}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.standard.mySales}
                                    icon={
                                        pathname.includes('/mysales')
                                            ? '/media/icons/duotune/art/MySales.svg'
                                            : '/media/icons/duotune/art/MySalesInactive.svg'
                                    }
                                    title='My Earnings'
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>
                            <AccessRights
                                ignorePosition
                                customCondition={true}
                                // customCondition={sideBarAccess.standard.arena}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.standard.arena}
                                    icon={
                                        pathname.includes('/arena')
                                            ? '/media/icons/duotune/art/Arena.svg'
                                            : '/media/icons/duotune/art/Arena.svg'
                                    }
                                    title='Arena'
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>
                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.standard.referrals}
                            >
                                <SidebarMenuItem
                                    to='/referrals'
                                    icon={
                                        pathname.includes('/referrals')
                                            ? '/media/icons/duotune/art/HiringActive.svg'
                                            : '/media/icons/duotune/art/Hiring.svg  '
                                    }
                                    title='Referrals'
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>
                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.standard.sequiDocs}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.standard.sequiDocs}
                                    followActiveRouteInnerLogic={false}
                                    isActiveRoute={pathname?.includes('sequidocs')}
                                    icon={
                                        pathname.includes('sequidocs')
                                            ? '/media/icons/duotune/art/doc1.svg'
                                            : '/media/icons/duotune/art/doc.svg'
                                    }
                                    title={
                                        <KTSVG
                                            path='/media/icons/duotune/art/SequiDocLogo.svg'
                                            svgStyle={{
                                                height: '25px',
                                                width: '100px',
                                                marginLeft: '-8px',
                                            }}
                                        />
                                    }
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>

                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.standard.hiring}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.standard.hiring}
                                    icon={
                                        pathname.includes('/hiring')
                                            ? '/media/icons/duotune/art/HiringActive.svg'
                                            : '/media/icons/duotune/art/Hiring.svg'
                                    }
                                    title='Hiring'
                                />
                            </AccessRights>

                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.standard.calendar}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.standard.calendar}
                                    icon={
                                        pathname.includes('/calendar')
                                            ? '/media/icons/duotune/art/ClaendarActive.svg'
                                            : '/media/icons/duotune/art/Calendar.svg'
                                    }
                                    title='Calendar'
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>
                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.standard.management}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.standard.management}
                                    icon={
                                        pathname.includes('/management')
                                            ? '/media/icons/duotune/art/OficeActive.svg'
                                            : '/media/icons/duotune/art/OfficeIcon.svg'
                                    }
                                    title='Management'
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>

                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.standard.reports}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.standard.reports}
                                    followActiveRouteInnerLogic={false}
                                    isActiveRoute={pathname?.includes('reports')}
                                    icon={
                                        pathname.includes('/standard/reports')
                                            ? '/media/icons/duotune/art/ReportsActive.svg'
                                            : '/media/icons/duotune/art/ReportsIcon.svg'
                                    }
                                    title='Reports'
                                    fontIcon=''
                                />
                            </AccessRights>

                            {/* <SidebarMenuItem
            to='/builder'
            icon={
              pathname.includes('/builder')
                ? '/media/icons/duotune/art/TrainingActive.svg'
                : '/media/icons/duotune/art/TrainingIcon.svg'
            }
            title='Training'
            fontIcon=''
          /> */}
                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.standard.requestAndApprovals}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.standard.requestAndApprovals}
                                    icon={
                                        pathname.includes('/requests/')
                                            ? '/media/icons/duotune/art/Request&AprovalActive.svg'
                                            : '/media/icons/duotune/art/Request&Aproval.svg'
                                    }
                                    title='Requests & Approvals'
                                    fontIcon='Training'
                                />
                            </AccessRights>
                        </Show.When>
                    </Show>

                    <AccessRights ignorePosition customCondition={sideBarAccess.standard.support}>
                        <SidebarMenuItem
                            to='/support/dashboard'
                            icon={
                                pathname.includes('/support')
                                    ? '/media/icons/duotune/art/SupportActive.svg'
                                    : '/media/icons/duotune/art/SupportIcon.svg'
                            }
                            title='Support'
                            fontIcon='bi-app-indicator'
                        />
                    </AccessRights>
                </>
            ) : (
                <>
                    <AccessRights
                        ignorePosition
                        customCondition={sideBarAccess.administrator.dashboard}
                    >
                        <SidebarMenuItem
                            followActiveRouteInnerLogic={false}
                            isActiveRoute={pathname?.includes('admin-dashboard')}
                            to={redirectRoute.administrator.dashboard}
                            icon={
                                pathname.includes('admin-dashboard')
                                    ? '/media/icons/duotune/art/dashboard1.svg'
                                    : '/media/icons/duotune/art/shape.svg'
                            }
                            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                            fontIcon='bi-app-indicator'
                        />
                    </AccessRights>

                    <Show>
                        <Show.When isVisible={!isBillingOverdue}>
                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.administrator.setting}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.administrator.settings}
                                    icon={
                                        pathname.includes('/setting/')
                                            ? '/media/icons/duotune/art/setting1.svg'
                                            : '/media/icons/duotune/art/setting.svg'
                                    }
                                    title='Settings'
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>
                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.administrator.integrations}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.administrator.integrations}
                                    icon={
                                        pathname.includes('integration')
                                            ? '/media/icons/duotune/art/IntregationsActive.svg'
                                            : '/media/icons/duotune/art/integration.svg'
                                    }
                                    title='Integrations'
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>

                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.administrator.sequiDocs}
                            >
                                <SidebarMenuItem
                                    followActiveRouteInnerLogic={false}
                                    isActiveRoute={pathname?.includes('sequidocs')}
                                    to={redirectRoute.administrator.sequiDocs}
                                    icon={
                                        pathname.includes('sequidocs')
                                            ? '/media/icons/duotune/art/doc1.svg'
                                            : '/media/icons/duotune/art/doc.svg'
                                    }
                                    title={
                                        <KTSVG
                                            path='/media/icons/duotune/art/SequiDocLogo.svg'
                                            svgStyle={{
                                                height: '25px',
                                                width: '100px',
                                                marginLeft: '-8px',
                                            }}
                                        />
                                    }
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>

                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.administrator.payroll}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.administrator.payroll}
                                    icon={
                                        pathname.includes('payroll/')
                                            ? '/media/icons/duotune/art/payroll1.svg'
                                            : '/media/icons/duotune/art/payroll.svg'
                                    }
                                    // icon='/media/icons/duotune/art/payroll.svg'
                                    title='Payroll        '
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>
                            {saleCRMActive ? (
                                <AccessRights
                                    ignorePosition
                                    customCondition={sideBarAccess.administrator.sequiCRM}
                                >
                                    <SidebarMenuItem
                                        to={redirectRoute.administrator.sequiCRM}
                                        icon={
                                            pathname.includes('sequicrm')
                                                ? '/media/icons/duotune/art/CRMLogo.svg'
                                                : '/media/icons/duotune/art/CRMLogo1.svg'
                                        }
                                        title={
                                            <KTSVG
                                                path='/media/icons/duotune/art/SequiCRM.svg'
                                                svgStyle={{
                                                    height: '25px',
                                                    width: '80px',
                                                }}
                                            />
                                        }
                                        fontIcon='bi-app-indicator'
                                    />
                                </AccessRights>
                            ) : null}
                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.administrator.sClearance}
                            >
                                {checkSclearnceActive ? (
                                    <SidebarMenuItem
                                        to={redirectRoute.administrator.sClearance}
                                        icon={
                                            pathname.includes('s-clearance')
                                                ? '/media/icons/duotune/art/reports1.svg'
                                                : '/media/icons/duotune/art/reports.svg'
                                        }
                                        title='S-Clearance'
                                        fontIcon='bi-app-indicator'
                                    />
                                ) : null}
                            </AccessRights>

                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.administrator.reports}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.administrator.reports}
                                    followActiveRouteInnerLogic={false}
                                    isActiveRoute={pathname?.includes('reports')}
                                    icon={
                                        pathname.includes('reports')
                                            ? '/media/icons/duotune/art/reports1.svg'
                                            : '/media/icons/duotune/art/reports.svg'
                                    }
                                    // icon='/media/icons/duotune/art/reports.svg'
                                    title='Reports        '
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>

                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.administrator.permissions}
                            >
                                <SidebarMenuItem
                                    to={redirectRoute.administrator.permissions}
                                    icon={
                                        pathname.includes('permissions')
                                            ? '/media/icons/duotune/art/PermissionsActive.svg'
                                            : '/media/icons/duotune/art/PermissionsInactive.svg'
                                    }
                                    title='Permissions'
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>

                            {/* <SidebarMenuItem
            to='/marketing-deal/md-list'
            icon='/media/icons/duotune/art/marketting.svg'
            title='Marketting Deals'
            fontIcon='bi-app-indicator'
          /> */}

                            {/* <SidebarMenuItem
            to='/import-export     '
            icon='/media/icons/duotune/art/import.svg'
            title='Import / Export'
            fontIcon='bi-app-indicator'
          /> */}

                            <AccessRights
                                ignorePosition
                                customCondition={sideBarAccess.administrator.alertCenter}
                            >
                                <SidebarMenuItem
                                    badgeCount={alertsCount}
                                    to={redirectRoute.administrator.alertCenter}
                                    icon={
                                        pathname.includes('alert-center')
                                            ? '/media/icons/duotune/art/alert-triangle-active.svg'
                                            : '/media/icons/duotune/art/alert-triangle-inActive.svg'
                                    }
                                    onClick={() => {
                                        // sendDataToReducer(
                                        //     dispatch,
                                        //     SET_ALERT_FILTER_DATA,
                                        //     FILTER_INITIAL_DATA.alert
                                        // )
                                    }}
                                    title='Alert Center'
                                    fontIcon='bi-app-indicator'
                                />
                            </AccessRights>
                        </Show.When>
                    </Show>

                    <AccessRights
                        ignorePosition
                        customCondition={sideBarAccess.administrator.support}
                    >
                        <SidebarMenuItem
                            to='/support/dashboard'
                            icon={
                                pathname.includes('/support')
                                    ? '/media/icons/duotune/art/SupportActive.svg'
                                    : '/media/icons/duotune/art/SupportIcon.svg'
                            }
                            title='Support'
                            fontIcon='bi-app-indicator'
                        />
                    </AccessRights>
                </>
            )}
        </>
    ) : null
}

const getRedirectRoute = ({companySetting, userDetails}) => {
    return {
        administrator: {
            dashboard: allPermissionsAccess.administrator.dashboard.dashboard.viewFunc()
                ? '/admin-dashboard'
                : '',
            settings: allPermissionsAccess.administrator.setting.setup.viewFunc()
                ? '/setting/setup'
                : allPermissionsAccess.administrator.setting.locations.viewFunc()
                ? '/setting/location'
                : allPermissionsAccess.administrator.setting.costTracking.viewFunc()
                ? '/cost-center'
                : allPermissionsAccess.administrator.setting.departments.viewFunc()
                ? '/setting/department'
                : allPermissionsAccess.administrator.setting.positions.viewFunc()
                ? '/setting/position'
                : // : allPermissionsAccess.administrator.setting.alerts.viewFunc()
                  // ? '/setting/alert'
                  // : allPermissionsAccess.administrator.setting.bankAccounts.viewFunc()
                  // ? '/setting/bank-account'
                  // : allPermissionsAccess.administrator.setting.paperWork.viewFunc()
                  // ? '/setting/paperwork'
                  '',
            integrations: allPermissionsAccess.administrator.integrations.integrations.viewFunc()
                ? '/integration'
                : '',
            sequiDocs: allPermissionsAccess.administrator.sequiDocs.templates.viewFunc()
                ? '/admin/sequidocs/templates'
                : allPermissionsAccess.administrator.sequiDocs.documents.viewFunc()
                ? '/admin/sequidocs/documents'
                : '',
            payroll: allPermissionsAccess.administrator.payroll.runPayrollAndApprovals.viewFunc()
                ? '/payroll/run-payroll'
                : allPermissionsAccess.administrator.payroll.oneTimePayment.viewFunc()
                ? 'payroll/one-time-payment'
                : allPermissionsAccess.administrator.payroll.paymentRequest.viewFunc()
                ? '/payroll/payment-request'
                : companySetting?.reconciliation &&
                  allPermissionsAccess.administrator.payroll.payrollReconciliation.viewFunc()
                ? '/payroll/reconciliation'
                : allPermissionsAccess.administrator.payroll.runPayrollAndApprovals.viewFunc()
                ? '/payroll/run-payroll'
                : '',
            sClearance: allPermissionsAccess.administrator.sClearance.sClearance.viewFunc()
                ? 's-clearance/list'
                : null,
            sequiCRM: allPermissionsAccess.administrator.sequiCRM.sequiCRM.viewFunc()
                ? '/sequicrm'
                : '',
            reports: allPermissionsAccess.administrator.reports.company.viewFunc()
                ? '/admin/reports/company'
                : allPermissionsAccess.administrator.reports.sales.viewFunc()
                ? '/admin/reports/sales'
                : allPermissionsAccess.administrator.reports.cost.viewFunc()
                ? '/admin/reports/costs'
                : allPermissionsAccess.administrator.reports.payroll.viewFunc()
                ? '/admin/reports/payroll'
                : companySetting?.reconciliation &&
                  allPermissionsAccess.administrator.reports.reconciliation.viewFunc()
                ? '/admin/reports/reconciliation'
                : allPermissionsAccess.administrator.reports.clawback.viewFunc()
                ? '/admin/reports/clawback'
                : allPermissionsAccess.administrator.reports.pendingInstall.viewFunc()
                ? '/admin/reports/pending-installs'
                : '',
            permissions: allPermissionsAccess.administrator.permissions.group.viewFunc()
                ? '/permissions/groups'
                : allPermissionsAccess.administrator.permissions.policies.viewFunc()
                ? '/permissions/policies'
                : '',
            alertCenter: allPermissionsAccess.administrator.alertCenter.alerts.viewFunc()
                ? '/alert-center/alerts'
                : '',
        },
        standard: {
            dashboard: allPermissionsAccess.standard.dashboard.dashboard.viewFunc()
                ? '/standard-dashboard'
                : '',
            mySales: allPermissionsAccess.standard.mySales.mysales.viewFunc()
                ? '/mysales/sales'
                : allPermissionsAccess.standard.mySales.myOverrides.viewFunc()
                ? '/mysales/my-overrides'
                : allPermissionsAccess.standard.mySales.myWages.viewFunc() &&
                  getBooleanValue(userDetails?.wages_status) == 1
                ? '/mysales/my-wages'
                : // : allPermissionsAccess.standard.mySales.payStubs.viewFunc()
                // ? '/mysales/pay-stubs'
                allPermissionsAccess.standard.mySales.payStubs.viewFunc() &&
                  getBooleanValue(userDetails?.stop_payroll) == 0 //PayStubs permission is equal to pending pay permission
                ? '/mysales/pending-pay'
                : '',
            // arena: allPermissionsAccess.standard.arena.arena.viewFunc() ? '/arena' : '',
            arena: true ? '/arena' : '',
            calendar: '/calendar',
            sequiDocs: allPermissionsAccess.standard.sequidocs.templates.viewFunc()
                ? '/standard/sequidocs/templates'
                : allPermissionsAccess.standard.sequidocs.documents.viewFunc()
                ? '/standard/sequidocs/documents'
                : '',
            hiring: allPermissionsAccess.standard.hiring.hiringProgress.viewFunc()
                ? '/hiring/hiring-process'
                : allPermissionsAccess.standard.hiring.leads.viewFunc()
                ? '/hiring/hiring-leads'
                : allPermissionsAccess.standard.hiring.onboardingEmployees.viewFunc()
                ? '/hiring/hiring-onBoardingEmployees'
                : '',
            management: allPermissionsAccess.standard.management.employee.viewFunc()
                ? '/management/employee'
                : allPermissionsAccess.standard.management.team.viewFunc()
                ? '/management/teams'
                : '',
            reports: allPermissionsAccess.standard.reports.office.viewFunc()
                ? '/standard/reports/office'
                : allPermissionsAccess.standard.reports.sales.viewFunc()
                ? '/standard/reports/sales'
                : allPermissionsAccess.standard.reports.pastPayStubs.viewFunc()
                ? '/standard/reports/pay-stubs'
                : companySetting?.reconciliation &&
                  allPermissionsAccess.standard.reports.reconciliation.viewFunc()
                ? '/standard/reports/reconciliation'
                : '',
            requestAndApprovals:
                allPermissionsAccess.standard.requestAndApprovels.myRequests.viewFunc()
                    ? '/requests/request'
                    : allPermissionsAccess.standard.requestAndApprovels.approvals.viewFunc()
                    ? '/requests/approvals'
                    : '',
        },
    }
}

export {SidebarMenuMain, getRedirectRoute}
