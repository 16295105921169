import {useLocation, useNavigate} from 'react-router-dom'
import useSequifiConfig from '../../../../sequiConfig/useSequifiConfig'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import {KTSVG} from '../../../../_metronic/helpers'
import {
    formattedNumberFields,
    sendDataToReducer,
    TABLE_BORDER,
} from '../../../../helpers/CommonHelpers'
import {CustomSortSvg} from '../../../../_metronic/helpers/components/CustomSortSVG'
import {
    getValidDate,
    MULTITENANT_CLIENT_DIRECTORY_DROPDOWN_LIST,
} from '../../../../constants/constants'
import CustomButton2, {
    BUTTON_SIZE_STYLE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton2'
import {CommonLabel} from '../../../../customComponents/customInputs/customInput/CustomInput'
import CustomToggleBtn from '../../../../customComponents/customToogleBtn/CustomToggleBtn'
import {useCallback, useEffect, useState} from 'react'
import CompanyM2Page from './CompanyM2Page'
import Pagination from '../../admin/sequidocs/component/Pagination'
import CustomTableSkeleton from '../../../../customComponents/customSkeleton/CustomTableSkeleton'
import CustomDropdown from '../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {getTenantUniquePidsService} from '../../../../services/Services'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {getMultitenantFilterDataSelector} from '../../../../redux/selectors/FilterSelectors'
import {SET_MULTITENANT_FILTER_DATA} from '../../../../redux/reducers/FilterReducer'
import Show from '../../../../customComponents/show/Show'
import CustomCardSkeleton from '../../../../customComponents/customSkeleton/CustomCardSkeleton'

const CompanyUniqueIdPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const filterData = useSelector(getMultitenantFilterDataSelector)

    const {basedOnCompanyType} = useSequifiConfig()
    const [filter, setFilter] = useState(filterData?.filter)

    const [page, setPage] = useState(filterData?.page)
    const [limit, setLimit] = useState(filterData?.limit)
    const [pageM2Completed, setPageM2Completed] = useState(filterData?.page)
    const [limitM2Completed, setLimitM2Completed] = useState(filterData?.limit)
    const [uniqueIdData, setUniqueIdData] = useState([])
    const [switchIsChecked, setSwitchIsChecked] = useState(false)
    const [loading, setLoading] = useState(false)

    const onChangeFilter = (data) => {
        setFilter(data)
    }

    const onChangeSwitch = useCallback((e) => {
        setSwitchIsChecked((prevState) => !prevState)
    }, [])

    const getUniquiIds = () => {
        const body = {
            tenant_id: location?.state?.tid,
            filter: filter,
            page: page,
            perpage: Number(limit),
        }
        setLoading(true)
        getTenantUniquePidsService(body)
            .then((res) => {
                setUniqueIdData(res)
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getUniquiIds()
        sendDataToReducer(dispatch, SET_MULTITENANT_FILTER_DATA, {
            ...filterData,
            filter,
            page: page,
            limit: limit,
        })
    }, [filter, page, limit])
    return (
        <div className='container gap-5 mx-auto'>
            {/* <CustomLoader full visible={loading} /> */}

            <div className='d-flex align-items-center mb-5 flex-wrap gap-3'>
                <div onClick={() => navigate(-1)} className='order-1'>
                    <KTSVG
                        path='/media/icons/duotune/art/back-square.svg'
                        className='cursor-pointer'
                        svgClassName='w-25px h-25px'
                    />
                </div>

                <div className='text-sqGray900 text-xl-bold flex-grow-1 order-2 text-center text-md-start'>
                    {!switchIsChecked ? 'Flex Power | Unique PID’s' : 'Flex Power | M2 Completed'}
                </div>

                <div className='d-flex justify-content-center justify-content-md-end align-items-center order-3 w-100 w-md-auto mt-3 mt-md-0'>
                    <div className='me-3'>
                        <CommonLabel label='Sold' />
                    </div>
                    <CustomToggleBtn
                        checked={switchIsChecked}
                        onChange={onChangeSwitch}
                        // id={'My_client_Table_Toggle'}
                    />
                    <div className='ms-3'>
                        <CommonLabel label='Completed' />
                    </div>
                </div>

                <div className='order-4 mt-3 mt-md-0'>
                    <CustomDropdown
                        id={'One_time_Payment_Time_Frame_Dropdown'}
                        value={filter}
                        showClear={false}
                        searching={false}
                        options={MULTITENANT_CLIENT_DIRECTORY_DROPDOWN_LIST}
                        valueKey='value'
                        displayKey='name'
                        onChange={(e) => onChangeFilter(e.target.value)}
                    />
                </div>
            </div>

            {!switchIsChecked ? (
                <>
                    <Show>
                        <Show.When isVisible={loading}>
                            <CustomCardSkeleton col='col-sm-2' />
                        </Show.When>
                        <Show.When isVisible={!loading}>
                            <div className='container mb-6'>
                                <div className='row gap-6'>
                                    {/* Card 1 */}

                                    <div
                                        className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200 p-2'
                                        style={{borderRadius: 10, height: '96px'}}
                                    >
                                        <div className='card-body'>
                                            <div className='card-title text-sqGray900 text-xl-bold'>
                                                {uniqueIdData?.unique_pid_rack_price
                                                    ? uniqueIdData?.unique_pid_rack_price
                                                    : 0}
                                            </div>
                                            <div className='card-text text-sqGray800 text-sm-semibold'>
                                                Unique PIDs
                                            </div>
                                        </div>
                                    </div>

                                    {/* Card 2 */}
                                    <div
                                        className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200 p-2'
                                        style={{borderRadius: 10}}
                                    >
                                        <div className='card-body'>
                                            <div className='card-title text-sqGray900  text-xl-bold'>
                                                {/* {0} */}
                                                {uniqueIdData?.kw_total
                                                    ? uniqueIdData?.kw_total
                                                    : 0}
                                            </div>
                                            <div className='card-text text-sqGray800  text-sm-semibold'>
                                                Total KW
                                            </div>
                                        </div>
                                    </div>
                                    {/* Card 3 */}
                                    <div
                                        className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200 p-2'
                                        style={{borderRadius: 10}}
                                    >
                                        <div className='card-body'>
                                            <div className='card-title text-sqGray900  text-xl-bold'>
                                                {formattedNumberFields(
                                                    uniqueIdData?.total_price,
                                                    '$'
                                                )}
                                            </div>
                                            <div className='card-text text-sqGray800 text-sm-semibold'>
                                                Total Price
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Show.When>
                    </Show>
                    <div
                        className='bg-cmwhite mt-6'
                        style={{borderRadius: 10, verticalAlign: 'top'}}
                    >
                        <div className='table-responsive' style={{borderRadius: 10}}>
                            <table className='table'>
                                <thead className={TABLE_BORDER}>
                                    <tr className='text-sqGray800 bg-sqGray200 text-sm-semibold text-nowrap'>
                                        <th className='p-5 text-nowrap'>PID</th>
                                        <th className='p-5 text-nowrap'>Customer</th>
                                        <th className='p-5 text-nowrap'>State</th>
                                        <th className='p-5 text-nowrap'>
                                            <div className='d-flex align-items-center'>
                                                KW
                                                {/* {basedOnCompanyType?.primaryUnitType} */}
                                                <CustomSortSvg
                                                    id={'comapny_type_Sort_Icon'}
                                                    // sortingOrder={
                                                    //     sortValue === 'requested_on' ? sortingOrder : null
                                                    // }
                                                    // onClick={() => onPress('requested_on')}
                                                />
                                            </div>
                                        </th>
                                        <th className='p-5 text-nowrap'>Approval Date</th>
                                        <th className='p-5 text-nowrap'>Price</th>
                                        <th className='p-5 text-nowrap'>
                                            <div className='d-flex align-items-center'>
                                                Price Billed
                                                <CustomSortSvg
                                                    id={'comapny_billing_Sort_Icon'}
                                                    // sortingOrder={
                                                    //     sortValue === 'requested_on' ? sortingOrder : null
                                                    // }
                                                    // onClick={() => onPress('requested_on')}
                                                />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={TABLE_BORDER}>
                                    {loading ? (
                                        <CustomTableSkeleton coloums={7} />
                                    ) : uniqueIdData?.data?.data?.length > 0 ? (
                                        <>
                                            {/* <tr className={TABLE_BORDER}> */}
                                            {uniqueIdData?.data?.data?.map((item, index) => (
                                                <tr key={0} className='text-sqGray800 stripRow'>
                                                    <td className='p-5 text-nowrap'>
                                                        <div className='d-flex gap-4 text-xs-medium'>
                                                            {item?.pid ?? '-'}
                                                            <div className='badge bg-sqOrange700 text-sqOrange700 bg-opacity-10 px-5 rounded-pill text-xs-bold'>
                                                                Delayed
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='p-5 text-nowrap text-xs-medium'>
                                                        {item?.customer_name}
                                                    </td>
                                                    <td className='p-5 text-nowrap text-xs-medium'>
                                                        {item?.customer_state}
                                                    </td>
                                                    <td className='p-5 text-nowrap text-xs-medium'>
                                                        {item?.kw ?? '-'}
                                                    </td>

                                                    <td className='p-5 text-nowrap text-xs-medium'>
                                                        {getValidDate(item?.approval_date)}
                                                    </td>
                                                    <td className='p-5 text-nowrap text-xs-medium'>
                                                        $ {item?.price}/
                                                        {basedOnCompanyType?.secondaryUnitType}
                                                    </td>
                                                    <td className='p-5 text-nowrap text-xs-medium'>
                                                        {formattedNumberFields(
                                                            item?.billed_price,
                                                            '$'
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={12}
                                                style={{
                                                    textAlign: 'center',
                                                    fontFamily: 'Manrope',
                                                    fontWeight: '500',
                                                    fontSize: 14,
                                                    paddingTop: 20,
                                                    paddingBottom: 20,
                                                }}
                                            >
                                                No data found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='pb-5 pe-5'>
                            {uniqueIdData?.data?.data?.length > 0 && (
                                <Pagination
                                    tableName={'Company_Unique_id'}
                                    page={page}
                                    totalPages={uniqueIdData?.data?.last_page}
                                    limit={limit}
                                    setLimit={setLimit}
                                    setPage={(changedPage) => setPage(changedPage)}
                                />
                            )}
                        </div>
                    </div>

                    {/* <div className='d-flex justify-content-center align-items-center mt-6'>
                        <CustomButton2
                            buttonLabel='Load More'
                            buttonSize={BUTTON_SIZE_STYLE.lg}
                            buttonType={BUTTON_TYPE.primary}
                            onClick={() => navigate('/new-user')}
                        />
                    </div> */}
                </>
            ) : (
                <>
                    <CompanyM2Page
                        filter={filter}
                        pageM2Completed={pageM2Completed}
                        limitM2Completed={limitM2Completed}
                        setLimit={setLimitM2Completed}
                        setPage={setPageM2Completed}
                        filterData={filterData}
                    />
                </>
            )}
        </div>
    )
}

export default CompanyUniqueIdPage
