import useBasedOnHost from './useBasedOnHost'
import {useCallback, useMemo} from 'react'
import {HOST_SERVERS} from '../constants/SERVERS'
import {useSelector} from 'react-redux'
import {getCompanyTypeSelector} from '../redux/selectors/SettingsSelectors'

// Comapany Type
export const COMPANY_TYPE = {
    solar: 'Solar',
    pest: 'Pest',
    turf: 'Turf',
    roofing: 'Roofing',
    realEstate: 'Real Estate',
}

const useBasedOnCompanyType = () => {
    const systemCompanyType = useSelector(getCompanyTypeSelector)

    const companyType = useMemo(() => {
        return systemCompanyType
    }, [systemCompanyType])

    const isCompanyType = useCallback(
        (types = []) => {
            return types?.includes(companyType)
        },
        [companyType]
    )

    const primaryUnitType = useMemo(() => {
        let type = 'KW'
        if (isCompanyType([COMPANY_TYPE.turf])) type = 'Sq ft'
        return type
    }, [isCompanyType])

    const secondaryUnitType = useMemo(() => {
        let type = 'Watt'
        if (isCompanyType([COMPANY_TYPE.turf])) type = 'Sq ft'
        return type
    }, [isCompanyType])

    const basedOnCompanyType = useMemo(() => {
        return {
            isActiveRedlineSystem: !isCompanyType([COMPANY_TYPE.pest]),
            isActiveCorePositionSystem: isCompanyType([COMPANY_TYPE.solar, COMPANY_TYPE.turf]),
            isActiveSelfGenSystem: !isCompanyType([COMPANY_TYPE.pest]),
            showCompanyProfileBusinessEIN: !isCompanyType([COMPANY_TYPE.pest]),
            showCompanyProfileFavicon: isCompanyType([COMPANY_TYPE.pest]),
            positionSettlementWithM2Verbiage: !isCompanyType([COMPANY_TYPE.pest])
                ? 'With M2'
                : 'Initial Service',
            settingPositionFilterForPest: isCompanyType([COMPANY_TYPE.pest]),
            installationPartnerVerbiage: !isCompanyType([COMPANY_TYPE.pest])
                ? 'Installation Partner'
                : 'Service Provider',
            epcText: isCompanyType([COMPANY_TYPE.turf]) ? 'Gross $ / Sq ft' : 'EPC',
            netEpcText: isCompanyType([COMPANY_TYPE.turf]) ? 'Net $ / Sq ft' : 'Net EPC',
            primaryUnitType,
            secondaryUnitType,
            isCompanyTypePest: isCompanyType([COMPANY_TYPE.pest]),
            isCompanyTypeTurf: isCompanyType([COMPANY_TYPE.turf]),
            isCompanyTypeSolar: isCompanyType([COMPANY_TYPE.solar]),
            isCompanyTypeSolar2: isCompanyType([COMPANY_TYPE.solar2]),
            isUsingPrimaryUnitType: !isCompanyType([COMPANY_TYPE.pest]),
        }
    }, [isCompanyType, primaryUnitType, secondaryUnitType])

    return {
        basedOnCompanyType,
        companyType,
        isCompanyType,
    }
}

export default useBasedOnCompanyType
