import React, {useCallback, useEffect} from 'react'
import CustomInput, {
    CommonLabel,
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomCheckbox from '../../../../../../customComponents/customCheckbox/CustomCheckbox'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {ARENA_EVENT_KEYS} from './AddArenaEvent'
import _ from 'lodash'
import {ARENA_EVENT_TYPES, ARENA_REWARD_TYPES} from '../../../../../../constants/constants'

const SetRewards = ({eventData, updateEventData, arenaEventErrorData, updateMultipleEventData}) => {
    useEffect(() => {
        addReward()
    }, [])

    // Function to handle adding a new milestone
    const addReward = useCallback(() => {
        let data = _.cloneDeep(eventData?.[ARENA_EVENT_KEYS.rewards_data] ?? [])
        let obj = {reward_type: 'Cash', reward: null, hide_reward: false}
        if (eventData?.[ARENA_EVENT_KEYS.event_type] == ARENA_EVENT_TYPES.ladder) {
            obj.points_required = ''
        }
        data.push(obj)
        updateMultipleEventData({
            [ARENA_EVENT_KEYS.rewards_data]: data,
        })
    }, [eventData, updateMultipleEventData])

    const updateRewardData = (index, e) => {
        debugger
        let data = _.cloneDeep(eventData?.[ARENA_EVENT_KEYS.rewards_data])
        data[index][e?.target?.name] = e?.target?.value
        if (e?.target?.name == ARENA_EVENT_KEYS.reward_type) {
            data[index][ARENA_EVENT_KEYS.reward] = ''
        }
        updateMultipleEventData({
            [ARENA_EVENT_KEYS.rewards_data]: data,
        })
    }

    return (
        <div className='px-32px'>
            <div className=''>
                <div className='text-md-semibold text-sqGray900 mb-32px'>Set No. of Milestones</div>
                <div className=''>
                    {eventData?.[ARENA_EVENT_KEYS.rewards_data]?.map((reward, index) => (
                        <div key={reward?.id} className='mb-32px'>
                            <div className='text-sm-bold text-sqGray900 mb-18px'>
                                Milestone {index + 1}
                            </div>
                            <div className='row mb-18px gap-2'>
                                <div className='col-sm'>
                                    <PointsRequiredInput
                                        reward={reward}
                                        index={index}
                                        updateRewardData={updateRewardData}
                                        arenaEventErrorData={arenaEventErrorData}
                                    />
                                </div>
                                <div className='col-sm'>
                                    <RewardTypeInput
                                        reward={reward}
                                        index={index}
                                        updateRewardData={updateRewardData}
                                        arenaEventErrorData={arenaEventErrorData}
                                    />
                                </div>
                            </div>
                            <div className='mb-18px'>
                                <RewardInput
                                    reward={reward}
                                    index={index}
                                    updateRewardData={updateRewardData}
                                    arenaEventErrorData={arenaEventErrorData}
                                />
                            </div>
                            <div className='d-flex gap-2'>
                                <HideRewardCheckbox
                                    reward={reward}
                                    index={index}
                                    updateRewardData={updateRewardData}
                                    arenaEventErrorData={arenaEventErrorData}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='d-flex gap-2 cursor-pointer' onClick={addReward}>
                    <div>
                        <KTSVG
                            path='/media/icons/duotune/arena/plus-square.svg'
                            className=' mb-2'
                            svgClassName='w-20px h-20px'
                        />
                    </div>
                    <div>
                        <span className='text-sm-semibold text-sqPrimary600'>
                            Add another Milestone
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetRewards

const PointsRequiredInput = ({index, reward, arenaEventErrorData, updateRewardData}) => (
    <CustomInput
        required
        label={'Points Required '}
        placeholder='0'
        type={INPUT_TYPE.number}
        restrictNegativeNum
        name={ARENA_EVENT_KEYS.points_required}
        value={reward?.[ARENA_EVENT_KEYS.points_required]}
        onChange={(e) => updateRewardData(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.rewards_data]?.[index]?.[
                ARENA_EVENT_KEYS.points_required
            ]
        }
    />
)

const RewardTypeInput = ({index, reward, arenaEventErrorData, updateRewardData}) => (
    <CustomDropdown
        required
        label={'Select Reward Type'}
        options={ARENA_REWARD_TYPES}
        showClear={false}
        name={ARENA_EVENT_KEYS.reward_type}
        value={reward?.[ARENA_EVENT_KEYS.reward_type]}
        onChange={(e) => updateRewardData(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.rewards_data]?.[index]?.[
                ARENA_EVENT_KEYS.reward_type
            ]
        }
    />
)

const RewardInput = ({index, reward, arenaEventErrorData, updateRewardData}) => (
    <CustomInput
        required
        label={'Reward'}
        placeholder={reward?.[ARENA_EVENT_KEYS.reward_type] == 'Cash' ? '0' : 'iPhone'}
        prefixText={reward?.[ARENA_EVENT_KEYS.reward_type] == 'Cash' ? '$' : ''}
        type={
            reward?.[ARENA_EVENT_KEYS.reward_type] == 'Cash' ? INPUT_TYPE.number : INPUT_TYPE.text
        }
        restrictNegativeNum
        name={ARENA_EVENT_KEYS.reward}
        value={reward?.[ARENA_EVENT_KEYS.reward]}
        onChange={(e) => updateRewardData(index, e)}
        errorMessage={
            arenaEventErrorData?.[ARENA_EVENT_KEYS.rewards_data]?.[index]?.[ARENA_EVENT_KEYS.reward]
        }
    />
)

const HideRewardCheckbox = ({index, reward, arenaEventErrorData, updateRewardData}) => (
    <>
        <div>
            <CustomCheckbox
                name={ARENA_EVENT_KEYS.hide_reward}
                checked={reward?.[ARENA_EVENT_KEYS.hide_reward] ? true : false}
                onChange={(e) => {
                    updateRewardData(index, {
                        ...e,
                        target: {
                            ...e?.target,
                            value: !reward?.[ARENA_EVENT_KEYS.hide_reward],
                        },
                    })
                }}
            />
        </div>
        <div>
            <CommonLabel className='' label='Hide rewards for players' />
        </div>
    </>
)
