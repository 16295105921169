import React from 'react'
import {formattedNumberFields} from '../../../../../helpers/CommonHelpers'
import {KTSVG} from '../../../../../_metronic/helpers'
import CustomCardSkeleton from '../../../../../customComponents/customSkeleton/CustomCardSkeleton'
import Show from '../../../../../customComponents/show/Show'
// mx-lg-10 mx-md-10 me-lg-10 me-sm-0 mx-sm-0 me-md-0 mt-lg-0 mt-md-0 mt-sm-10 mt-10
const TotalSalesCard = ({className, style, totalSalesData, loading}) => {
    return (
        <Show>
            <Show.When isVisible={loading}>
                <CustomCardSkeleton
                    count={1}
                    cardWidth='w-100'
                    containerWidth='col-12 col-md col-lg-4 ps-0 pe-0 mx-auto'
                    col='px-0'
                    height='220px'
                />
            </Show.When>
            <Show.When isVisible={!loading}>
                <>
                    {/* Heading */}
                    <div
                        className='bg-cmwhite shadow-sm col-12 col-md col-lg-4 px-3 px-md-4 px-lg-5 py-3  py-md-4 py-lg-5'
                        style={{borderRadius: 10}}
                    >
                        <div className='mb-5'>
                            <div className='text-sqGray900 text-dsm-bold'>
                                {formattedNumberFields(totalSalesData?.total_earning)}
                            </div>
                            <div className='text-sqGray500 text-md-medium'>Total Earnings</div>
                        </div>
                        <div>
                            {/* line 1 */}
                            <div className='d-flex gap-xl-20 gap-10 mb-4'>
                                <div className='col text-sqGray500 text-md-medium'>
                                    Avg. Client Billing
                                </div>
                                <div className='text-md-bold text-cmGrey900'>
                                    {formattedNumberFields(totalSalesData?.avg_client_billing)}
                                    <KTSVG
                                        path='/media/icons/duotune/art/UpRight.svg'
                                        svgClassName='w-20px h-20px cursor-pointer '
                                        className='ms-1'
                                    />
                                </div>
                            </div>
                            {/* line 2 */}
                            <div className='d-flex gap-xl-20 gap-10 mb-4'>
                                <div className='col text-sqGray500 text-md-medium'>
                                    Lowest Client Check
                                </div>
                                <div className='text-md-bold text-cmGrey900'>
                                    {formattedNumberFields(totalSalesData?.lowest_client_billing)}
                                    <KTSVG
                                        path='/media/icons/duotune/art/DownLeft.svg'
                                        svgClassName='w-20px h-20px cursor-pointer'
                                        className='ms-1'
                                    />
                                </div>
                            </div>

                            {/* line 3 */}
                            <div className='d-flex gap-xl-20 mb-4'>
                                <div className='col text-sqGray500 text-md-medium'>
                                    Avg no. of Users
                                </div>
                                <div className='text-md-bold text-cmGrey900'>
                                    {formattedNumberFields(
                                        totalSalesData?.avg_no_of_users_per_client,
                                        ''
                                    )}
                                    <KTSVG
                                        path='/media/icons/duotune/art/UpRight.svg'
                                        svgClassName='w-20px 2-30px cursor-pointer '
                                        className='ms-1'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Show.When>
        </Show>
    )
}

export default TotalSalesCard
