import {useEffect, useRef, useState} from 'react'
import {isMobile} from 'react-device-detect'

const CustomStepper = ({
    activeStep,
    steps,
    textColor = null,
    onTabClick = () => {},
    showCursor = true,
    showStepsOuter = true,
}) => {
    const [activeTab, setActiveTab] = useState(activeStep)
    const tabRef = useRef(null)

    useEffect(() => {
        setActiveTab(activeStep)
    }, [activeStep])

    const handleTabClick = (tabId) => {
        onTabClick(tabId)
    }
    return (
        <div
            className={`tabs ${isMobile ? '' : 'row'} mx-auto w-100`}
            ref={tabRef}
            style={{
                overflow: 'visible',
                overflowX: 'unset',
                bottom: -5,
            }}
        >
            {steps.map((tab, index) => {
                const active = activeTab === index
                return (
                    <div
                        key={index}
                        id={`tab-${index}`}
                        className={`col-sm ${
                            index < activeTab ? 'completed' : 'text-sqGray700'
                        } text-nowrap tab ${
                            active ? 'active text-white  text-md-bold' : 'text-md-semibold'
                        } ${showCursor ? 'cursor-pointer' : ''}`}
                        style={{
                            // marginLeft: showStepsOuter && active ? -6 : 0,
                            // marginRight: showStepsOuter && active ? -6 : 0,
                            zIndex: active ? 1 : 0,
                        }}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.label}
                    </div>
                )
            })}
        </div>
    )
}

export default CustomStepper
