import {Route, Routes, Navigate} from 'react-router-dom'

import MultiTenantDashboard from '../app/modules/multiTenant/MultiTenantDashboard'
import {MultitenantLayout} from '../_metronic/layout/MultitenantLayout'
import AddNewClient from '../app/modules/multiTenant/addNewClient/AddNewClient'
import ClientDirectoryPage from '../app/modules/multiTenant/clientDirectory/ClientDirectoryPage'
import CompanyOverviewPage from '../app/modules/multiTenant/companyOverview/CompanyOverviewPage'
import CompanyUniqueIdPage from '../app/modules/multiTenant/companyOverview/CompanyUniqueIdPage'
import PaycheckPage from '../app/modules/multiTenant/companyOverview/PaycheckPage'
import BackgroundCheckPage from '../app/modules/multiTenant/companyOverview/BackgroundCheckPage'

const MultitenantPrivateRoutes = ({directPath, setDirectPath = () => {}}) => {
    const profileBreadCrumbs = [
        {
            title: 'Dashboard/',
            path: '/',
            isSeparator: false,
            isActive: false,
        },
    ]

    return (
        <Routes>
            <Route element={<MultitenantLayout />}>
                <Route path='login/*' element={<Navigate to={'/multitenant-dashboard'} />} />
                <Route
                    path='multitenant-dashboard/*'
                    element={
                        <MultiTenantDashboard
                            directPath={directPath}
                            setDirectPath={setDirectPath}
                        />
                    }
                />
                <Route path='new-user' element={<AddNewClient />} />
                <Route path='client-directory' element={<ClientDirectoryPage />} />
                <Route path='client-overview' element={<CompanyOverviewPage />} />
                <Route path='client-sales' element={<CompanyUniqueIdPage />} />
                <Route path='client-paycheck' element={<PaycheckPage />} />
                <Route path='client-background-check' element={<BackgroundCheckPage />} />

                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

export {MultitenantPrivateRoutes}
