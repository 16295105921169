import CustomInput, {
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomDatePicker from '../../../../../../customComponents/customInputs/customDatePicker/CustomDatePicker'
import {ARENA_EVENT_KEYS} from './AddArenaEvent'
import {
    ARENA_COMPETITION_DURATION,
    ARENA_WEEK_DAYS,
    getValidDate,
} from '../../../../../../constants/constants'
import CustomAutoCompleteDropdown from '../../../../../../customComponents/customInputs/customAutoCompleteDropdown/CustomAutoCompleteDropdown'
import {useEffect} from 'react'
import Show from '../../../../../../customComponents/show/Show'
import {customMoment} from '../../../../../../customComponents/customMoment/customMoment'
import moment from 'moment'

const SetGameRulesLadder = ({
    eventData,
    updateEventData,
    arenaEventErrorData,
    updateMultipleEventData,
}) => {
    useEffect(() => {
        let start_date = getValidDate(
            eventData?.[ARENA_EVENT_KEYS.competition_start_date],
            'YYYY-MM-DD'
        )
        let end_date = getValidDate(
            eventData?.[ARENA_EVENT_KEYS.competition_end_date],
            'YYYY-MM-DD'
        )
        let duration = eventData?.[ARENA_EVENT_KEYS.competition_duration]
        let no_of_days =
            eventData?.[ARENA_COMPETITION_DURATION] == 'set_by_exact_date' && start_date && end_date
                ? moment(end_date).diff(start_date, 'd') + 1
                : eventData?.[ARENA_EVENT_KEYS.competition_no_of_days]

        if (duration == 'set_by_exact_date' && start_date) {
            no_of_days = moment(end_date).diff(start_date, 'd') + 1
        }

        if (duration != 'set_by_exact_date' && start_date && end_date) {
            end_date = moment(start_date)
                .add(no_of_days - 1, 'd')
                .format('YYYY-MM-DD')
        }

        updateMultipleEventData({
            [ARENA_EVENT_KEYS.competition_start_date]: start_date,
            [ARENA_EVENT_KEYS.competition_end_date]: end_date,
            [ARENA_EVENT_KEYS.competition_no_of_days]: no_of_days,
        })
    }, [
        eventData?.[ARENA_EVENT_KEYS.competition_duration],
        eventData?.[ARENA_EVENT_KEYS.competition_start_date],
        eventData?.[ARENA_EVENT_KEYS.competition_end_date],
        eventData?.[ARENA_EVENT_KEYS.competition_no_of_days],
    ])

    return (
        <>
            <div className='px-32px'>
                <div className=''>
                    {/* -------------------- Game Rules -------------------- */}
                    <>
                        <div className='text-md-semibold text-sqGray900 mb-32px'>
                            Set Game Rules
                        </div>

                        {/* -------------------- Sold As -------------------- */}
                        <div className='text-sm-bold text-sqGray900 mb-18px'>Sold As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Setter'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.sold_as_setter}
                                    value={eventData?.[ARENA_EVENT_KEYS.sold_as_setter]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.sold_as_setter]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Closer'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.sold_as_closer}
                                    value={eventData?.[ARENA_EVENT_KEYS.sold_as_closer]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.sold_as_closer]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.sold_as_self_gen}
                                    value={eventData?.[ARENA_EVENT_KEYS.sold_as_self_gen]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.sold_as_self_gen]
                                    }
                                />
                            </div>
                        </div>

                        {/* -------------------- Installed As -------------------- */}
                        <div className='text-sm-bold text-sqGray900 mb-18px'>Installed As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Setter'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.installed_as_setter}
                                    value={eventData?.[ARENA_EVENT_KEYS.installed_as_setter]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.installed_as_setter]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Closer'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.installed_as_closer}
                                    value={eventData?.[ARENA_EVENT_KEYS.installed_as_closer]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.installed_as_closer]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.installed_as_self_gen}
                                    value={eventData?.[ARENA_EVENT_KEYS.installed_as_self_gen]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[
                                            ARENA_EVENT_KEYS.installed_as_self_gen
                                        ]
                                    }
                                />
                            </div>
                        </div>

                        {/* -------------------- KW Sold as -------------------- */}
                        <div className='text-sm-bold text-sqGray900 mb-18px'>KW Sold as</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Setter'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_sold_as_setter}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_sold_as_setter]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.kw_sold_as_setter]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Closer'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_sold_as_closer}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_sold_as_closer]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.kw_sold_as_closer]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_sold_as_self_gen}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_sold_as_self_gen]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.kw_sold_as_self_gen]
                                    }
                                />
                            </div>
                        </div>

                        {/* -------------------- KW Installed -------------------- */}
                        <div className='text-sm-bold text-sqGray900 mb-18px'>KW Installed As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Setter'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_installed_as_setter}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_installed_as_setter]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[
                                            ARENA_EVENT_KEYS.kw_installed_as_setter
                                        ]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Closer'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_installed_as_closer}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_installed_as_closer]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[
                                            ARENA_EVENT_KEYS.kw_installed_as_closer
                                        ]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.kw_installed_as_self_gen}
                                    value={eventData?.[ARENA_EVENT_KEYS.kw_installed_as_self_gen]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[
                                            ARENA_EVENT_KEYS.kw_installed_as_self_gen
                                        ]
                                    }
                                />
                            </div>
                        </div>

                        {/* -------------------- Others -------------------- */}
                        <div className='row mb-32px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'New Hiring Leads'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.new_hiring_leads}
                                    value={eventData?.[ARENA_EVENT_KEYS.new_hiring_leads]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.new_hiring_leads]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'New Recruit Hired'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.new_recruit_hired}
                                    value={eventData?.[ARENA_EVENT_KEYS.new_recruit_hired]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.new_recruit_hired]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Hours Worked'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.hours_worked}
                                    value={eventData?.[ARENA_EVENT_KEYS.hours_worked]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.hours_worked]
                                    }
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    required
                                    label={'Days On-Time'}
                                    placeholder='0'
                                    type={INPUT_TYPE.number}
                                    restrictNegativeNum
                                    name={ARENA_EVENT_KEYS.days_on_time}
                                    value={eventData?.[ARENA_EVENT_KEYS.days_on_time]}
                                    onChange={updateEventData}
                                    errorMessage={
                                        arenaEventErrorData?.[ARENA_EVENT_KEYS.days_on_time]
                                    }
                                />
                            </div>
                        </div>
                    </>

                    {/* -------------------- Competition Meta Data -------------------- */}
                    <div className='text-sm-bold text-sqGray900 mb-32px'>Competition Duration</div>
                    <div className='row mb-18px gap-2'>
                        <div className='col-sm'>
                            <CustomAutoCompleteDropdown
                                required
                                display='comma'
                                label={'Competition Days'}
                                options={ARENA_WEEK_DAYS}
                                name={ARENA_EVENT_KEYS.competition_days}
                                selectedOptions={eventData?.[ARENA_EVENT_KEYS.competition_days]}
                                onChange={updateEventData}
                                errorMessage={
                                    arenaEventErrorData?.[ARENA_EVENT_KEYS.competition_days]
                                }
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomDropdown
                                required
                                label={'Duration'}
                                showClear={false}
                                options={ARENA_COMPETITION_DURATION}
                                name={ARENA_EVENT_KEYS.competition_duration}
                                value={eventData?.[ARENA_EVENT_KEYS.competition_duration]}
                                onChange={updateEventData}
                                errorMessage={
                                    arenaEventErrorData?.[ARENA_EVENT_KEYS.competition_duration]
                                }
                            />
                        </div>
                    </div>
                    <div className='row align-items-center gap-2'>
                        <div className='col-sm d-flex flex-column'>
                            <CustomDatePicker
                                required
                                minDate={customMoment.todayDate()}
                                label={'Start Date'}
                                id={'One_time_Payment_Start_Date_Input_1'}
                                placeholderText={'Start Date'}
                                name={ARENA_EVENT_KEYS.competition_start_date}
                                value={eventData?.[ARENA_EVENT_KEYS.competition_start_date]}
                                onChange={updateEventData}
                                errorMessage={
                                    arenaEventErrorData?.[ARENA_EVENT_KEYS.competition_start_date]
                                }
                            />
                        </div>

                        <Show>
                            <Show.When
                                isVisible={
                                    eventData?.[ARENA_EVENT_KEYS.competition_duration] ==
                                    'set_by_exact_date'
                                }
                            >
                                <div className='col-sm d-flex flex-column'>
                                    <CustomDatePicker
                                        required
                                        label={'End Date'}
                                        minDate={customMoment.date(
                                            getValidDate(
                                                eventData?.[
                                                    ARENA_EVENT_KEYS.competition_start_date
                                                ],
                                                'YYYY-MM-DD'
                                            )
                                        )}
                                        placeholderText={'Start Date'}
                                        name={ARENA_EVENT_KEYS.competition_end_date}
                                        value={eventData?.[ARENA_EVENT_KEYS.competition_end_date]}
                                        onChange={updateEventData}
                                        errorMessage={
                                            arenaEventErrorData?.[
                                                ARENA_EVENT_KEYS.competition_end_date
                                            ]
                                        }
                                    />
                                </div>
                            </Show.When>
                            <Show.Else>
                                <div className='col-sm d-flex flex-column'>
                                    <CustomInput
                                        required
                                        label={'No. of days'}
                                        placeholder='0'
                                        type={INPUT_TYPE.number}
                                        restrictNegativeNum
                                        name={ARENA_EVENT_KEYS.competition_no_of_days}
                                        value={eventData?.[ARENA_EVENT_KEYS.competition_no_of_days]}
                                        onChange={updateEventData}
                                        errorMessage={
                                            arenaEventErrorData?.[
                                                ARENA_EVENT_KEYS.competition_no_of_days
                                            ]
                                        }
                                    />
                                </div>
                            </Show.Else>
                        </Show>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetGameRulesLadder
