import {useCallback, useEffect, useState} from 'react'
import {fontsFamily} from '../../../../../assets/fonts/fonts'
import {KTSVG} from '../../../../../_metronic/helpers'
import CustomButton from '../../../../../customComponents/customButtton/CustomButton'
import {useLocation, useNavigate} from 'react-router-dom'
import CustomToast from '../../../../../customComponents/customToast/CustomToast'
import {getErrorMessageFromResponse} from '../../../../../helpers/CommonHelpers'
import CustomCardSkeleton from '../../../../../customComponents/customSkeleton/CustomCardSkeleton'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton2'

const ArenaPurchasePlans = () => {
    const [selectedPlan, setSelectedPlan] = useState(null) // 1 = Basic | 2 = Plus | 3 = Pro
    const naviagte = useNavigate()
    const [planData, setPlanData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const location = useLocation()

    // useEffect(() => {
    //     getPlanData()
    // }, [])

    // const getPlanData = useCallback(() => {
    //     getSequiAiPlansV1Service()
    //         .then((res) => {
    //             setPlanData(res?.data)
    //             setSelectedPlan(res?.data?.[0]?.id)
    //         })
    //         .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
    //         .finally(() => setLoading(false))
    // }, [])

    const activateArena = () => {
        naviagte('/integration')
        // setButtonLoading(true)
        // let crmId = location?.state?.crmId
        // const body = {crm_id: crmId, sequiai_plan_id: selectedPlan}
        // activateSequiAiV1Service(body)
        //     .then(() => {
        //         naviagte('/integration')
        //         CustomToast.success('SequiAI activated successfully')
        //     })
        //     .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
        //     .finally(() => setButtonLoading(false))
    }

    return (
        <div className='mx-sm-10' style={{marginTop: -20, fontFamily: fontsFamily.manrope}}>
            <div
                className='bg-cmwhite shadow-sm p-sm-10 p-32px'
                style={{borderRadius: 10, fontFamily: fontsFamily.manrope}}
            >
                {/* title */}
                <div className='text-center mb-15'>
                    <div className='text-dsm-bold text-cmGrey900 mb-5'>
                        Minimize Confusion, Maximize Efficiency
                    </div>
                    <div className='ext-sm-medium text-cmGrey700'>
                        Get started today and make job tracking and management a breeze. Review the{' '}
                        <br />
                        features and pricing offered below.
                    </div>
                </div>
                {/* plans */}
                <div className='d-flex flex-wrap  flex-center gap-sm-10 gap-5 mb-sm-15 mb-10'>
                    {loading ? (
                        <CustomCardSkeleton count={2} height='480px' />
                    ) : (
                        <>
                            <BasicPlanCard
                                selectedPlan={selectedPlan}
                                setSelectedPlan={setSelectedPlan}
                                data={planData?.[0]}
                            />
                            <PlusPlanCard
                                selectedPlan={selectedPlan}
                                setSelectedPlan={setSelectedPlan}
                                data={planData?.[1]}
                            />
                        </>
                    )}
                </div>
                {/* button */}
                <div>
                    <CustomButton
                        id={'purchase_plan_Get_Started_Btn'}
                        onClick={activateArena}
                        disabled={selectedPlan == null}
                        buttonLabel='Get Started'
                        buttonSize={BUTTON_SIZE.large}
                        loading={buttonLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default ArenaPurchasePlans

// 1
const BasicPlanCard = ({selectedPlan, data, setSelectedPlan}) => {
    const planItems = [
        'Vibrant Games For High User Engagement ',
        'Run Competitions Amongst Offices Or Individuals',
        'Easy Point System For Competition Tracking',
        '16 Unique Trackable Metrics ',
        'Rewards automatically sent to payroll for easy Processing',
    ]

    return (
        <div
            id='Basic_Plan_Card'
            className={`${
                selectedPlan === 1
                    ? 'h-sm-450px bg-cmBlue-Crayola bg-opacity-10'
                    : 'h-sm-450px bg-strip  '
            } w-600px py-23 px-24px card m-0`}
            style={{borderRadius: 18}}
        >
            {/* <div className='h-25px mb-5'></div> */}

            <div className='d-flex flex-column justify-content-between card-body py-23px px-5  m-0 h-sm-80 '>
                <div>
                    <div className='d-flex justify-content-between align-items-center mb-24px mt-30px '>
                        <div className='text-cmBlack mb-2 mt-5 text-xl-bold'>
                            {/* {data?.plan_name} */}
                            Basic
                            <div className='text-sqGray600 text-sm-medium'>
                                Essential Gamification and Basic Competitions
                            </div>
                        </div>

                        <div className='text-sqPrimary600'>
                            <span>$</span>
                            <span className='text-dmd-semibold'>{/* {data?.price} */}5</span>
                            <span className='text-sqGray500 text-xs-regular'> / User</span>
                        </div>
                    </div>
                    <div className='mb-89px ms-7px'>
                        {planItems.map((i) => (
                            <li className='d-flex justify-content-between mb-3'>
                                <div className='text-sqGray900 text-sm-medium'>
                                    <span className='me-2'>&#8226;</span>
                                    {i}
                                </div>
                                <KTSVG
                                    path='/media/icons/duotune/art/sequiDocSuccess.svg'
                                    svgClassName='w-16px h-16px'
                                />
                            </li>
                        ))}
                    </div>
                </div>
                <div className='d-flex flex-center'>
                    <CustomButton2
                        buttonLabel='Subscribed'
                        buttonSize={BUTTON_SIZE.md}
                        buttonType={BUTTON_TYPE.secondaryColor}
                        onClick={() => setSelectedPlan(1)}
                    />
                </div>
            </div>
        </div>
    )
}
const PlusPlanCard = ({selectedPlan, data}) => {
    const planItems = [
        'Basic Plan Features',
        'Job Financials',
        'Customer Messaging And Communication',
        'Customer Job Portal',
        'Allow Customer To View Job Progress',
        'Custom Integrations',
    ]

    return (
        <div className='position-relative d-flex flex-center'>
            <div
                id='Basic_Plan_Card'
                className={`${
                    1 > 0 ? 'h-sm-450px bg-cmBlue-Crayola bg-opacity-10' : 'h-sm-450px bg-strip  '
                } w-600px py-23 px-24px card m-0`}
                style={{borderRadius: 18, filter: 'blur(3px)'}}
            >
                <div className='d-flex flex-column justify-content-between card-body py-23px px-5  m-0 h-sm-80 '>
                    <div>
                        <div className='d-flex justify-content-between align-items-center  mt-30px '>
                            <div className='text-cmBlack mb-2 mt-5 text-xl-bold'>
                                {/* {data?.plan_name} */}
                                Plus
                                <div className='text-sqGray600 text-sm-medium'>
                                    Customer portal & Job Financials
                                </div>
                            </div>

                            <div className='text-sqPrimary600'>
                                <span>$</span>
                                <span className='text-dmd-semibold'>{/* {data?.price} */}10</span>
                                <span className='text-sqGray500 text-xs-regular'> / User</span>
                            </div>
                        </div>
                        <div className='mb-89px ms-7px'>
                            {planItems.map((i) => (
                                <li className='d-flex justify-content-between mb-3'>
                                    <div className='text-sqGray900 text-sm-medium'>
                                        <span className='me-2'>&#8226;</span>
                                        {i}
                                    </div>
                                    <KTSVG
                                        path='/media/icons/duotune/art/sequiDocSuccess.svg'
                                        svgClassName='w-16px h-16px'
                                    />
                                </li>
                            ))}
                        </div>
                    </div>
                    <div className='d-flex flex-center'>
                        <CustomButton2
                            buttonLabel='Subscribed'
                            buttonSize={BUTTON_SIZE.md}
                            buttonType={BUTTON_TYPE.secondaryColor}
                            // onClick={() => setOpenBuyPlanModal(true)}
                        />
                    </div>
                </div>
            </div>
            <ComingSoonCard />
        </div>
    )
}

const ComingSoonCard = () => {
    return (
        <div
            className='card shadow-sm d-flex flex-column gap-24px h-170px w-320px py-24px px-18px'
            style={{position: 'absolute'}}
        >
            <div className='text-center'>
                <KTSVG
                    path='/media/icons/duotune/art/ArenaLogo.svg'
                    svgClassName='w-45px h-45px cursor-pointer'
                />
            </div>
            <div className='text-center'>
                <div className='text-sqGray900 text-lg-bold'>Coming Soon</div>
                <div className='text-sm-medium text-sqGray500'>New Features and New Game modes</div>
            </div>
        </div>
    )
}
