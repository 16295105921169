// import {DEV_TEAMs, DEVELOPMENT_TEAM} from './constants'

/**
 * BASE URL CONFIG
 */
export const HOST_SERVERS = {
    multitenant: 'multitenant',
    // Testing / Development Servers
    localhost: 'localhost',
    demo: 'demo',
    sdev: 'sdev',
    pdev: 'pdev',
    sstage: 'sstage',
    pstage: 'pstage',
    flextest: 'flextest',
    testaveyo: 'testaveyo',
    preprod: 'preprod',
    pestdev: 'pestdev',
    multitenant: 'multitenant',
    milestone: 'milestone',

    // Live / Client Servers
    uat: 'uat',
    adam: 'adam',
    sequifi: 'sequifi',
    flex: 'flex',
    aveyo: 'aveyo',
    aveyo2: 'aveyo2',
    newera: 'newera',
    soar: 'soar',
    flexpest: 'flexpest',
    capecod: 'capecod',
    maine: 'maine',
    poweredup: 'poweredup',
    momentum: 'momentum',
    toptier: 'toptier',
    vantagepoint: 'vantagepoint',
    simpleflow: 'simpleflow',
    harvestsolar: 'harvestsolar',
    groffs: 'groffs',
    homeguard: 'homeguard',
    currentenergy: 'currentenergy',

    // Tenancy
    tenancy: 'tenancy',
}

export const getServerName = (serverName) => {
    return serverName?.includes(HOST_SERVERS.localhost) ? 'localhost' : serverName?.split('.')?.[0]
}
export const DEFAULT_SERVER = process.env?.REACT_APP_HOST_NAME
    ? getServerName(process.env.REACT_APP_HOST_NAME)
    : HOST_SERVERS.sstage

/**
 * !Don't Change / Delete
 */

export const HOST_ORIGIN = window.location.origin
export const HOST_NAME = window.location.hostname?.includes('localhost')
    ? process.env?.REACT_APP_HOST_NAME
    : window.location.hostname
export const CURRENT_HOST = getServerName(HOST_NAME)
export const IS_MULTITENANT_ADMIN = CURRENT_HOST?.includes(HOST_SERVERS.multitenant)

export const OLD_SERVER_WITH_NEW_DOMAIN_SYSTEM = [
    HOST_SERVERS.multitenant,
    HOST_SERVERS.currentenergy,
    HOST_SERVERS.milestone,
].includes(CURRENT_HOST)

export const IS_TENANT_CLIENTS = !Object.keys(HOST_SERVERS)?.includes(CURRENT_HOST)
export const IS_NEW_DOMAIN_SYSTEM =
    !Object.keys(HOST_SERVERS)?.includes(CURRENT_HOST) || OLD_SERVER_WITH_NEW_DOMAIN_SYSTEM

/**
 * Server Config STARTED
 */
const BASE_URL_SECURE_URL = 'https://'

const BASE_URL_PRE_HOST_URL = IS_NEW_DOMAIN_SYSTEM ? `${CURRENT_HOST}.api.` : 'api.'

const BASE_URL_HOST_NAME_URL = CURRENT_HOST.includes(HOST_SERVERS.localhost)
    ? DEFAULT_SERVER + '.'
    : IS_NEW_DOMAIN_SYSTEM
    ? ''
    : CURRENT_HOST + '.'

export const BASE_URL_PATH =
    IS_NEW_DOMAIN_SYSTEM && !OLD_SERVER_WITH_NEW_DOMAIN_SYSTEM
        ? 'sequifi.com/public'
        : 'sequifi.com/public/api'

export const IS_LOCAL_BACKEND = process.env.REACT_APP_IS_LOCAL_BACKEND == 'true'

const ORIGIN = BASE_URL_SECURE_URL + BASE_URL_PRE_HOST_URL + BASE_URL_HOST_NAME_URL

export const FULL_BASE_URL = IS_LOCAL_BACKEND
    ? process.env.REACT_APP_BACKEND_FULL_URL
    : ORIGIN + BASE_URL_PATH

export const IMAGE_URL_PATH = 'sequifi.com'
export const FULL_IMAGE_URL = ORIGIN + IMAGE_URL_PATH
export const MULTITENANT_API_BASE_URL = 'https://multitenant.api.sequifi.com/public/api'
