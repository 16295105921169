import {useMemo, useState} from 'react'
import CustomInput, {INPUT_TYPE} from '../CustomInput'
import {getGlobalSearchData} from '../../../../helpers/CommonHelpers'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import CustomImage from '../../../customImage/CustomImage'
import images from '../../../../assets/images'
import Show from '../../../show/Show'

const CustomInputChipOpen = ({
    label = null,
    placeholder = null,
    optionList = [],
    searchFields = [],
    selectedList = [],
    displayKey = 'name',
    valueKey = 'value',
    onOptionPress = null,
}) => {
    const [inputValue, setInputValue] = useState('')
    const [chips, setChips] = useState([])
    const [searchText, setSearchText] = useState('')

    const searchedData = useMemo(() => {
        return searchText ? getGlobalSearchData(optionList, searchFields, searchText) : optionList
    }, [optionList, searchFields, searchText])

    const handleInputChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            setChips([...chips, inputValue.trim()])
            setInputValue('')
        }
    }

    return (
        <div style={{fontFamily: fontsFamily.manrope}}>
            <CustomInput
                label={label}
                onChange={handleInputChange}
                value={searchText}
                onKeyPress={handleInputKeyPress}
                placeholder={placeholder}
                type={INPUT_TYPE.search}
            />
            <div
                style={{
                    maxHeight: 250,
                    zIndex: 1,
                    marginTop: '14px',
                    overflowY: 'scroll',
                    position: 'relative',
                }}
            >
                {searchedData?.map((item, index) => {
                    const isThereInList =
                        selectedList?.length > 0
                            ? selectedList?.some((tItem) => tItem?.[valueKey] == item?.[valueKey])
                            : false

                    return (
                        <div
                            key={index}
                            className={`${
                                isThereInList ? 'bg-sqPrimary600 bg-opacity-10' : ''
                            } d-flex cursor-pointer justify-content-between h-40px px-5 w-100`}
                            style={{
                                backgroundColor: '',
                            }}
                            onClick={() => {
                                onOptionPress(item)
                            }}
                        >
                            <div className='d-flex gap-5 align-items-center w-100'>
                                <span className='text-sm-medium text-Gray900 d-flex align-items-center justify-content-between w-100'>
                                    {item?.[displayKey]}
                                    <Show>
                                        <Show.When isVisible={isThereInList}>
                                            <CustomImage
                                                src={images.check}
                                                style={{height: '20px', width: '20px'}}
                                            />
                                        </Show.When>
                                    </Show>
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CustomInputChipOpen
