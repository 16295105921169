const CustomSortSvg = ({className = '', onClick = () => {}, sortingOrder = null, id = null}) => {
    return (
        <span
            id={id}
            className={'cursor-pointer d-flex flex-column ms-12px my-0 py-0' || className}
            onClick={onClick}
        >
            {sortingOrder !== 'asc' ? (
                <i
                    className='bi bi-caret-up-fill text-sqGray700 text-hover-sqPrimary600'
                    data-toggle='tooltip'
                    title={'ASC Order'}
                    style={{marginBottom: -2, fontSize: 10}}
                ></i>
            ) : (
                <i
                    className='bi bi-caret-up-fill text-cmBlue-Crayola'
                    data-toggle='tooltip'
                    title={'ASC Order'}
                    style={{marginBottom: -2, fontSize: 10}}
                ></i>
            )}
            {sortingOrder !== 'desc' ? (
                <i
                    className='bi bi-caret-down-fill text-sqGray700 text-hover-sqPrimary600'
                    data-toggle='tooltip'
                    title={'DESC Order'}
                    style={{fontSize: 10}}
                ></i>
            ) : (
                <i
                    className='bi bi-caret-down-fill text-cmBlue-Crayola'
                    data-toggle='tooltip'
                    title={'DESC Order'}
                    style={{fontSize: 10}}
                ></i>
            )}
        </span>
    )
}

export {CustomSortSvg}
