import {Dialog} from 'primereact/dialog'
import {fontsFamily} from '../../assets/fonts/fonts'
import {KTSVG} from '../../_metronic/helpers'
import Show from '../show/Show'
import CustomTooltip from '../customTooltip/CustomTooltip'

const CustomModal2 = ({
    id = null,
    children,
    show,
    fullScreen = false,
    onShow = null,
    onHide,
    title,
    showHeader = true,
    footerComponent = null,
    // fullScreen = false,
    borderRadius = '10px',
    maxWidth = 750,
    borderColor = null,
    zIndex = 0,
    className = null,
    backgroundColor = 'cmwhite',
    style = null,
    showCloseButton = true,
    header_py = '18px',
    header_px = '18px',
    body_py = '18px',
    body_px = '18px',
    headerBorder = true,
    titleHelpText = '',
}) => {
    return (
        <Dialog
            onShow={onShow}
            id={id}
            contentClassName={`bg-${backgroundColor} p-0`}
            className={`${
                fullScreen ? '' : `mw-${maxWidth}px`
            } w-100 p-0 m-0  ${className} shadow-lg overflow-hidden`}
            style={{
                borderRadius: borderRadius,
                padding: 0,
                margin: 0,
                fontFamily: fontsFamily.manrope,
                ...style,
            }}
            visible={show}
            maximized={fullScreen}
            onHide={onHide}
            closable={false}
            closeOnEscape
            baseZIndex={zIndex}
            modal
            blockScroll={true}
            headerClassName='p-0'
            header={() =>
                showHeader ? (
                    <div
                        className={`d-flex justify-content-between align-items-center py-${header_py} px-${header_px} ${
                            headerBorder ? 'border-bottom border-sqGray300' : ''
                        }  m-0`}
                    >
                        <div></div>
                        <div className='d-flex gap-8px align-items-center'>
                            <div className='text-lg-bold text-sqGray900'>{title}</div>

                            <Show>
                                <Show.When isVisible={titleHelpText ? true : false}>
                                    <CustomTooltip
                                        style={{fontSize: '12px'}}
                                        title={titleHelpText}
                                        position='right'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/art/question-gray700.svg'
                                            svgClassName='w-16px h-16px cursor-pointer'
                                        />
                                    </CustomTooltip>
                                </Show.When>
                            </Show>
                        </div>

                        <KTSVG
                            path='/media/icons/duotune/art/gray-close-circle.svg'
                            svgClassName='w-30px h-30px cursor-pointer'
                            onClick={onHide}
                        />
                    </div>
                ) : null
            }
            footer={footerComponent ?? null}
            // contentStyle={{
            //     position: 'relative',
            //     padding: ladder && 0,
            //     // borderBottomLeftRadius: borderRadius,
            //     // borderBottomRightRadius: borderRadius,
            // }}
        >
            <div className={`py-${body_py} px-${body_px} position-relative`}>
                {children}
                {showCloseButton && !showHeader ? (
                    <div className={`position-absolute top-0 end-0 py-${body_py} px-${body_px}`}>
                        <KTSVG
                            path='/media/icons/duotune/art/x.svg'
                            svgClassName='w-20px h-20px cursor-pointer'
                            onClick={onHide}
                        />
                    </div>
                ) : null}
            </div>
        </Dialog>
    )
}

export default CustomModal2
