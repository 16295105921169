import {useEffect, useRef, useState} from 'react'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton2'
import useValidation from '../../../../hooks/useValidation'
import {newBasicInfoValidation} from '../../../../validations/validations'
import {
    postTenantBasicInformationService,
    UpdateTenantBasicInfomationService,
} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import {getErrorMessageFromResponse} from '../../../../helpers/CommonHelpers'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'
import CustomInput, {
    CommonLabel,
} from '../../../../customComponents/customInputs/customInput/CustomInput'
import {Container, Card} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'

const buttons = [
    {label: 'Solar', value: 'Solar', type: BUTTON_TYPE.secondaryGray},
    {label: 'Pest Control', value: 'Pest', type: BUTTON_TYPE.secondaryGray},
    {label: 'Turf', value: 'Turf', type: BUTTON_TYPE.secondaryGray},
    {label: 'Roofing', value: 'Roofing', type: BUTTON_TYPE.secondaryGray},
    {label: 'Real Estate', value: 'Real Estate', type: BUTTON_TYPE.secondaryGray},
]
export const BasicInformation = ({
    setActiveIndex,
    setTenantId,
    tabActive,
    setSaleData,
    salesData,
    selectedFile,
    setSelectedFile,
    selectedImage,
    setSelectedImage,
    completeStep,
    selectedButton,
    setSelectedButton,
    location,
    loadingGetData,
}) => {
    const fileInputRef = useRef(null)
    const [validateSaleData, salesErrorData] = useValidation()
    const [loading, setLoading] = useState(false)

    const onChangeInputData = (e) => {
        const {name, value} = e.target
        setSaleData((val) => ({
            ...val,
            [name]: value,
        }))
    }

    const handleButtonClick = (label) => {
        setSelectedButton(label)
    }
    const body = {
        name: salesData?.companyName,
        domain_name: salesData?.sub_domain,
        companyType: selectedButton,
        image: selectedImage || '',
    }

    const payload = {
        name: salesData?.companyName,
        image: selectedImage || '',
        tenant_id: location?.state?.tid,
    }
    const formData = new FormData()
    const formDataEdit = new FormData()

    Object.entries(body).forEach(([key, value]) => {
        formData.append(key, value)
    })
    Object.entries(payload).forEach(([key, value]) => {
        formDataEdit.append(key, value)
    })

    useEffect(() => {
        if (salesErrorData?.beginValidating) {
            validateSaleData(newBasicInfoValidation(body))
        }
    }, [salesData])
    const saveAndContinue = () => {
        validateSaleData(newBasicInfoValidation(body)).then((res) => {
            if (res.isValidate) {
                // if (type == 'alert') {
                setLoading(true)
                if (location?.state?.tid) {
                    UpdateTenantBasicInfomationService(formDataEdit)
                        .then((res) => {
                            setActiveIndex(1)
                            CustomToast.success(res?.message)
                            // setTenantId(res?.data?.tenant_id)
                            completeStep()
                        })
                        .catch((e) => {
                            CustomToast.error(getErrorMessageFromResponse(e))
                        })
                        .finally(() => setLoading(false))
                } else {
                    postTenantBasicInformationService(formData)
                        .then((res) => {
                            setActiveIndex(1)
                            CustomToast.success(res?.message)
                            setTenantId(res?.data?.tenant_id)
                            completeStep()
                            const formData = {
                                currentStep: 1,
                                tenantId: res?.data?.tenant_id,
                                stepCompletion: [true],
                            }
                            localStorage.setItem('currentStep', JSON.stringify(formData))
                        })
                        .catch((e) => {
                            CustomToast.error(getErrorMessageFromResponse(e))
                        })
                        .finally(() => setLoading(false))
                }
            }
        })
    }

    const handleCardClick = () => {
        fileInputRef.current.click()
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0]
        setSelectedImage(file)
        if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                setSelectedFile(e.target.result)
            }
            reader.readAsDataURL(file)
        }
    }

    const handleDrop = (event) => {
        event.preventDefault()
        const file = event.dataTransfer.files[0]
        setSelectedImage(file)

        if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                setSelectedFile(e.target.result)
            }
            reader.readAsDataURL(file)
        }
    }

    const handleDragEnter = (event) => {
        event.preventDefault()
    }
    return (
        <div style={{position: 'relative'}}>
            <CustomLoader visible={loading || loadingGetData} full />
            <div className='text-xl-bold text-sqGray900 mb-3'>Basic Information</div>
            <div className='d-flex justify-content-between responsivess-first row'>
                <div className='col-md-7 mb-17'>
                    <div className='mb-5 '>
                        <CustomInput
                            label={'Company Name'}
                            name='companyName'
                            placeholder='Enter Company Name'
                            onChange={onChangeInputData}
                            value={salesData?.companyName}
                            required
                            errorMessage={salesErrorData?.companyName}
                        />
                    </div>
                    <div className='mb-5 '>
                        <CustomInput
                            label={'Sub-domain'}
                            name='sub_domain'
                            placeholder='Enter Sub-domain'
                            onChange={onChangeInputData}
                            value={salesData?.sub_domain}
                            required
                            errorMessage={salesErrorData?.subDomain}
                            disabled={location?.state?.tid}
                        />
                    </div>

                    {/* ---- Types of Company ---- */}
                    <div>
                        <div className='mb-1'>
                            <CommonLabel label='Type of company' required />
                        </div>
                        <div className='d-flex gap-5 mb-5'>
                            <div class='d-flex flex-wrap justify-content-center gap-5'>
                                {buttons.map((button) => (
                                    <div key={button.label}>
                                        <CustomButton2
                                            buttonLabel={button.label}
                                            buttonSize={BUTTON_SIZE.sm}
                                            buttonType={
                                                selectedButton === button.value
                                                    ? BUTTON_TYPE.secondaryColor
                                                    : button.type
                                            }
                                            width={'115px'}
                                            selected={selectedButton === button.value}
                                            onClick={() => handleButtonClick(button.value)}
                                            disabled={location?.state?.tid}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Container className='col-xl-3 col-md-4'>
                    <div className='text-sm-bold text-sqGray700 mb-3'>Upload Logo</div>
                    <Card
                        className='d-flex flex-column justify-content-center align-items-center p-0 w-100'
                        style={{
                            border: '3px dashed #80808063',
                            width: '286px',
                            height: '166px',
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}
                        onClick={handleCardClick}
                        onDragEnter={handleDragEnter}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                    >
                        {selectedFile ? (
                            <img
                                src={selectedFile}
                                alt='Uploaded Logo'
                                className='uploaded-image'
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        ) : (
                            <>
                                <KTSVG
                                    path='/media/icons/duotune/art/plus-circle.svg'
                                    className='cursor-pointer mb-2'
                                    svgClassName='w-30px h-30px'
                                />
                                <div className='text-xs-medium text-sqGray800 mb-2'>
                                    Upload File
                                </div>
                                <div className='text-sqGray700 text-center'>
                                    Drag and drop logo here or click to upload.
                                </div>
                            </>
                        )}
                    </Card>
                    <input
                        id='File_Input_Excel_Import_Modal'
                        ref={fileInputRef}
                        type='file'
                        onChange={handleFileSelect}
                        style={{display: 'none'}}
                        accept='.heic,.png,.jpeg,.jpg'
                    />
                </Container>
            </div>
            <div className='mt-20'>
                <CustomButton2
                    buttonLabel=' Save & Continue'
                    buttonSize={BUTTON_SIZE.lg}
                    buttonType={BUTTON_TYPE.primary}
                    onClick={saveAndContinue}
                    // width={'225px'}
                />
            </div>
        </div>

        // {tabActiveIndex === 2 && <AdministrativeSetup />}
    )
}
