import React from 'react'
import './RadioButtonStyle.css'
import {RadioButton} from 'primereact/radiobutton'

export const RADIO_SIZE = {
    sm: 'sm',
    md: 'md',
}

const CustomRadioButton1 = ({
    label,
    subLabel,
    name,
    value,
    isChecked,
    handleChange,
    children,
    childClass,
    id = null,
    disabled = false,
    size = RADIO_SIZE.sm,
}) => {
    return (
        <div
            className={`${
                size == RADIO_SIZE.sm ? 'smRadioBtn' : 'mdRadioBtn'
            }   d-inline-flex flex-start gap-2 `}
        >
            <input
                type='radio'
                className='mt-1'
                name={name}
                checked={isChecked}
                onChange={handleChange}
                disabled={disabled}
            />
            {label ? (
                <div className='text-sqGray700 d-flex flex-column'>
                    <div
                        className={`${size == RADIO_SIZE.sm ? 'text-sm-medium' : 'text-md-medium'}`}
                    >
                        {label}
                    </div>
                    {subLabel ? (
                        <div
                            className={`text-sqGray500 ${
                                size == RADIO_SIZE.sm ? 'text-sm-regular' : 'text-sqGray400'
                            }  `}
                        >
                            {subLabel}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    )
}

export default CustomRadioButton1
