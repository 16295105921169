import clsx from 'clsx'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {ROLES} from '../../../../accessRights/AccessRightsConstants'
import {setAccessRightsAction, setActiveRoleAction} from '../../../../redux/actions/AuthActions'
import {
    getActiveRoleSelector,
    getUserDataSelector,
    getUserRolesSelector,
} from '../../../../redux/selectors/AuthSelectors'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import AccessRights from '../../../../accessRights/AccessRights'
import CustomLink from '../../../../customComponents/customButtton/CustomLink'
import NotificationSidebar from './NotificationSidebar'
import {getNotificationSelector} from '../../../../redux/selectors/SettingsSelectors'
import CustomUserAvatar from '../../../../customComponents/customUserAvatar/CustomUserAvatar'
import useSequifiConfig from '../../../../sequiConfig/useSequifiConfig'
import Show from '../../../../customComponents/show/Show'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton2'
import CustomTooltip from '../../../../customComponents/customTooltip/CustomTooltip'
import {switchToAdminService} from '../../../../services/Services'
import {HIDDEN_SUPER_ADMIN} from '../../../../constants/constants'
import {getPositionSettingAction} from '../../../../redux/actions/SettingActions'
import {getErrorMessageFromResponse, sendDataToReducer} from '../../../../helpers/CommonHelpers'
import {SET_TOKEN, SET_USER_DATA, USER_IS_LOGGED_IN} from '../../../../redux/reducers/AuthReducer'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import oneSignal from '../../../../onesignal/oneSignal'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
    const dispatch = useDispatch()
    var navigate = useNavigate()
    const userData = useSelector(getUserDataSelector)
    const userRoles = useSelector(getUserRolesSelector)
    var activeRole = useSelector(getActiveRoleSelector)
    const notification = useSelector(getNotificationSelector)
    const handleUser = (role) => {
        dispatch(setActiveRoleAction(role))
        navigate('/dashboard', {state: role})
    }
    const [profileView, setProfileView] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const [notificationData, setNotificationData] = useState(notification?.data)
    const [showNotificationSidebar, setShowNotificationSidebar] = useState(false)
    const {basedOnHost} = useSequifiConfig()

    const {config} = useLayout()

    const handleNotificationSidebar = () => {
        setShowNotificationSidebar(!showNotificationSidebar)
    }

    const onSwitchBackToAdmin = () => {
        switchToAdminService()
            .then((res) => {
                if (res?.data?.access_rights?.length > 0) {
                    sendDataToReducer(dispatch, SET_USER_DATA, {
                        ...res?.data,
                        token: res?.token,
                        lastLogin: res?.last_login,
                    })
                    oneSignal.promptForNotificationPermissionAndGetPlayerId()
                    sendDataToReducer(dispatch, USER_IS_LOGGED_IN, true)
                    dispatch(setAccessRightsAction(res?.data?.access_rights))
                    const isStandardAvailable = res?.data?.access_rights?.some(
                        (item) => item?.name == ROLES.standard.roleValue
                    )
                    const isAdminAvailable = res?.data?.access_rights?.some(
                        (item) => item?.name == ROLES.administrator.roleValue
                    )
                    dispatch(
                        setActiveRoleAction(
                            res?.data?.is_super_admin
                                ? ROLES.administrator.roleValue
                                : isStandardAvailable
                                ? ROLES.standard.roleValue
                                : isAdminAvailable
                                ? ROLES.administrator.roleValue
                                : null
                        )
                    )
                    dispatch(getPositionSettingAction())

                    sendDataToReducer(dispatch, SET_TOKEN, res.token)
                    navigate('/')
                } else {
                    CustomToast.error('This user have not any assigned role')
                }
            })
            .catch((error) => {
                CustomToast.error(getErrorMessageFromResponse(error))
            })
    }

    return (
        <div className='app-navbar flex-shrink-0 gap-5'>
            <div className={clsx('app-navbar-item d-lg-inline my-auto d-none ', itemClass)}>
                <div data-kt-search-element='toggle' id='kt_header_search_toggle'>
                    {activeRole ? (
                        <>
                            {activeRole !== ROLES.administrator.roleValue &&
                            userRoles.includes(ROLES.administrator.roleValue) ? (
                                <SwitchRoleUI
                                    title={'Switch to Admin'}
                                    activeRole={activeRole}
                                    handleUser={handleUser}
                                />
                            ) : activeRole !== ROLES.standard.roleValue &&
                              userRoles.includes(ROLES.standard.roleValue) ? (
                                <SwitchRoleUI
                                    title={'Switch to Standard'}
                                    activeRole={activeRole}
                                    handleUser={handleUser}
                                />
                            ) : null}
                        </>
                    ) : null}
                </div>
            </div>
            <div className={clsx('app-navbar-item align-items-stretch ', itemClass)}>
                <div
                    className='d-flex gap-5 align-items-center'
                    data-kt-search-element='toggle'
                    id='kt_header_search_toggle'
                >
                    {activeRole === ROLES.standard.roleValue ? (
                        <KTSVG
                            path='/media/icons/duotune/art/podium.svg'
                            svgClassName='w-30px h-30px cursor-pointer'
                            onClick={() => navigate('/standard-dashboard/leaderboards')}
                        />
                    ) : null}
                    {/* Notification Icon */}

                    {basedOnHost?.notificationModule ? (
                        <div className=''>
                            <div
                                className='position-relative cursor-pointer bg-hover-sqGray100 rounded-circle p-1'
                                style={{transitionDuration: '500ms'}}
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                                data-kt-menu-placement='bottom-center'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/art/notification.svg'
                                    svgClassName='w-25px h-25px cursor-pointer'
                                />

                                <span className='position-absolute top-20 start-75 translate-middle p-2'>
                                    <span
                                        className='bi bi-circle-fill text-cmError'
                                        style={{fontSize: 8}}
                                    ></span>
                                </span>
                            </div>
                            <div
                                className='dropdown-menu text-cmGrey600 w-sm-350px w-95'
                                data-kt-menu='true'
                                style={{
                                    borderRadius: '10px',
                                    fontSize: '12px',
                                    fontFamily: fontsFamily.manrope,
                                }}
                            >
                                <div
                                    className='text-cmBlack p-5 border-bottom border-cmGrey200'
                                    style={{fontSize: '16px', fontWeight: 700, lineHeight: '24px'}}
                                >
                                    Notifications ({notification?.total})
                                </div>
                                <div className='' style={{fontSize: 14, fontWeight: 600}}>
                                    {notificationData?.slice(0, 4).map((i) => (
                                        <div className='p-5 border-bottom border-cmGrey200'>
                                            <div className='d-flex gap-3 align-items-center'>
                                                <i className='bi bi-circle-fill fs-9 text-cmgreen'></i>
                                                <div
                                                    className='text-cmBlack'
                                                    style={{fontWeight: 700}}
                                                >
                                                    {i?.name ?? '-'}
                                                </div>
                                            </div>
                                            <div className='d-flex gap-3 align-items-center mb-2'>
                                                <i className='text-cmwhite bi bi-circle-fill fs-9'></i>
                                                <div className='text-cmGrey600'>
                                                    {i?.subtitle ?? '-'}
                                                </div>
                                            </div>
                                            <div className='d-flex gap-3 align-items-center'>
                                                <i className='text-cmwhite bi bi-circle-fill fs-9'></i>
                                                <div>
                                                    <CustomLink label={'View Now'} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='my-5 d-flex flex-end pe-10'>
                                        <CustomButton2
                                            buttonLabel='View All'
                                            buttonSize={BUTTON_SIZE.sm}
                                            onClick={handleNotificationSidebar}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {showNotificationSidebar ? (
                        <NotificationSidebar
                            open={showNotificationSidebar}
                            onClose={handleNotificationSidebar}
                        />
                    ) : null}
                    <>
                        <AccessRights
                            forSuperAdmin={false}
                            customCondition={
                                activeRole === ROLES.administrator.roleValue &&
                                userRoles.includes(ROLES.administrator.roleValue)
                            }
                        >
                            <div
                                className='cursor-pointer bg-hover-sqGray100 rounded-circle p-1'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                                data-kt-menu-placement='bottom-center'
                                style={{transitionDuration: '500ms'}}
                            >
                                <KTSVG
                                    path='/media/icons/duotune/art/setting-gear.svg'
                                    svgClassName='w-25px h-25px'
                                />
                            </div>
                        </AccessRights>
                        <Show>
                            <Show.When isVisible={activeRole === ROLES.administrator.roleValue}>
                                <div
                                    className='dropdown-menu text-cmGrey600 py-5 mw-sm-450px w-95'
                                    data-kt-menu='true'
                                    style={{
                                        borderRadius: '10px',
                                        fontSize: '12px',
                                        fontFamily: fontsFamily.manrope,
                                    }}
                                >
                                    <div
                                        className='text-cmGrey800 mb-10 px-7'
                                        style={{fontSize: '16px', fontWeight: 700}}
                                    >
                                        Settings
                                    </div>

                                    <div>
                                        <div
                                            className='text-uppercase mb-3 px-7'
                                            style={{
                                                fontWeight: 600,
                                                fontFamily: fontsFamily.manrope,
                                            }}
                                        >
                                            Sequifi Admin
                                        </div>
                                        <div className='d-flex flex-column flex-start'>
                                            <div
                                                className='dropdown-item d-flex gap-3 text-wrap w-100 px-7 py-3 bg-hover-cmGrey100'
                                                onClick={() => navigate('settings/user-management')}
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/art/people.svg'
                                                    className='cursor-pointer'
                                                    svgClassName='w-35px h-35px'
                                                />
                                                <div className='d-flex flex-column'>
                                                    <div
                                                        className='text-cmGrey800'
                                                        style={{fontWeight: 600, fontSize: 14}}
                                                    >
                                                        User Management
                                                    </div>
                                                    <div
                                                        className='text-cmGrey600'
                                                        style={{fontWeight: 500}}
                                                    >
                                                        Manage users, group administration, and
                                                        access requests.
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className='dropdown-item d-flex gap-3  text-wrap cursor-pointer w-100 px-7 py-3 bg-hover-cmGrey100 '
                                                onClick={() =>
                                                    navigate('settings/billings/subscription')
                                                }
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/art/Billing.svg'
                                                    className='cursor-pointer'
                                                    svgClassName='w-35px h-35px'
                                                />
                                                <div className='d-flex flex-column'>
                                                    <div
                                                        className='text-cmGrey800'
                                                        style={{fontWeight: 600, fontSize: 14}}
                                                    >
                                                        Billing
                                                    </div>
                                                    <div
                                                        className='text-cmGrey600'
                                                        style={{fontWeight: 500}}
                                                    >
                                                        Update your billing details, Manage
                                                        subscriptions and more.
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className='dropdown-item d-flex gap-3 cursor-pointer  text-wrap w-100 py-3 px-7 bg-hover-cmGrey100'
                                                onClick={() => navigate('settings/system/emails')}
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/art/Blue-Email.svg'
                                                    className='cursor-pointer'
                                                    svgClassName='w-35px h-35px'
                                                />
                                                <div className='d-flex flex-column'>
                                                    <div
                                                        className='text-cmGrey800'
                                                        style={{fontWeight: 600, fontSize: 14}}
                                                    >
                                                        Email Settings
                                                    </div>
                                                    <div
                                                        className='text-cmGrey600'
                                                        style={{fontWeight: 500}}
                                                    >
                                                        Set up and manage email templates and
                                                        preferences
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Show.When>
                        </Show>
                    </>

                    {basedOnHost?.loginAsUser && userData?.through == HIDDEN_SUPER_ADMIN ? (
                        <div>
                            <CustomTooltip title='Switch to Admin'>
                                <i
                                    className='fa fa-solid fa-people-arrows fs-3 ms-1 text-sqPrimary500 cursor-pointer'
                                    style={{
                                        fontSize: 15,
                                    }}
                                    onClick={onSwitchBackToAdmin}
                                />
                            </CustomTooltip>
                        </div>
                    ) : null}
                </div>
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div
                    className={clsx('cursor-pointer symbol', userAvatarClass)}
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    <CustomUserAvatar
                        image={userData?.employee_image_s3}
                        margin='0'
                        style={{width: '32px', height: '32px'}}
                        username={`${userData?.first_name} ${userData?.last_name}`}
                        positionData={userData}
                    />
                </div>
                <HeaderUserMenu
                    open={open}
                    handleClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                />
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div
                    className='text-sm-medium text-sqGray600'
                    data-kt-search-element='toggle'
                    id='kt_header_search_toggle'
                >
                    <span className='text-xs-medium text-sqGray500 me-1'>Hi,</span>
                    {userData?.first_name}
                </div>
            </div>
        </div>
    )
}

export {Navbar}

export const SwitchRoleUI = ({title, activeRole, handleUser}) => {
    return (
        <div
            onClick={() => {
                if (activeRole === ROLES.standard.roleValue) {
                    handleUser(ROLES.administrator.roleValue)
                } else if (activeRole == ROLES.administrator.roleValue) {
                    handleUser(ROLES.standard.roleValue)
                }
            }}
            className='d-flex flex-row cursor-pointer gap-10px'
        >
            <Show>
                <Show.When isVisible={activeRole === ROLES.standard.roleValue}>
                    <KTSVG
                        path='/media/icons/duotune/art/toggle-right.svg'
                        svgClassName='w-20px h-20px'
                        svgStyle={{marginTop: 2}}
                    />
                </Show.When>
                <Show.Else>
                    <KTSVG
                        svgStyle={{marginTop: 2}}
                        path='/media/icons/duotune/art/toggle-left.svg'
                        svgClassName='w-20px h-20px'
                    />
                </Show.Else>
            </Show>

            <CustomButton2
                isLink
                buttonLabel={title}
                buttonSize={BUTTON_SIZE.sm}
                buttonType={BUTTON_TYPE.linkColor}
            />
        </div>
    )
}
