import React, {useState} from 'react'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomDatePicker from '../../../../../../customComponents/customInputs/customDatePicker/CustomDatePicker'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomDelete from '../../../../../../customComponents/customIcons/CustomDelete'
import CustomBadge from '../../../../../../customComponents/customBadge/CustomBadge'

const SetGameRulesSurvivor = () => {
    const [addRound, setAddRound] = useState([{id: 1}])

    const HandleAddRound = () => {
        setAddRound([...addRound, {id: addRound.length + 1}])
    }

    const removeRound = (indexToRemove) => {
        setAddRound((prevRounds) => prevRounds?.filter((_, index) => index !== indexToRemove))
    }
    return (
        <>
            <div className='py-32px'>
                <div className='mb-32px h-350px overflow-auto px-32px'>
                    <div className='d-flex align-items-center flex-row mb-32px'>
                        <label htmlFor='upload-image'>
                            <KTSVG
                                path='/media/icons/duotune/arena/survivor.svg'
                                // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                                svgClassName='w-50px h-50px cursor-pointer'
                            />
                        </label>

                        <div className='d-flex flex-column ps-3'>
                            <span className='mb-1 text-md-bold text-sqGray900'>
                                Play to Survive
                            </span>
                            <div className='d-flex gap-8px'>
                                <div>
                                    <CustomBadge
                                        label={'Champion'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                                <div>
                                    <CustomBadge
                                        label={'Individuals'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                                <div>
                                    <CustomBadge
                                        label={'105 Participants'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='text-md-semibold text-sqGray900 mb-32px'>
                            Set Game Rules
                        </div>

                        <div className='text-sm-bold text-sqGray900 mb-18px'>Sold As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'Setter'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Closer'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='text-sm-bold text-sqGray900 mb-18px'>Installed As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'Setter'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Closer'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='text-sm-bold text-sqGray900 mb-18px'>KW Sold as</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'Setter'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Closer'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='text-sm-bold text-sqGray900 mb-18px'>KW Installed As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'Setter'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Closer'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='row mb-32px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'New Hiring Leads'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'New Recruit Hired'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Hours Worked'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Days On-Time'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='text-sm-bold text-sqGray900 mb-32px'>
                            Competition Duration
                        </div>
                        <div className='row gap-2 mb-32px'>
                            <div className='col-sm'>
                                <CustomDropdown
                                    id='Manage_Leads_Home_Location_Input'
                                    label={'Competition Days'}
                                    valueKey='id'
                                    placeholder={'Select Days'}
                                    // options={allState}
                                    // value={homestate}
                                    // onChange={(e) => setHomeState(e.target.value)}
                                    errorMessage={''}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomDropdown
                                    id='Manage_Leads_Home_Location_Input'
                                    label={'Duration'}
                                    valueKey='id'
                                    placeholder={'Select Duration'}
                                    // options={allState}
                                    // value={homestate}
                                    // onChange={(e) => setHomeState(e.target.value)}
                                    errorMessage={''}
                                />
                            </div>
                        </div>

                        <div className='text-md-semibold text-sqGray900 mb-8px'>Set Game Rules</div>
                        <div className='text-sm-medium sqGray600 mb-32px'>
                            Players above the cutoff will win, players below the cutoff will be
                            eliminated.
                        </div>

                        <div className='text-sm-bold text-sqGray900 mb-18px'>Round 1</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm d-flex flex-column'>
                                <label
                                    className='text-sm-medium text-cmGrey700 mb-2'
                                    style={{fontWeight: 500}}
                                >
                                    Start Date
                                </label>
                                <CustomDatePicker
                                    id={'One_time_Payment_Start_Date_Input_1'}
                                    placeholderText={'Start Date'}
                                    name='startDate'
                                    // value={startDate}
                                    // onChange={(event) => {
                                    //     setStartDate(event.target.value)
                                    // }}
                                    // maxDate={customMoment.todayDate()}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'No. of days'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='row mb-32px gap-2'>
                            <div className='col-sm d-flex flex-column'>
                                <CustomDropdown
                                    id='Manage_Leads_Home_Location_Input'
                                    label={'Cutoff / Win Criteria '}
                                    valueKey='id'
                                    // options={allState}
                                    // value={homestate}
                                    // onChange={(e) => setHomeState(e.target.value)}
                                    errorMessage={''}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'Points Required'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>

                        {addRound?.map((item, index) => (
                            <>
                                <div className='d-flex align-items-center mb-32px'>
                                    <div className='text-sm-bold text-sqGray900 flex-center'>
                                        Round {index + 2}
                                    </div>
                                    <div className='ms-auto'>
                                        <CustomDelete
                                            svgClassName={`w-32px h-32px`}
                                            onClick={() => removeRound(index)}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-18px gap-2'>
                                    <div className='col-sm d-flex flex-column'>
                                        <label
                                            className='text-sm-medium text-cmGrey700 mb-2'
                                            style={{fontWeight: 500}}
                                        >
                                            Start Date
                                        </label>
                                        <CustomDatePicker
                                            id={'One_time_Payment_Start_Date_Input_1'}
                                            placeholderText={'Start Date'}
                                            name='startDate'
                                            // value={startDate}
                                            // onChange={(event) => {
                                            //     setStartDate(event.target.value)
                                            // }}
                                            // maxDate={customMoment.todayDate()}
                                        />
                                    </div>
                                    <div className='col-sm'>
                                        <CustomInput
                                            label={'No. of days'}
                                            id='Manage_Leads_First_Name_Input'
                                            placeholder='0'
                                            // onChange={(e) => {
                                            //     setFirstName(e.target.value)
                                            // }}
                                            // errorMessage={referalFormError?.firstName}
                                            // value={firstname}
                                            // rejex={/^[\w\-\s]+$/}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-32px gap-2'>
                                    <div className='col-sm d-flex flex-column'>
                                        <CustomDropdown
                                            id='Manage_Leads_Home_Location_Input'
                                            label={'Cutoff / Win Criteria '}
                                            valueKey='id'
                                            // options={allState}
                                            // value={homestate}
                                            // onChange={(e) => setHomeState(e.target.value)}
                                            errorMessage={''}
                                        />
                                    </div>
                                    <div className='col-sm'>
                                        <CustomInput
                                            label={'Points Required'}
                                            id='Manage_Leads_First_Name_Input'
                                            placeholder='0'
                                            // onChange={(e) => {
                                            //     setFirstName(e.target.value)
                                            // }}
                                            // errorMessage={referalFormError?.firstName}
                                            // value={firstname}
                                            // rejex={/^[\w\-\s]+$/}
                                        />
                                    </div>
                                </div>
                            </>
                        ))}
                        <div className='d-flex gap-2'>
                            <div>
                                <KTSVG
                                    path='/media/icons/duotune/arena/plus-square.svg'
                                    className='cursor-pointer mb-2'
                                    svgClassName='w-20px h-20px'
                                    onClick={HandleAddRound}
                                />
                            </div>
                            <div>
                                <span className='text-sm-semibold text-sqPrimary600'>
                                    Add Another Round
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-32px'>
                    <CustomButton2
                        buttonLabel='Next (Set Game Rules)'
                        buttonSize={BUTTON_SIZE.xl}
                        buttonType={BUTTON_TYPE.primary}
                        width={'100'}
                    />
                </div>
            </div>
        </>
    )
}

export default SetGameRulesSurvivor
