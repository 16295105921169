import React, {useState} from 'react'
import CustomInput, {
    CommonLabel,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomBadge from '../../../../../../customComponents/customBadge/CustomBadge'

const SetRewardsPerformance = () => {
    const [addRow, setAddRow] = useState([{id: 1}])

    const addMilestone = () => {
        setAddRow([...addRow, {id: addRow.length + 1}])
    }

    return (
        <div className='py-32px'>
            <div className='mb-32px h-310px overflow-auto px-32px'>
                <div className='d-flex align-items-center flex-md-row mb-32px'>
                    <label htmlFor='upload-image'>
                        <KTSVG
                            path='/media/icons/duotune/arena/performance.svg'
                            // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                            svgClassName='w-50px h-50px cursor-pointer'
                        />
                    </label>

                    <div className='d-flex flex-column align-items-center align-items-sm-start ps-sm-3 mt-3 mt-sm-0'>
                        <span className='mb-1 text-md-bold text-sqGray900 text-center text-sm-start'>
                            Lead the Pack and Win it All!
                        </span>
                        <div className='d-flex flex-wrap justify-content-center justify-content-sm-start gap-8px'>
                            <CustomBadge
                                label={'Champion'}
                                textColor='sqGray700'
                                fontWeight={700}
                                fontSize={12}
                                px={8}
                                py={2}
                                backgroundColor={'sqGray100'}
                                opacity={false}
                                rounded={true}
                            />
                            <CustomBadge
                                label={'Individuals'}
                                textColor='sqGray700'
                                fontWeight={700}
                                fontSize={12}
                                px={8}
                                py={2}
                                backgroundColor={'sqGray100'}
                                opacity={false}
                                rounded={true}
                            />
                            <CustomBadge
                                label={'64 Participants'}
                                textColor='sqGray700'
                                fontWeight={700}
                                fontSize={12}
                                px={8}
                                py={2}
                                backgroundColor={'sqGray100'}
                                opacity={false}
                                rounded={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='text-md-semibold text-sqGray900 mb-32px'>Set Reward List</div>

                <div className=''>
                    <div className='mb-18px'>
                        <div className='text-md-medium text-sqGray900 mb-18px'>Round 1- 10days</div>
                        <div className='row gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    prefixText='$'
                                    label={'Reward'}
                                    id={`reward`}
                                    placeholder='0'
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomDropdown
                                    label={'Select Reward Type'}
                                    id='Missing_Info_Customer_State_Input'
                                    // value={missingData?.customer_state}
                                    // onChange={onChangeInputData}
                                    // options={allLocationsList}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mb-18px'>
                        <div className='text-md-medium text-sqGray900 mb-18px'>Round 2 - 5days</div>
                        <div className='row gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    helpText={'Help text'}
                                    label={'Reward'}
                                    id={`reward`}
                                    placeholder='0'
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomDropdown
                                    label={'Select Reward Type'}
                                    id='Missing_Info_Customer_State_Input'
                                    // value={missingData?.customer_state}
                                    // onChange={onChangeInputData}
                                    // options={allLocationsList}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mb-18px'>
                        <div className='text-md-medium text-sqGray900 mb-18px'>Round 3- 10days</div>
                        <div className='row gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    prefixText='$'
                                    label={'Reward'}
                                    id={`reward`}
                                    placeholder='0'
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomDropdown
                                    label={'Select Reward Type'}
                                    id='Missing_Info_Customer_State_Input'
                                    // value={missingData?.customer_state}
                                    // onChange={onChangeInputData}
                                    // options={allLocationsList}
                                />
                            </div>
                        </div>
                    </div>

                    {addRow?.map((item, index) => (
                        <div className='mb-18px'>
                            <div className='text-md-medium text-sqGray900 mb-18px'>
                                Round {index + 4} - 10days
                            </div>
                            <div className='row gap-2'>
                                <div className='col-sm'>
                                    <CustomInput
                                        prefixText='$'
                                        label={'Reward'}
                                        id={`reward`}
                                        placeholder='0'
                                    />
                                </div>
                                <div className='col-sm'>
                                    <CustomDropdown
                                        label={'Select Reward Type'}
                                        id='Missing_Info_Customer_State_Input'
                                        // value={missingData?.customer_state}
                                        // onChange={onChangeInputData}
                                        // options={allLocationsList}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='d-flex mt-32px gap-2'>
                    <div>
                        <KTSVG
                            path='/media/icons/duotune/arena/plus-square.svg'
                            className='cursor-pointer mb-2'
                            svgClassName='w-20px h-20px'
                            onClick={addMilestone}
                        />
                    </div>
                    <div>
                        <span className='text-sm-semibold text-sqPrimary600'>Add Reward</span>
                    </div>
                </div>
            </div>
            <div className='px-32px'>
                <div className='mb-18px'>
                    <CustomButton2
                        buttonLabel='Preview Game Map'
                        buttonSize={BUTTON_SIZE.xl}
                        buttonType={BUTTON_TYPE.secondaryColor}
                        width={'100'}
                        // onClick={() => setViewParticipants(true)}
                    />
                </div>
                <CustomButton2
                    buttonLabel='Save and Publish'
                    buttonSize={BUTTON_SIZE.xl}
                    buttonType={BUTTON_TYPE.primary}
                    width={'100'}
                />
            </div>
        </div>
    )
}

export default SetRewardsPerformance
