import React, {useState} from 'react'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomDatePicker from '../../../../../../customComponents/customInputs/customDatePicker/CustomDatePicker'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomBadge from '../../../../../../customComponents/customBadge/CustomBadge'

const SetGameChampion = () => {
    const [image, setImage] = useState(null)

    return (
        <>
            <div className='py-32px'>
                <div className='mb-32px h-375px overflow-auto px-32px'>
                    <div className='d-flex align-items-center flex-row mb-32px'>
                        <label htmlFor='upload-image cursor-pointer'>
                            <KTSVG
                                path='/media/icons/duotune/arena/champion.svg'
                                // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                                svgClassName='w-50px h-50px cursor-pointer'
                            />
                        </label>

                        <div className='d-flex flex-column ps-3'>
                            <h6 className='mb-1 text-md-bold text-sqGray900'>
                                March Sales Competition
                            </h6>
                            <div className='d-flex gap-8px'>
                                <div>
                                    <CustomBadge
                                        label={'Ladder'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                                <div>
                                    <CustomBadge
                                        label={'Individuals'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                                <div>
                                    <CustomBadge
                                        label={'105 Participants'}
                                        textColor='sqGray700'
                                        fontWeight={700}
                                        fontSize={12}
                                        px={8}
                                        py={2}
                                        backgroundColor={'sqGray100'}
                                        opacity={false}
                                        rounded={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='text-md-semibold text-sqGray900 mb-32px'>
                            Set Game Rules
                        </div>

                        <div className='text-sm-bold text-sqGray900 mb-18px'>Sold As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'Setter'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Closer'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='text-sm-bold text-sqGray900 mb-18px'>Installed As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'Setter'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Closer'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='text-sm-bold text-sqGray900 mb-18px'>KW Sold as</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'Setter'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Closer'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='text-sm-bold text-sqGray900 mb-18px'>KW Installed As</div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'Setter'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Closer'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Self-Gen'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='row mb-32px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    label={'New Hiring Leads'}
                                    id='Manage_Leads_First_Name_Input'
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setFirstName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.firstName}
                                    // value={firstname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'New Recruit Hired'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Hours Worked'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomInput
                                    id='Manage_Leads_First_Last_Input'
                                    label={'Days On-Time'}
                                    placeholder='0'
                                    // onChange={(e) => {
                                    //     setLastName(e.target.value)
                                    // }}
                                    // errorMessage={referalFormError?.lastName}
                                    // value={lastname}
                                    // rejex={/^[\w\-\s]+$/}
                                />
                            </div>
                        </div>
                        <div className='text-sm-bold text-sqGray900 mb-32px'>
                            Competition Duration
                        </div>
                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomDropdown
                                    id='Manage_Leads_Home_Location_Input'
                                    label={'Competition Days'}
                                    valueKey='id'
                                    // options={allState}
                                    // value={homestate}
                                    // onChange={(e) => setHomeState(e.target.value)}
                                    errorMessage={''}
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomDropdown
                                    id='Manage_Leads_Home_Location_Input'
                                    label={'Duration'}
                                    valueKey='id'
                                    // options={allState}
                                    // value={homestate}
                                    // onChange={(e) => setHomeState(e.target.value)}
                                    errorMessage={''}
                                />
                            </div>
                        </div>
                        <div className='row align-items-center gap-2'>
                            <div className='col-sm d-flex flex-column'>
                                <label
                                    className='text-sm-medium text-cmGrey700 mb-2'
                                    style={{fontWeight: 500}}
                                >
                                    Start Date
                                </label>
                                <CustomDatePicker
                                    id={'One_time_Payment_Start_Date_Input_1'}
                                    placeholderText={'Start Date'}
                                    name='startDate'
                                    // value={startDate}
                                    // onChange={(event) => {
                                    //     setStartDate(event.target.value)
                                    // }}
                                    // maxDate={customMoment.todayDate()}
                                />
                            </div>

                            <div className='col-sm d-flex flex-column'>
                                <label
                                    className='text-sm-medium text-cmGrey700 mb-2'
                                    style={{fontWeight: 500}}
                                >
                                    End Date
                                </label>
                                <CustomDatePicker
                                    id={'One_time_Payment_Start_Date_Input_2'}
                                    placeholderText={'Start Date'}
                                    name='startDate'
                                    // value={startDate}
                                    // onChange={(event) => {
                                    //     setStartDate(event.target.value)
                                    // }}
                                    // maxDate={customMoment.todayDate()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-32px'>
                    <CustomButton2
                        buttonLabel='Next (Set Game Rules)'
                        buttonSize={BUTTON_SIZE.xl}
                        buttonType={BUTTON_TYPE.primary}
                        width={'100'}

                        // onClick={() => setOpen(true)}
                        // width={'225px'}
                    />
                </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default SetGameChampion
