import React, {useState} from 'react'
import CustomInput, {
    CommonLabel,
} from '../../../../../../customComponents/customInputs/customInput/CustomInput'
import CustomCheckbox from '../../../../../../customComponents/customCheckbox/CustomCheckbox'
import {KTSVG} from '../../../../../../_metronic/helpers'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../../customComponents/customButtton/CustomButton2'
import CustomDropdown from '../../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomDelete from '../../../../../../customComponents/customIcons/CustomDelete'
import CustomBadge from '../../../../../../customComponents/customBadge/CustomBadge'

const SetRewardsChampion = () => {
    const [milestones, setMilestones] = useState([{id: 1}])

    const addMilestone = () => {
        setMilestones([...milestones, {id: milestones.length + 1}])
    }
    return (
        <div className='py-32px'>
            <div className='mb-32px h-310px overflow-auto px-32px'>
                <div className='d-flex align-items-center flex-row mb-32px'>
                    <label htmlFor='upload-image cursor-pointer'>
                        <KTSVG
                            path='/media/icons/duotune/arena/champion.svg'
                            // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                            svgClassName='w-50px h-50px cursor-pointer'
                        />
                    </label>

                    <div className='d-flex flex-column align-items-center align-items-sm-start ps-sm-3 mt-3 mt-sm-0'>
                        <span className='mb-1 text-md-bold text-sqGray900 text-center text-sm-start'>
                            Be the Champion
                        </span>
                        <div className='d-flex flex-wrap justify-content-center justify-content-sm-start gap-8px'>
                            <CustomBadge
                                label={'Ladder'}
                                textColor='sqGray700'
                                fontWeight={700}
                                fontSize={12}
                                px={8}
                                py={2}
                                backgroundColor={'sqGray100'}
                                opacity={false}
                                rounded={true}
                            />

                            <CustomBadge
                                label={'Individuals'}
                                textColor='sqGray700'
                                fontWeight={700}
                                fontSize={12}
                                px={8}
                                py={2}
                                backgroundColor={'sqGray100'}
                                opacity={false}
                                rounded={true}
                            />
                            <CustomBadge
                                label={'105 Participants'}
                                textColor='sqGray700'
                                fontWeight={700}
                                fontSize={12}
                                px={8}
                                py={2}
                                backgroundColor={'sqGray100'}
                                opacity={false}
                                rounded={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='text-md-semibold text-sqGray900 mb-32px'>Set Reward List</div>

                <div className=''>
                    <div className='mb-32px'>
                        <div className='text-md-medium mb-18px'>
                            <KTSVG
                                path='/media/icons/duotune/arena/Place1.svg'
                                svgClassName='w-24px h-24px'
                            />
                            1st Place
                        </div>

                        <div className='row mb-18px gap-2'>
                            <div className='col-sm'>
                                <CustomInput
                                    prefixText={'$'}
                                    label={'Reward'}
                                    id={`reward`}
                                    placeholder='0'
                                />
                            </div>
                            <div className='col-sm'>
                                <CustomDropdown
                                    id='Manage_Leads_Home_Location_Input'
                                    label={'Select Reward Type'}
                                    valueKey='id'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mb-32px'>
                        <div className='text-md-medium mb-18px'>
                            <KTSVG
                                path='/media/icons/duotune/arena/Place2.svg'
                                // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                                svgClassName='w-24px h-24px'
                            />
                            2st Place
                        </div>
                        <div className='row gap-2'>
                            <div className='col-sm'>
                                <CustomInput prefixText={'$'} label={'Reward'} id={`reward`} />
                            </div>
                            <div className='col-sm d-flex align-items-center gap-24px h-100'>
                                <div className='flex-grow-1'>
                                    {/* <CustomDropdown
                                        label={'Select Reward Type'}
                                        id='Missing_Info_Customer_State_Input'
                                        placeholder={''}
                                    /> */}
                                    <CustomDropdown
                                        label={'Select Reward Type'}
                                        // options={allState}
                                        // value={homestate}
                                        // onChange={(e) => setHomeState(e.target.value)}
                                    />
                                </div>
                                <div className='d-flex align-items-center mt-11'>
                                    <CustomDelete />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mb-32px'>
                        <div className='text-md-medium mb-18px'>
                            <KTSVG
                                path='/media/icons/duotune/arena/Place3.svg'
                                // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                                svgClassName='w-24px h-24px'
                            />
                            3st Place
                        </div>
                        <div className='row gap-2'>
                            <div className='col-sm'>
                                <CustomInput prefixText={'$'} label={'Reward'} id={`reward`} />
                            </div>
                            <div className='col-sm d-flex align-items-center gap-24px h-100'>
                                <div className='flex-grow-1'>
                                    <CustomDropdown
                                        label={'Select Reward Type'}
                                        id='Missing_Info_Customer_State_Input'
                                    />
                                </div>
                                <div className='d-flex align-items-center mt-11'>
                                    <CustomDelete />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-32px'>
                        <div className='text-md-medium mb-18px'>
                            <KTSVG
                                path='/media/icons/duotune/arena/place4.svg'
                                // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                                svgClassName='w-24px h-24px'
                            />
                            4st Place
                        </div>
                        <div className='row gap-2'>
                            <div className='col-sm'>
                                <CustomInput prefixText={'$'} label={'Reward'} id={`reward`} />
                            </div>
                            <div className='col-sm d-flex align-items-center gap-24px h-100'>
                                <div className='flex-grow-1'>
                                    <CustomDropdown
                                        label={'Select Reward Type'}
                                        id='Missing_Info_Customer_State_Input'
                                    />
                                </div>
                                <div className='d-flex align-items-center mt-11'>
                                    <CustomDelete />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {milestones?.map((item, index) => (
                        <div className='mb-7'>
                            <div className='text-md-medium mb-3'>
                                <KTSVG
                                    path='/media/icons/duotune/arena/Place2.svg'
                                    // className='me-3 svg-icon-6 svg-icon-muted me-1 w-20 h-20'
                                    svgClassName='w-24px h-24px'
                                />
                                {index + 1}st Place
                            </div>
                            <div className='row mb-3 gap-2'>
                                <div className='col-sm'>
                                    <CustomInput label={'Reward'} id={`reward`} placeholder='0' />
                                </div>
                                <div className='col-sm d-flex align-items-center gap-8 h-100'>
                                    <div className='flex-grow-1'>
                                        <CustomDropdown
                                            label={'Select Reward Type'}
                                            id='Missing_Info_Customer_State_Input'
                                        />
                                    </div>
                                    <div className='d-flex align-items-center mt-11'>
                                        <CustomDelete />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))} */}
                </div>
                <div className='d-flex gap-2'>
                    <div>
                        <KTSVG
                            path='/media/icons/duotune/arena/plus-square.svg'
                            className='cursor-pointer mb-2'
                            svgClassName='w-20px h-20px'
                            // onClick={addMilestone}
                        />
                    </div>
                    <div>
                        <span className='text-sm-semibold text-sqPrimary600'>Add Reward</span>
                    </div>
                </div>
            </div>
            <div className='px-32px'>
                <div className='mb-18px'>
                    <CustomButton2
                        buttonLabel='Preview Game Map'
                        buttonSize={BUTTON_SIZE.xl}
                        buttonType={BUTTON_TYPE.secondaryColor}
                        width={'100'}
                        // onClick={() => setViewParticipants(true)}
                    />
                </div>
                <CustomButton2
                    buttonLabel='Save and Publish'
                    buttonSize={BUTTON_SIZE.xl}
                    buttonType={BUTTON_TYPE.primary}
                    width={'100'}
                />
            </div>
        </div>
    )
}

export default SetRewardsChampion
