import './styles.css'
import {useCallback, useEffect, useState} from 'react'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../customComponents/customInputs/customInput/CustomInput'
import {
    getErrorMessageFromResponse,
    getMobileNumberWithoutMask,
} from '../../../../helpers/CommonHelpers'
import CustomButton2, {
    BUTTON_TYPE,
    BUTTON_SIZE,
} from '../../../../customComponents/customButtton/CustomButton2'
import {adminSetupValidation} from '../../../../validations/validations'
import useValidation from '../../../../hooks/useValidation'
import {setupTenantAdminService, updateTenantAdminSetUpService} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'

const AdminInformation = ({
    tabActive,
    onTabChange,
    tenantId,
    adminInformationData,
    setAdminInformationData,
    completeStep,
    location,
}) => {
    const [loading, setLoading] = useState(false)
    const [validateAdminInformation, adminErrorData] = useValidation()

    useEffect(() => {
        if (adminErrorData?.beginValidating) {
            validateAdminInformation(adminSetupValidation(adminInformationData))
        }
    }, [adminInformationData])

    const updateAdminInformationData = (e) => {
        setAdminInformationData((val) => ({
            ...adminInformationData,
            [e?.target?.name]: e?.target?.value,
        }))
    }

    const onAdminSetupSave = useCallback(() => {
        const emails = [
            adminInformationData?.companyOwnerEmail,
            adminInformationData?.superAdmin1Email,
            adminInformationData?.superAdmin2Email,
            adminInformationData?.superAdmin3Email,
        ].filter((email) => email)

        if (new Set(emails).size !== emails.length) {
            CustomToast.error('Duplicate email found!')
            return
        }

        validateAdminInformation(adminSetupValidation(adminInformationData)).then((res) => {
            if (res?.isValidate) {
                let admin_users_data = [
                    {
                        full_name: adminInformationData?.companyOwnerName,
                        email: adminInformationData?.companyOwnerEmail,
                        mobile_no: getMobileNumberWithoutMask(
                            adminInformationData?.companyOwnerPhno
                        ),
                        is_owener: 1,
                        ...(location?.state?.tid && {
                            id: adminInformationData?.userDataId,
                            is_super_admin: 1,
                        }),
                    },
                ]
                if (adminInformationData?.superAdmin1Name) {
                    admin_users_data.push({
                        full_name: adminInformationData?.superAdmin1Name,
                        email: adminInformationData?.superAdmin1Email,
                        mobile_no: getMobileNumberWithoutMask(
                            adminInformationData?.superAdmin1Phno
                        ),
                        is_owener: 0,
                        ...(location?.state?.tid && {
                            id: adminInformationData?.userDataId1,
                            is_super_admin: 1,
                        }),
                    })
                }
                if (adminInformationData?.superAdmin2Name) {
                    admin_users_data.push({
                        full_name: adminInformationData?.superAdmin2Name,
                        email: adminInformationData?.superAdmin2Email,
                        mobile_no: getMobileNumberWithoutMask(
                            adminInformationData?.superAdmin2Phno
                        ),
                        is_owener: 0,
                        ...(location?.state?.tid && {
                            id: adminInformationData?.userDataId2,
                            is_super_admin: 1,
                        }),
                    })
                }
                if (adminInformationData?.superAdmin3Name) {
                    admin_users_data.push({
                        full_name: adminInformationData?.superAdmin3Name,
                        email: adminInformationData?.superAdmin3Email,
                        mobile_no: getMobileNumberWithoutMask(
                            adminInformationData?.superAdmin3Phno
                        ),
                        is_owener: 0,
                        ...(location?.state?.tid && {
                            id: adminInformationData?.userDataId3,
                            is_super_admin: 1,
                        }),
                    })
                }
                const data = {
                    tenant_id: location?.state?.tid || tenantId,
                    admin_users_data,
                }
                setLoading(true)
                if (location?.state?.tid) {
                    updateTenantAdminSetUpService(data)
                        .then((res) => {
                            onTabChange(tabActive + 1)
                            CustomToast.success(res?.message)
                            completeStep()
                            const formData = {
                                currentStep: tabActive + 1,
                                tenantId: location?.state?.tid || tenantId,
                                stepCompletion: [true, true],
                            }
                            localStorage.setItem('currentStep', JSON.stringify(formData))
                        })
                        .catch((e) => CustomToast.error(getErrorMessageFromResponse(e)))
                        .finally(() => setLoading(false))
                } else {
                    setupTenantAdminService(data)
                        .then((res) => {
                            onTabChange(tabActive + 1)
                            CustomToast.success(res?.message)
                            completeStep()
                            const formData = {
                                currentStep: tabActive + 1,
                                tenantId: location?.state?.tid || tenantId,
                                stepCompletion: [true, true],
                            }
                            localStorage.setItem('currentStep', JSON.stringify(formData))
                        })
                        .catch((e) => CustomToast.error(getErrorMessageFromResponse(e)))
                        .finally(() => setLoading(false))
                }
            }
        })
    }, [adminInformationData])

    return (
        <div style={{position: 'relative'}}>
            <CustomLoader visible={loading} full />
            <div className='text-xl-bold text-sqGray900 mb-6 '>Administrative Setup</div>
            {/*  ------- Company Owner ----- */}
            <div>
                <div
                    className={`badge rounded-pill text-sqBlue700 bg-sqBlue700 bg-opacity-10 text-sm-bold px-4 py-2 mb-4`}
                    style={{fontWeight: 700, fontSize: 14}}
                >
                    Company Owner
                </div>

                <div className='container'>
                    <div className='row mb-5'>
                        <div className='col-sm'>
                            <CustomInput
                                required
                                label={'Full Name'}
                                placeholder={'Enter Name'}
                                name='companyOwnerName'
                                onChange={updateAdminInformationData}
                                value={adminInformationData?.companyOwnerName}
                                errorMessage={adminErrorData?.companyOwnerName}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                required
                                type={INPUT_TYPE.email}
                                placeholder={'Enter Email'}
                                label={'Email'}
                                name='companyOwnerEmail'
                                onChange={updateAdminInformationData}
                                value={adminInformationData?.companyOwnerEmail}
                                errorMessage={adminErrorData?.companyOwnerEmail}
                            />
                        </div>
                        <div className='col-sm'>
                            <div className='d-flex gap-4 align-items-center'>
                                <CustomInput
                                    required
                                    type={INPUT_TYPE.mobile}
                                    placeholder={'Enter Phone Number'}
                                    label={'Phone Number'}
                                    name='companyOwnerPhno'
                                    onChange={updateAdminInformationData}
                                    value={adminInformationData?.companyOwnerPhno}
                                    errorMessage={adminErrorData?.companyOwnerPhno}
                                />

                                <div
                                    className={`bi bi-check-circle text-success fs-1 mt-5 opacity-${
                                        getMobileNumberWithoutMask(
                                            adminInformationData?.companyOwnerPhno
                                        )?.length == 10
                                            ? 1
                                            : 0
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  -------  Super Admin ----- */}
            <div>
                <div className='d-flex align-items-center my-4'>
                    <div
                        className={`badge rounded-pill text-sqSuccess700 bg-sqSuccess700 bg-opacity-10 text-sm-bold px-4 py-2`}
                        style={{fontWeight: 700, fontSize: 14}}
                    >
                        Super Admin
                    </div>
                    <div className='mx-2 text-sm-bold text-sqGray600'> (max 3)</div>
                </div>

                <div className='container'>
                    <div className='row mb-5'>
                        <div className='col-sm'>
                            <CustomInput
                                label={'Full Name'}
                                placeholder={'Enter Name'}
                                name='superAdmin1Name'
                                onChange={updateAdminInformationData}
                                value={adminInformationData?.superAdmin1Name}
                                errorMessage={adminErrorData?.superAdmin1Name}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                type={INPUT_TYPE.email}
                                placeholder={'Enter Email'}
                                label={'Email'}
                                name='superAdmin1Email'
                                onChange={updateAdminInformationData}
                                value={adminInformationData?.superAdmin1Email}
                                errorMessage={adminErrorData?.superAdmin1Email}
                            />
                        </div>
                        <div className='col-sm'>
                            <div className='mb-5 d-flex gap-4 align-items-center'>
                                <CustomInput
                                    type={INPUT_TYPE.mobile}
                                    placeholder={'Enter Phone Number'}
                                    label={'Phone Number'}
                                    name='superAdmin1Phno'
                                    onChange={updateAdminInformationData}
                                    value={adminInformationData?.superAdmin1Phno}
                                    errorMessage={adminErrorData?.superAdmin1Phno}
                                />
                                <div
                                    className={`bi bi-check-circle text-success fs-1 mb-1 mt-5 opacity-${
                                        getMobileNumberWithoutMask(
                                            adminInformationData?.superAdmin1Phno
                                        )?.length == 10
                                            ? 1
                                            : 0
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-sm'>
                            <CustomInput
                                label={'Full Name'}
                                placeholder={'Enter Name'}
                                name='superAdmin2Name'
                                onChange={updateAdminInformationData}
                                value={adminInformationData?.superAdmin2Name}
                                errorMessage={adminErrorData?.superAdmin2Name}
                            />
                        </div>

                        <div className='col-sm'>
                            <CustomInput
                                type={INPUT_TYPE.email}
                                placeholder={'Enter Email'}
                                label={'Email'}
                                name='superAdmin2Email'
                                onChange={updateAdminInformationData}
                                value={adminInformationData?.superAdmin2Email}
                                errorMessage={adminErrorData?.superAdmin2Email}
                            />
                        </div>
                        <div className='col-sm'>
                            <div className='d-flex gap-4 align-items-center'>
                                <CustomInput
                                    type={INPUT_TYPE.mobile}
                                    placeholder={'Enter Phone Number'}
                                    label={'Phone Number'}
                                    name='superAdmin2Phno'
                                    onChange={updateAdminInformationData}
                                    value={adminInformationData?.superAdmin2Phno}
                                    errorMessage={adminErrorData?.superAdmin2Phno}
                                />
                                <div
                                    className={`bi bi-check-circle text-success fs-1 mb-1 mt-5 opacity-${
                                        getMobileNumberWithoutMask(
                                            adminInformationData?.superAdmin2Phno
                                        )?.length == 10
                                            ? 1
                                            : 0
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-sm'>
                            <CustomInput
                                label={'Full Name'}
                                placeholder={'Enter Name'}
                                name='superAdmin3Name'
                                onChange={updateAdminInformationData}
                                value={adminInformationData?.superAdmin3Name}
                                errorMessage={adminErrorData?.superAdmin3Name}
                            />
                        </div>
                        <div className='col-sm'>
                            <CustomInput
                                type={INPUT_TYPE.email}
                                placeholder={'Enter Email'}
                                label={'Email'}
                                name='superAdmin3Email'
                                onChange={updateAdminInformationData}
                                value={adminInformationData?.superAdmin3Email}
                                errorMessage={adminErrorData?.superAdmin3Email}
                            />
                        </div>
                        <div className='col-sm'>
                            <div className='d-flex gap-4 align-items-center'>
                                <CustomInput
                                    type={INPUT_TYPE.mobile}
                                    placeholder={'Enter Phone Number'}
                                    label={'Phone Number'}
                                    name='superAdmin3Phno'
                                    onChange={updateAdminInformationData}
                                    value={adminInformationData?.superAdmin3Phno}
                                    errorMessage={adminErrorData?.superAdmin3Phno}
                                />
                                <div
                                    className={`bi bi-check-circle text-success fs-1 mb-1 mt-5 opacity-${
                                        getMobileNumberWithoutMask(
                                            adminInformationData?.superAdmin3Phno
                                        )?.length == 10
                                            ? 1
                                            : 0
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-16 mx-2'>
                <CustomButton2
                    buttonLabel='Save & Continue'
                    buttonSize={BUTTON_SIZE.lg}
                    buttonType={BUTTON_TYPE.primary}
                    // width={'225px'}
                    onClick={onAdminSetupSave}
                />
            </div>
        </div>
    )
}

export default AdminInformation
