import {useCallback, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import CustomButton, {BUTTON_SIZE} from '../../../../customComponents/customButtton/CustomButton'
import {
    IMAGE_TYPE,
    TABLE_BORDER,
    formattedNumberFields,
    getErrorMessageFromResponse,
    isImageUrl,
    sendDataToReducer,
} from '../../../../helpers/CommonHelpers'
import Pagination from '../../admin/sequidocs/component/Pagination'
import {getValidDate, STRIPE_PAYMENT_STATUS} from '../../../../constants/constants'
import CustomLink from '../../../../customComponents/customButtton/CustomLink'
import CustomImage from '../../../../customComponents/customImage/CustomImage'
import CustomArrow, {ARROW_DIRECTION} from '../../../../customComponents/customIcons/CustomIcons'
import images from '../../../../assets/images'
import CustomBadge from '../../../../customComponents/customBadge/CustomBadge'
import CustomEllipsis from '../../../../customComponents/customEllipsis/CustomEllipsis'
import {Document, Page} from 'react-pdf'
import Show from '../../../../customComponents/show/Show'
import {FileIcon, defaultStyles} from 'react-file-icon'
import CustomCollapse from '../../../../customComponents/customCollapse/CustomCollapse'
import {
    getActiveUserTotalSalesService,
    getAdministrativeSetUpService,
    getBillingInvoiceService,
    getDocumentsListClientDirectoryService,
    getPaymentInvoiceService,
    getPaymentRecordsService,
    getTenantDetailsClientDirectoryService,
    getTenantModulesListService,
    putAdministrativeSetupMakeUserGroupService,
    putAdministrativeSetupSuspendUserService,
} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import CustomTableSkeleton from '../../../../customComponents/customSkeleton/CustomTableSkeleton'
import CustomCardSkeleton from '../../../../customComponents/customSkeleton/CustomCardSkeleton'
import CustomModal from '../../../../customComponents/customModal/CustomModal'
import ViewBillingInvoice from '../../common/navSettings/billing/ViewBillingInvoice'
import DocumentPreviewModal from '../../standard_manager/management/particularEmployee/compoents/documents/DocumentPreviewModal'
import {useSelector} from 'react-redux'
import {getMultitenantFilterDataSelector} from '../../../../redux/selectors/FilterSelectors'
import {useDispatch} from 'react-redux'
import {SET_MULTITENANT_FILTER_DATA} from '../../../../redux/reducers/FilterReducer'

const dummyData = [
    {
        product_count: 1,
        start_date: '2024-08-01',
        end_date: '2024-08-31',
        grand_total: 500,
        paid_status: 0,
        last_payment_message: null,
        invoice_no: 'S-1719815666',
        billing_date: '2024-08-31 00:00:00',
        is_overdue: false,
    },
    {
        product_count: 1,
        start_date: '2024-06-01',
        end_date: '2024-06-30',
        grand_total: 536.25,
        paid_status: 0,
        last_payment_message: null,
        invoice_no: 'S-1719815408',
        billing_date: '2024-06-30 00:00:00',
        is_overdue: true,
    },
    {
        product_count: 1,
        start_date: '2024-05-01',
        end_date: '2024-05-31',
        grand_total: 536.25,
        paid_status: 1,
        last_payment_message: null,
        invoice_no: 'S-1717223408',
        billing_date: '2024-05-31 00:00:00',
        is_overdue: false,
    },
    {
        product_count: 1,
        start_date: '2024-04-01',
        end_date: '2024-04-30',
        grand_total: 1072.5,
        paid_status: 1,
        last_payment_message: null,
        invoice_no: 'S-1714545007',
        billing_date: '2024-04-30 00:00:00',
        is_overdue: false,
    },
    {
        product_count: 1,
        start_date: '2024-03-01',
        end_date: '2024-03-31',
        grand_total: 1072.5,
        paid_status: 1,
        last_payment_message: null,
        invoice_no: 'S-1711953007',
        billing_date: '2024-03-31 00:00:00',
        is_overdue: false,
    },
    {
        product_count: 1,
        start_date: '2024-02-01',
        end_date: '2024-02-29',
        grand_total: 1480.48,
        paid_status: 1,
        last_payment_message: null,
        invoice_no: 'S-1709912412',
        billing_date: '2024-02-29 00:00:00',
        is_overdue: false,
    },
]
const CompanyOverviewPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const filterData = useSelector(getMultitenantFilterDataSelector)

    const [loading, setLoading] = useState(false)
    const [loadingAdministrative, setLoadingAdministrative] = useState(false)
    const [loadingPayment, setLoadingPayment] = useState(false)
    const [page, setPage] = useState(filterData?.page)
    const [limit, setLimit] = useState(filterData?.limit)
    const [pagePayment, setPagePayment] = useState(filterData?.page)
    const [limitPayment, setLimitPayment] = useState(filterData?.limit)

    //Company details card
    const [activeUserData, setActiveUserData] = useState()
    const [paymentRecordData, setPaymentRecordData] = useState([])
    const [adminIntrativeData, setAdminIntrativeData] = useState([])
    const [companyDetailCardData, setCompanyDetailCardData] = useState()

    const [modulesCardData, setModulesCardDataData] = useState([])
    const [documentCardData, setDocumentCardDataData] = useState([])
    const [docDataToShow, setDocDataToShow] = useState(null)

    const [billingInvoiceData, setBillingInvoiceData] = useState(null)
    const [openViewInvoice, setopenViewInvoice] = useState(false)
    const [openDocPreviewModal, setOpenDocPreviewModal] = useState(false)

    //activeUser
    const getActiveUserData = () => {
        const body = {
            tenant_id: location?.state?.tid,
            filter: '',
            office_id: 'all',
        }
        setLoading(true)
        getActiveUserTotalSalesService(body)
            .then((res) => {
                setActiveUserData(res)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }

    //Payment Records
    const getPaymentRecordsData = () => {
        const body = {
            tenant_id: location?.state?.tid,
            filter: '',
            page: pagePayment,
            perpage: Number(limitPayment),
        }
        setLoadingPayment(true)
        getPaymentRecordsService(body)
            .then((res) => {
                setPaymentRecordData(res?.data)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoadingPayment(false))
    }
    const handleViewInvoice = () => {
        setopenViewInvoice(!openViewInvoice)
    }
    //View Invoice
    const onViewInvoicePress = useCallback(
        (item) => {
            const body = {
                tenant_id: location?.state?.tid,
                invoice_no: item?.invoice_no,
            }
            setLoadingPayment(true)
            getPaymentInvoiceService(body)
                .then((res) => {
                    setBillingInvoiceData(res?.data)
                    setopenViewInvoice(!openViewInvoice)
                })
                .catch((e) => {
                    CustomToast.error(getErrorMessageFromResponse(e))
                })
                .finally(() => {
                    setLoadingPayment(false)
                })
        },
        [openViewInvoice]
    )

    const getClientOverView = () => {
        const body = {
            tenant_id: location?.state?.tid,
        }
        setLoading(true)
        getTenantDetailsClientDirectoryService(body)
            .then((res) => {
                setCompanyDetailCardData(res?.data)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }

    // Module Card
    const getModules = () => {
        const body = {
            tenant_id: location?.state?.tid,
        }
        setLoading(true)
        getTenantModulesListService(body)
            .then((res) => {
                setModulesCardDataData(res?.data)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }

    //Document Card
    const getDocumentData = () => {
        const body = {
            tenant_id: location?.state?.tid,
        }
        setLoading(true)
        getDocumentsListClientDirectoryService(body)
            .then((res) => {
                setDocumentCardDataData(res?.data)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }

    //Administrative Setup Table
    const getAdminIstrativeSetupData = () => {
        const body = {
            tenant_id: location?.state?.tid,
            filter: '',
            office_id: 'all',
            page: page,
            perpage: Number(limit),
        }
        setLoadingAdministrative(true)
        getAdministrativeSetUpService(body)
            .then((res) => {
                setAdminIntrativeData(res)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoadingAdministrative(false))
    }

    const onSuspendAccessOfUserPress = useCallback(
        (item) => {
            const body = {
                user_id: item?.id,
                tenant_id: location?.state?.tid,
            }
            // setLoadingUserId(item?.id)
            putAdministrativeSetupSuspendUserService(body)
                .then((res) => {
                    CustomToast.success(res?.message)
                    getAdminIstrativeSetupData()
                })
                .catch((error) => {
                    CustomToast.error(getErrorMessageFromResponse(error))
                })
                .finally(() => {
                    // setLoadingUserId(null)
                })
        },
        [getAdminIstrativeSetupData]
    )

    const onMakeCompanyAdminPress = useCallback(
        (item) => {
            // setLoadingUserId(item?.id)
            const body = {
                user_id: item?.id,
                tenant_id: location?.state?.tid,
                is_super_admin: true,
            }
            putAdministrativeSetupMakeUserGroupService(body)
                .then(() => {
                    CustomToast.success('Made Super Admin')
                    getAdminIstrativeSetupData()
                })
                .catch((error) => {
                    CustomToast.error(getErrorMessageFromResponse(error))
                })
                .finally(() => {
                    // setLoadingUserId(null)
                })
        },
        [getAdminIstrativeSetupData]
    )
    useEffect(() => {
        getActiveUserData()
        getClientOverView()
        getModules()
        getDocumentData()
    }, [])

    useEffect(() => {
        getPaymentRecordsData()
        sendDataToReducer(dispatch, SET_MULTITENANT_FILTER_DATA, {
            ...filterData,
            page: pagePayment,
            limit: limitPayment,
        })
    }, [pagePayment, limitPayment])
    useEffect(() => {
        getAdminIstrativeSetupData()
        sendDataToReducer(dispatch, SET_MULTITENANT_FILTER_DATA, {
            ...filterData,
            page: page,
            limit: limit,
        })
    }, [page, limit])

    const handleDocPreviewModal = useCallback(
        (doc) => {
            setOpenDocPreviewModal(!openDocPreviewModal)
            setDocDataToShow(doc)
        },
        [openDocPreviewModal]
    )

    function downloadDoc(name, item, extension = 'pdf') {
        handleDocPreviewModal({url: item, extension: 'pdf', name: name})
    }
    return (
        <div className='row container mx-auto'>
            {/* Big body */}
            <div className='col-lg mt-lg-13'>
                <div className='d-flex justify-content-between align-items-center gap-sm-10 gap-5 mb-6 flex-wrap'>
                    <div className='d-flex align-items-center gap-3'>
                        <div>
                            <KTSVG
                                path='/media/icons/duotune/art/back-square.svg'
                                svgClassName='h-25px w-25px cursor-pointer'
                                onClick={() => navigate(-1)}
                            />
                        </div>
                        <div className='text-sqGray900 text-xl-bold'>Flex Power | Overview</div>
                    </div>
                    <div>
                        <CustomButton
                            buttonLabel='Edit Client'
                            buttonSize={BUTTON_SIZE.small}
                            onClick={() =>
                                navigate(`/new-user`, {
                                    state: {
                                        tid: location?.state?.tid,
                                    },
                                })
                            }
                        />
                    </div>
                </div>
                {/* Top cards starts */}
                <Show>
                    <Show.When isVisible={loading}>
                        <CustomCardSkeleton count={4} col='col-sm-2' />
                    </Show.When>
                    <Show.When isVisible={!loading}>
                        <div className='row mb-6'>
                            <div className='col-12 col-sm-6 col-md-3 mb-3 mb-md-0'>
                                <CommonTopCards
                                    heading={activeUserData?.totalActiveUsers}
                                    content={'Active Users'}
                                    underline={false}
                                />
                            </div>
                            <div className='col-12 col-sm-6 col-md-3 mb-3 mb-md-0 cursor-pointer'>
                                <CommonTopCards
                                    heading={activeUserData?.totalSales}
                                    content={'Total Sales'}
                                    underline={true}
                                    onClick={() =>
                                        navigate('/client-sales', {
                                            state: {
                                                tid: location?.state?.tid,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className='col-12 col-sm-6 col-md-3 mb-3 mb-md-0 cursor-pointer'>
                                <CommonTopCards
                                    heading={activeUserData?.checkProcessed}
                                    content={'Payroll Checks Processed'}
                                    underline={true}
                                    onClick={() =>
                                        navigate('/client-paycheck', {
                                            state: {
                                                tid: location?.state?.tid,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className='col-12 col-sm-6 col-md-3 cursor-pointer'>
                                <CommonTopCards
                                    heading={activeUserData?.backgroundProcess}
                                    content={'Background Checks'}
                                    underline={true}
                                    onClick={() =>
                                        navigate('/client-background-check', {
                                            state: {
                                                tid: location?.state?.tid,
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </Show.When>
                </Show>

                {/* Payment Records */}
                <div className='mb-6 mt-6'>
                    <PaymentRecordsTable
                        paymentRecordData={paymentRecordData}
                        loading={loadingPayment}
                        onViewInvoicePress={onViewInvoicePress}
                        page={pagePayment}
                        limit={limitPayment}
                        setLimit={setLimitPayment}
                        setPage={setPagePayment}
                    />
                </div>
                <div className='mb-10'>
                    <AdministrativeSetupTable
                        adminIntrativeData={adminIntrativeData}
                        loading={loadingAdministrative}
                        onSuspendAccessOfUserPress={onSuspendAccessOfUserPress}
                        onMakeCompanyAdminPress={onMakeCompanyAdminPress}
                        page={page}
                        limit={limit}
                        setLimit={setLimit}
                        setPage={setPage}
                    />
                </div>
            </div>
            {/* side pannel */}
            <div className='col-lg-3 '>
                <div className='mb-10 mt-lg-13'>
                    <CompanyDetailCard
                        companyDetailCardData={companyDetailCardData}
                        loading={loading}
                    />
                </div>
                {/* <div className='row flex-wrap'> */}
                <div className='mb-10 '>
                    <IntegartionCard modulesCardData={modulesCardData} loading={loading} />
                </div>
                <div className='mb-10'>
                    <ModulesCard documentCardData={documentCardData} downloadDoc={downloadDoc} />
                </div>
                {/* </div> */}
            </div>
            {openViewInvoice ? (
                <CustomModal
                    maxWidth='900'
                    show={openViewInvoice}
                    onHide={handleViewInvoice}
                    showline={false}
                >
                    {/* <PreviewA4> */}
                    <ViewBillingInvoice data={billingInvoiceData} />
                    {/* </PreviewA4> */}
                </CustomModal>
            ) : null}

            {openDocPreviewModal ? (
                <DocumentPreviewModal
                    open={openDocPreviewModal}
                    handleClose={handleDocPreviewModal}
                    docData={docDataToShow}
                />
            ) : null}
        </div>
    )
}

export default CompanyOverviewPage

// Inner components
const CommonTopCards = ({heading, content, underline, onClick}) => (
    <div className={`card card-flush px-8 py-4 shadow-sm`} onClick={onClick}>
        <div
            className={`text-sqGray900 text-dxs-bold mb-4 ${
                underline ? 'text-decoration-underline' : ''
            }`}
        >
            {heading}
        </div>
        <div className='text-sqGray600 text-sm-medium'>{content}</div>
    </div>
)
const PaymentRecordsTable = ({
    paymentRecordData,
    loading,
    onViewInvoicePress,
    page,
    limit,
    setLimit,
    setPage,
}) => {
    const paymentStatus = useCallback((paymentStatus) => {
        return STRIPE_PAYMENT_STATUS?.find((item) => item?.status_id == paymentStatus)?.status
    }, [])

    return (
        <div className='bg-cmwhite shadow-sm' style={{borderRadius: 10, verticalAlign: 'top'}}>
            <div className='px-sm-10 px-5 py-5 text-sqGray900 text-xl-bold'>Payment Records</div>
            <div className='table-responsive'>
                <table className='table'>
                    <thead className={TABLE_BORDER}>
                        <tr className='text-sqGray800 bg-sqGray200 text-sm-semibold text-nowrap'>
                            <td className='p-5 ps-sm-10'>Invoice no.</td>
                            <td className='p-5'>Bill Date</td>
                            <td className='p-5'>Status</td>
                            <td className='p-5'>Amount</td>
                            <td className='p-5'>Status</td>
                        </tr>
                    </thead>
                    {/* <tbody className='text-sqGray600' style={{fontSize: '14px'}}> */}
                    <tbody className={TABLE_BORDER}>
                        {loading ? (
                            <CustomTableSkeleton coloums={5} />
                        ) : paymentRecordData?.data?.length > 0 ? (
                            <>
                                {paymentRecordData?.data?.map((item, index) => (
                                    <tr key={index} className='text-sqGray800 stripRow '>
                                        <td className='p-5 ps-sm-10 text-xs-medium'>
                                            {item?.invoice_no}
                                        </td>
                                        <td className='p-5 text-xs-medium'>
                                            {getValidDate(item?.billing_date)}
                                        </td>
                                        <td className='p-5 text-cmSuccess text-xs-bold'>
                                            {/* {item?.paid_status == 1 ? 'Paid' : ''} */}
                                            {paymentStatus(item?.paid_status)}
                                        </td>

                                        <td className='p-5 text-sqGray800 text-xs-medium'>
                                            {formattedNumberFields(item?.grand_total)}
                                        </td>

                                        <td className='p-5 text-nowrap text-md-semibold'>
                                            <CustomLink
                                                label={'View Invoice'}
                                                underline={false}
                                                linkSize={16}
                                                onClick={() => onViewInvoicePress(item)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td
                                    colSpan={12}
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: 'Manrope',
                                        fontWeight: '500',
                                        fontSize: 14,
                                        paddingTop: 20,
                                        paddingBottom: 20,
                                    }}
                                >
                                    No data found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* <div className='pb-5 pe-5'> */}
            <Pagination
                tableName={'Payment_record'}
                page={page}
                totalPages={paymentRecordData?.last_page}
                limit={limit}
                setLimit={setLimit}
                setPage={(changedPage) => setPage(changedPage)}
            />
            {/* </div> */}
        </div>
    )
}
const AdministrativeSetupTable = ({
    adminIntrativeData,
    loading,
    onSuspendAccessOfUserPress,
    onMakeCompanyAdminPress,
    page,
    limit,
    setLimit,
    setPage,
}) => {
    return (
        <div className='bg-cmwhite shadow-sm' style={{borderRadius: 10}}>
            <div className='px-sm-10 px-5 py-5 text-sqGray900 d-flex text-xl-bold flex-wrap justify-content-between'>
                <div>Administrative Setup</div>
                <div className='text-cm'>
                    {adminIntrativeData?.totalAdminActiveUsers +
                        adminIntrativeData?.totalAdminInActiveUsers}
                    <span className='text-sqGray600 text-sm-medium ms-2'> Super Admins</span>
                </div>
            </div>
            <div className='table-responsive'>
                <table
                    className='table'
                    style={{
                        fontSize: '14px',
                        tableLayout: 'fixed',
                        verticalAlign: 'middle',
                        width: '100%',
                    }}
                >
                    <thead className={TABLE_BORDER}>
                        <tr className='text-sqGray800 bg-sqGray200 text-sm-semibold text-nowrap'>
                            <td className='p-5 ps-sm-10 w-150px'>Invoice no.</td>
                            <td className='p-5 w-200px'>Email ID</td>
                            <td className='p-5 w-75px'>Actions</td>
                        </tr>
                    </thead>
                    <tbody className='text-sqGray600' style={{fontSize: '14px', fontWeight: 600}}>
                        {loading ? (
                            <CustomTableSkeleton coloums={3} />
                        ) : adminIntrativeData?.data?.data?.length > 0 ? (
                            <>
                                {adminIntrativeData?.data?.data?.map((item) => (
                                    <tr key={0} className='text-sqGray800 stripRow'>
                                        <td className='p-5 ps-sm-10'>
                                            <div className='d-flex flex-wrap align-items-center gap-4 '>
                                                <CustomImage
                                                    // type={IMAGE_TYPE.companyLogo}
                                                    src={item?.image}
                                                    className={'w-30px h-30px'}
                                                />
                                                <div className='text-sqGray900 text-xs-medium'>
                                                    {item?.first_name} {item?.last_name}
                                                </div>
                                                {item?.is_owener == 1 ? (
                                                    <div
                                                        className='badge rounded-pill bg-sqBlue700 bg-opacity-10 text-sqBlue700 px-3 text-xs-bold'
                                                        style={{
                                                            fontWeight: 600,
                                                            lineHeight: '21px',
                                                        }}
                                                    >
                                                        Company Owner
                                                    </div>
                                                ) : (
                                                    <div
                                                        className='badge rounded-pill bg-sqSuccess700 bg-opacity-10 text-sqSuccess700 px-3 text-xs-bold'
                                                        style={{
                                                            fontWeight: 600,
                                                            lineHeight: '21px',
                                                        }}
                                                    >
                                                        Super Admin
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className='p-5 text-sqGray900 text-xs-medium'>
                                            {item?.email}
                                        </td>

                                        <td className='p-5 text-nowrap'>
                                            <div className=''>
                                                {item?.status_id !== 6 && item?.id !== 1 ? (
                                                    <>
                                                        <span
                                                            className='bi bi-three-dots-vertical fs-2 text-sqGray500 cursor-pointer text-hover-dark'
                                                            data-bs-toggle='dropdown'
                                                            aria-expanded='false'
                                                            style={{width: 'fit-content'}}
                                                        />
                                                        <ul className='dropdown-menu text-sm-semibold'>
                                                            <li
                                                                className='dropdown-item cursor-pointer text-sqGray800 border-bottom border-sqGray200'
                                                                onClick={() =>
                                                                    onSuspendAccessOfUserPress(item)
                                                                }
                                                            >
                                                                Suspend access
                                                            </li>
                                                            <li
                                                                className='dropdown-item cursor-pointer text-sqGray800'
                                                                onClick={() =>
                                                                    onMakeCompanyAdminPress(item)
                                                                }
                                                            >
                                                                Make Company Admin
                                                                {/* {item?.is_super_admin
                                                                    ? 'Remove Company Admin'
                                                                    : 'Make Company Admin'} */}
                                                            </li>
                                                        </ul>
                                                    </>
                                                ) : null}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td
                                    colSpan={12}
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: 'Manrope',
                                        fontWeight: '500',
                                        fontSize: 14,
                                        paddingTop: 20,
                                        paddingBottom: 20,
                                    }}
                                >
                                    No data found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* <div className='pb-5 pe-5'> */}
            <Pagination
                tableName={'Administrative_Setup'}
                page={page}
                totalPages={adminIntrativeData?.data?.last_page}
                limit={limit}
                setLimit={setLimit}
                setPage={(changedPage) => setPage(changedPage)}
            />
            {/* </div> */}
        </div>
    )
}

// Side Cards
const CompanyDetailCard = ({companyDetailCardData, loading}) => {
    return (
        <Show>
            <Show.When isVisible={loading}>
                <CustomCardSkeleton
                    count={1}
                    // containerWidth='w-md-100 w-100'
                    containerWidth='h-100'
                    cardWidth='50px'
                    height='500px'
                />
            </Show.When>
            <Show.When isVisible={!loading}>
                <div className='p-5 bg-cmwhite shadow-sm' style={{borderRadius: 10}}>
                    {/* Header */}
                    <div className='text-center border-bottom-dashed pb-5 mb-5 border-cmDisabled'>
                        <div className='w-65px h-65px mx-auto rounded bg-sqGray100 d-flex flex-center'>
                            <CustomImage
                                type={IMAGE_TYPE.companyLogo}
                                src={companyDetailCardData?.companyLogo}
                                className={'w-50px h-50px'}
                            />
                        </div>
                        <div className='my-2 text-sqGray900 text-xl-bold'>
                            {companyDetailCardData?.logo}
                        </div>
                        <div className='badge bg-sqPrimary600 text-sqPrimary600 bg-opacity-10 px-5 rounded-pill text-xs-bold'>
                            {companyDetailCardData?.companyType}
                        </div>
                    </div>
                    {/* Body */}
                    <div>
                        {/* line 1 */}
                        <div className='mb-5'>
                            <div className='text-sqGray900 mb-1 text-sm-bold'>Client ID</div>
                            <div className='text-sqGray600 text-sm-medium'>
                                {companyDetailCardData?.client_id
                                    ? companyDetailCardData?.client_id
                                    : '-'}
                            </div>
                        </div>
                        {/* line 2 */}
                        <div className='mb-5'>
                            <div className='text-sqGray900 mb-1 text-sm-bold'>
                                Billing Frequency
                            </div>
                            <div className='text-sqGray600 d-flex gap-2 align-items-center text-sm-medium'>
                                <span className='text-sqGray600 text-xs-semibold'>
                                    {companyDetailCardData?.billing_frequency
                                        ? companyDetailCardData?.billing_frequency
                                        : '-'}
                                </span>
                                <span className='d-flex gap-2 text-sqGray500 text-xs-semibold '>
                                    <span>Started:</span>
                                    <span>
                                        {' '}
                                        {companyDetailCardData?.start_date
                                            ? getValidDate(companyDetailCardData?.start_date)
                                            : '-'}
                                    </span>
                                </span>
                            </div>
                        </div>
                        {/* line 3 */}
                        <div className='mb-5'>
                            <div className='text-sqGray900 mb-1 text-sm-bold'>No. of Users</div>
                            <div className='text-sqGray600 text-sm-medium'>
                                {companyDetailCardData?.active_users
                                    ? companyDetailCardData?.active_users
                                    : '-'}
                            </div>
                        </div>
                        {/* line 4 */}
                        <div className='mb-5'>
                            <div className='text-sqGray900 mb-1 text-sm-bold'>Subdomain</div>
                            <div className='text-sqGray600 text-sm-medium'>
                                {companyDetailCardData?.sub_domain
                                    ? companyDetailCardData.sub_domain
                                    : '-'}
                            </div>
                        </div>
                        {/* line 5 */}
                        {/* <div className='mb-5'>
                            <div className='text-sqGray900 mb-1 text-sm-bold'>EIN No.</div>
                            <div className='text-sqGray600 text-sm-medium'>
                                {''}
                                {'-'}
                            </div>
                        </div> */}
                        {/* line 6 */}
                        <div className='mb-5'>
                            <div className='text-sqGray900 mb-1 text-sm-bold'>Pricing</div>
                            <div className='text-sqGray600 text-sm-medium d-flex gap-1 align-items-center'>
                                <span className='text-sqGray600'>
                                    ${' '}
                                    {companyDetailCardData?.unique_pid_rack_price
                                        ? companyDetailCardData?.unique_pid_rack_price
                                        : '-'}
                                    /watt
                                </span>{' '}
                                <span className='text-sqGray800 text-sm-bold'>Unique PID’s</span>
                                <KTSVG
                                    path='/media/icons/duotune/art/percentage.svg'
                                    svgClassName='w-15px h-15px cursor-pointer'
                                />
                            </div>
                            <div className='text-sqGray600 text-sm-medium d-flex gap-1 align-items-center'>
                                <span className='text-sqGray600'>
                                    ${' '}
                                    {companyDetailCardData?.m2_rack_price
                                        ? companyDetailCardData?.m2_rack_price
                                        : '-'}
                                    /watt
                                </span>
                                <span className='text-sqGray800 text-sm-bold'>M2 Completed</span>
                                <KTSVG
                                    path='/media/icons/duotune/art/percentage.svg'
                                    svgClassName='w-15px h-15px cursor-pointer'
                                />
                            </div>
                        </div>
                        {/* line 7 */}
                        <div className='mb-5'>
                            <div className='text-sqGray900 mb-1 text-sm-bold'>Recovery email</div>
                            <div className='text-sqGray600 text-sm-medium'>
                                {companyDetailCardData?.recovery_email
                                    ? companyDetailCardData?.recovery_email
                                    : '-'}
                            </div>
                        </div>
                        {/* line 8 */}
                        <div className=''>
                            <div className='text-sqGray900 mb-1 text-sm-bold'>Support URL</div>
                            <div
                                className='text-sqGray600 text-sm-medium text-truncate'
                                style={{maxWidth: '100%'}}
                            >
                                {companyDetailCardData?.support_url
                                    ? companyDetailCardData?.support_url
                                    : '-'}
                            </div>
                        </div>
                    </div>
                </div>
            </Show.When>
        </Show>
    )
}

const IntegartionCard = ({modulesCardData, loading}) => {
    const [showModules, setModules] = useState(true)

    return (
        <Show>
            <Show.When isVisible={loading}>
                <CustomCardSkeleton
                    count={1}
                    // containerWidth='w-md-100 w-100'
                    containerWidth='h-100'
                    // cardWidth='50px'
                    height='100px'
                />
            </Show.When>
            <Show.When isVisible={!loading}>
                <div className='p-5 bg-cmwhite shadow-sm w-100' style={{borderRadius: 10}}>
                    {/* Heading */}
                    <div className='d-flex gap-2 mb-5' onClick={() => setModules(!showModules)}>
                        <div className='text-sqGray800 text-md-bold'>Modules</div>
                        <CustomArrow arrowDirection={ARROW_DIRECTION.down} />
                    </div>
                    <CustomCollapse toggle={showModules}>
                        <div>
                            {/* line 1 */}
                            {modulesCardData &&
                                modulesCardData?.map((item) => (
                                    <div className='d-flex align-items-center gap-5 mb-5'>
                                        {item?.logo ? (
                                            <CustomImage
                                                className={'w-35px h-35px shadow-sm'}
                                                src={item?.logo}
                                                style={{borderRadius: '15px'}}
                                            />
                                        ) : (
                                            <CustomImage
                                                className={'w-35px h-35px shadow-sm'}
                                                type={IMAGE_TYPE.companyLogo}
                                                // src={images?.logo}
                                                style={{borderRadius: '15px'}}
                                            />
                                        )}
                                        <div>
                                            <div className='text-sqGray900 p-0 text-sm-semibold'>
                                                {item?.product}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </CustomCollapse>
                </div>
            </Show.When>
        </Show>
    )
}

const ModulesCard = ({documentCardData, downloadDoc}) => {
    const [showDocument, setDocument] = useState(true)
    return (
        <div className='p-5 bg-cmwhite shadow-sm w-100' style={{borderRadius: 10}}>
            {/* Heading */}
            <div className='d-flex gap-2 mb-5' onClick={() => setDocument(!showDocument)}>
                <div className='text-sqGray800 text-md-bold'>Documents</div>
                <CustomArrow arrowDirection={ARROW_DIRECTION.down} />
            </div>
            <CustomCollapse toggle={showDocument}>
                <div
                    className='row flex-wrap gap-4 p-2'
                    style={{overflowX: 'hidden', overflow: 'auto'}}
                >
                    {documentCardData?.map((category, index) => (
                        <div
                            style={{borderRadius: 10, width: '130px'}}
                            className='col-sm-2 p-0 shadow-sm overflow-hidden'
                            key={`document_${index}`}
                        >
                            <div
                                // className={`cursor-pointer
                                //     ${
                                //     i?.extension == 'pdf'
                                //         ? ''
                                //         : 'd-flex justify-content-center align-items-center'
                                // }
                                // `}
                                className='cursor-pointer'
                                style={{
                                    height: '80px',
                                    zIndex: 1,
                                    filter: 'brightness(0.6)',
                                    overflow: 'hidden',
                                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                }}
                                onClick={() =>
                                    downloadDoc(
                                        category?.document_uploaded_type,
                                        category?.un_signed_document,
                                        ''
                                    )
                                }
                            >
                                <Show>
                                    <div>
                                        <Document file={category?.un_signed_document}>
                                            <Page pageNumber={1} id={`page_${index}`} />
                                        </Document>
                                    </div>
                                </Show>
                            </div>
                            <div className='card' style={{zIndex: 2}}>
                                <div className='d-flex align-items-center justify-content-between gap-3 p-1'>
                                    <div className='bg-cmwhite text-cmGrey700'>
                                        <div className='d-flex flex-column'>
                                            {/* Ellipsis for long text */}
                                            {/* <CustomEllipsis
                                                style={{fontSize: '10px', width: '146px'}}
                                                text={category?.document_uploaded_type}
                                            >
                                                {category?.document_uploaded_type}
                                            </CustomEllipsis> */}

                                            {/* Document type text */}
                                            <div className='' style={{fontSize: '10px'}}>
                                                {category?.document_uploaded_type}
                                            </div>

                                            {/* Date */}
                                            <div className='' style={{fontSize: '10px'}}>
                                                {getValidDate(
                                                    category?.document_response_date,
                                                    'DD MMM YYYY, h:mm a'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {/* <CustomBadge
                                        label={category?.document_uploaded_type || ''}
                                        textColor='cmGrey800'
                                        fontWeight={700}
                                        fontSize={12}
                                        px='2'
                                    /> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </CustomCollapse>
        </div>
    )
}
