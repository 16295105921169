import {Doughnut} from 'react-chartjs-2'
import CustomCardSkeleton from '../../../../../customComponents/customSkeleton/CustomCardSkeleton'
import Show from '../../../../../customComponents/show/Show'

const ActiveBillingCard = ({activeGraphData, loading}) => {
    const data = {
        labels: ['Paid', 'Unpaid', 'Overdue'],
        datasets: [
            {
                data: [activeGraphData?.paid, activeGraphData?.unpaid, activeGraphData?.overdue],
                backgroundColor: ['#039855', '#00A3FF', '#D92D20'],
                borderColor: ['#039855', '#00A3FF', '#D92D20'],
                // borderRadius: [10, 10, 10],
            },
        ],
    }

    return (
        <Show>
            <Show.When isVisible={loading}>
                <CustomCardSkeleton
                    count={1}
                    cardWidth='w-100'
                    containerWidth='col-12 col-md col-lg-4 ps-0 pe-0 mx-auto'
                    col='px-0'
                    height='220px'
                />
            </Show.When>
            <Show.When isVisible={!loading}>
                <>
                    <div
                        className={`bg-cmwhite shadow-sm col-lg-3 col-md-6 col-sm-12 col-12 px-sm-10 py-5`}
                        style={{borderRadius: 10}}
                    >
                        <div className='mb-4'>
                            <div className='text-cmGrey900 text-dsm-bold'>
                                {activeGraphData?.active_billing}
                            </div>
                            <div className='text-cmGrey600 text-md-medium'>Active Billing</div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-12 col-sm-4 col-md-6'>
                                <Doughnut
                                    data={data}
                                    options={{
                                        cutout: '70%',
                                        plugins: {
                                            legend: {display: false},
                                            tooltip: {enabled: true},
                                        },
                                        maintainAspectRatio: true,
                                        responsive: true,
                                    }}
                                    className='w-100 h-auto'
                                />
                            </div>
                            <div className='col-sm'>
                                <div className='d-flex gap-8 mb-2'>
                                    <div className='col d-flex align-items-center '>
                                        <span
                                            className='bullet w-10px h-5px rounded-2 me-3'
                                            style={{backgroundColor: '#039855'}}
                                        />
                                        <span className='text-end text-xs-medium text-sqGray500'>
                                            Paid
                                        </span>
                                    </div>
                                    <div className='text-sqGray900 text-sm-bold'>
                                        {activeGraphData?.paid}
                                    </div>
                                </div>
                                <div className='d-flex gap-8 mb-2'>
                                    <div className='col d-flex align-items-center'>
                                        <span
                                            className='bullet w-10px h-5px rounded-2 me-3'
                                            style={{backgroundColor: '#00A3FF'}}
                                        />
                                        <span className='text-end text-xs-medium text-sqGray500'>
                                            Unpaid
                                        </span>
                                    </div>
                                    <div className='text-sqGray900 text-sm-bold'>
                                        {activeGraphData?.unpaid}
                                    </div>
                                </div>
                                <div className='d-flex gap-8 mb-2'>
                                    <div className='col d-flex align-items-center'>
                                        <span
                                            className='bullet w-10px h-5px rounded-2 me-3'
                                            style={{backgroundColor: '#FF3333'}}
                                        />
                                        <span className='text-end text-sqError600'>Overdue</span>
                                    </div>
                                    <div className='text-sqGray900 text-sm-bold'>
                                        {activeGraphData?.overdue}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Show.When>
        </Show>
    )
}

export default ActiveBillingCard
